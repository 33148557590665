<div class="fare-section" [ngClass]="{ 'upsell': isUpsell }">
  <h4 class="fare-title-wrapper">
    <span class="fare-title">{{title}}</span>
    <span class="price">
      {{ fare.total.price | currency: fare.total.currency }}
      <app-warning-tags *ngIf="travelRules"
      class="upsell-wrapper"
      [iconId]="fare.uid"
      [ticket]="travelRules"
      [displayCheck]="false"></app-warning-tags>
    </span>
  </h4>
  <div class="flight-details">
    <div>
      <div class="cabin-class">
        {{ fare.outboundFlights[0].cabinClass | splitCamelCase }}
        <span *ngIf="fare.outboundFlights[0].additional.fareBrand?.name">
          ({{ fare.outboundFlights[0].additional.fareBrand?.name | titlecase }})
        </span>
        <!-- <span *ngIf="fare?.inboundFlights[0]">
          <span>&nbsp;/&nbsp;</span>
          {{ fare.inboundFlights[0].cabinClass | splitCamelCase }}
          <span *ngIf="fare.inboundFlights[0].additional.fareBrand?.name"
            >({{ fare.inboundFlights[0].additional.fareBrand?.name | titlecase }})
            <app-saver-aer-lingus-travelfusion-tooltip [flight]="fare"></app-saver-aer-lingus-travelfusion-tooltip>
          </span>
        </span> -->
      </div>
      <div class="flight-route">
        <!-- one way, dual in, dual out -->
        <app-flight-header
          class="flight-header-border"
          [flightJourney]="fare.outboundFlights[0]"
          [lastFlightJourney]="lastOutboundFlightJourney"
          [allJourneys]="fare.outboundFlights"
          [source]="fare.source"
          [duration]="fare.outboundTotalDuration"
          [preferred]="fare?.preferred"
          [preferenceLevel]="fare?.preferenceLevel"
          [preferredMessages]="fare?.preferredMessages"
          [tmcPreferredLogoUrl]="fare?.tmcPreferredLogoUrl"
          [searchType]="searchService.searches[ServiceType.Flight]?.chosenSearchType"
          [upsell]="true">
        </app-flight-header>
        <!-- return -->
        <app-flight-header
          *ngIf="searchService.searches[ServiceType.Flight].isReturn && !isDualFlight"
          [flightJourney]="fare.inboundFlights[0]"
          [lastFlightJourney]="lastInboundFlightJourney"
          [allJourneys]="fare.inboundFlights"
          [source]="fare.source"
          [isReturn]="true"
          [duration]="fare.inboundTotalDuration"
          [searchType]="searchService.searches[ServiceType.Flight]?.chosenSearchType"
          [upsell]="true">
        </app-flight-header>
      </div>
    </div>

    <div>
      <dl class="fare-info">
        <div class="flex-row-container flex-row-container--center">
          <dt class="visuallyhidden">Fare Rules</dt>
          <dd>
            <a
              class="fare_rules_link"
              *ngIf="!isAerLingus"
              (click)="getFareRules(fare)"
              (keydown.enter)="getFareRules(fare)"
              tabindex="0"
              role="link">
              <span i18n="@@ClicktoView">Click to View</span>
            </a>
          </dd>
        </div>
        <!-- hangBaggage -->
        <div>
          <dt class="visuallyhidden">Carry-on Baggage</dt>
          <dd>
            <ng-container *ngIf="routeHappyResults?.data?.carryOnBag[0]?.description; else noInfo">
              {{routeHappyResults?.data.carryOnBag[0].description}}
            </ng-container>

            <ng-container *ngIf="routeReturnHappyResults?.data?.carryOnBag[0]?.description">
              <span class="visuallyhidden">Return</span>
              <span> / </span>
              {{routeReturnHappyResults?.data.carryOnBag[0].description}}
            </ng-container>
          </dd>
        </div>
        <!-- Checked Baggage -->
        <div>
          <dt class="visuallyhidden">Checked Baggage</dt>
          <dd>
            <ng-container *ngIf="routeHappyResults?.data?.checkedBag[0]?.description; else noInfo">
              {{routeHappyResults?.data.checkedBag[0].description}}
            </ng-container>

            <ng-container *ngIf="routeReturnHappyResults?.data?.checkedBag[0]?.description">
              <span class="visuallyhidden">Return</span>
              <span> / </span>
              {{routeReturnHappyResults?.data.checkedBag[0].description}}
            </ng-container>
          </dd>
        </div>
        <!-- changes -->
        <div>
          <dt class="visuallyhidden">Changes</dt>
          <dd>
            <app-ancillary-status-icon
              [isReturnFlight]="isReturnFlight"
              [ancillaryStatus]="transformedFareAncillaries[0].changes">
            </app-ancillary-status-icon>
          </dd>
        </div>
        <!-- refund -->
        <div>
          <dt class="visuallyhidden">Refunds</dt>
          <dd>
            <app-ancillary-status-icon
              [isReturnFlight]="isReturnFlight"
              [ancillaryStatus]="transformedFareAncillaries[0].refund">
            </app-ancillary-status-icon>
          </dd>
        </div>
        <!-- seat -->
        <div>
          <dt class="visuallyhidden">Seat</dt>
          <dd>
            <ng-container *ngIf="routeHappyResults?.data?.amenitySummary[0]?.seat; else noInfo">
              {{routeHappyResults.data.amenitySummary[0].seat.displayText}}
            </ng-container>

            <ng-container *ngIf="routeReturnHappyResults?.data?.amenitySummary[0]?.seat">
              <span class="visuallyhidden">Return</span>
              <span> / </span>
              {{routeReturnHappyResults.data.amenitySummary[0].seat.displayText}}
            </ng-container>
          </dd>
        </div>
        <!-- food -->
        <div>
          <dt class="visuallyhidden">Meal</dt>
          <dd>
            <ng-container *ngIf="routeHappyResults?.data?.amenitySummary[0]?.freshFood; else noInfo">
              {{routeHappyResults.data.amenitySummary[0].freshFood.displayText}}
            </ng-container>

            <ng-container *ngIf="routeReturnHappyResults?.data?.amenitySummary[0]?.freshFood">
              <span class="visuallyhidden">Return</span>
              <span> / </span>
              {{routeReturnHappyResults.data.amenitySummary[0].freshFood.displayText}}
            </ng-container>
          </dd>
        </div>
        <!-- wifi -->
        <div>
          <dt class="visuallyhidden">wifi</dt>
          <dd>
            <ng-container *ngIf="routeHappyResults?.data?.amenitySummary[0]?.wifi; else noInfo">
              {{routeHappyResults.data.amenitySummary[0].wifi.displayText}}
            </ng-container>

            <ng-container *ngIf="routeReturnHappyResults?.data?.amenitySummary[0]?.wifi">
              <span class="visuallyhidden">Return</span>
              <span> / </span>
              {{routeReturnHappyResults.data.amenitySummary[0].wifi.displayText}}
            </ng-container>
          </dd>
        </div>

        <ng-template #noInfo>
          <span i18n="@@NotAvailable">
            n/a
          </span>
        </ng-template>
      </dl>

      <div class="flex-row-container flex-row-container--center add-to-basket-wrapper">
        <button
        [disabled]="fare?.unavailable"
        type="button"
        class="small-button"
        (keydown.enter)="addToBasket(fare)"
        (keydown.space)="addToBasket(fare)"
        (click)="addToBasket(fare)">
          {{isUpsell ? 'Select Alternative' : 'Continue'}}
        </button>
      </div>
    </div>

  </div>
</div>
