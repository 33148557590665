<div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title"
        i18n="@@itemHotelsFacilities"
        id="modal-header">Facilities</h4>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="flex-row-container flex-row-container--tb-margin flex-row-container--wrap">
        <section *ngFor="let tag of hotel?.sortedTags" class="container">
            <div *ngIf="tag.name === AmenitiesSearchHandler.ECO_FRIENDLY;else icons" class="eco-friendly-icon" (mouseleave)="tt1.close()">
                <fa-icon
                    triggers="manual"
                    container="body"
                    #tt1="ngbTooltip"
                    (mouseenter)="tt1.open()"
                    ngbTooltip="{{tag.display}}"
                    attr.aria-label="{{tag.display}}"
                    (focus)="tt1.open()"
                    (blur)="tt1.close()"
                    tabindex="0"
                    [icon]="faLeaf">
                </fa-icon>
            </div>
            <ng-template #icons>
                <section (mouseleave)="tt.close()" class="container">
                    <img class="preferred-container__icon list"
                    src="{{tag.icon}}"
                    height="24"
                    width="24"
                    triggers="manual"
                    container="body"
                    #tt="ngbTooltip"
                    (mouseenter)="tt.open()"
                    ngbTooltip="{{tag.display}}"
                    attr.aria-label="{{tag.display}}"
                    (focus)="tt.open()"
                    (blur)="tt.close()"
                    tabindex="0">
                </section>
            </ng-template>
        </section>
    </div>
</div>
<div class="modal-footer">
    <button type="button"
            class="btn btn-outline-dark"
            (click)="activeModal.close()"
            i18n="@@itemHotelsClose">close</button>
</div>