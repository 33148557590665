<div class="flex-column-container pt-2" >
  <label i18n="FlightFavourites" for="favourites-list">
    Favourites
  </label>
  <div class="favorites">
    <div class="input-box favorites-header" 
      (click)="toggleFavouritesList()"
      (keydown.enter)="toggleFavouritesList()"
      tabindex="0"
      role="button"
      [attr.aria-expanded]="dropdownOpen"
      [attr.aria-label]="dropdownOpen ? 'Hide favourites list' : 'Show favourites list'"
      i18n-aria-label
      id="favourites-list">
      <span i18n="SelectAFavourite">Select a Favourite</span>
      <i class="material-icons favorites-chevron" [class.open]="dropdownOpen">arrow_drop_down</i>
    </div>
  
    <ul *ngIf="dropdownOpen" class="favorites-menu"> 
      <ng-container *ngIf="favouriteItems.length; else noFavorites">
        <li *ngFor="let item of favouriteItems" class="favorites-menu-list-item">
          <span  
            tabindex="0"
            role="button"
            (click)="favouritySelected(item)"
            (keydown.enter)="favouritySelected(item)"
            (keydown.space)="favouritySelected(item)">
            <span class="visuallyhidden">Select</span>
            {{ getTitle(item) }}
          </span>
          <button
            mat-icon-button
            (click)="removeFavourite(item)"
            tabindex="0"
            class="remove-button"
          >
            <span class="visuallyhidden">Remove favourite search</span>
            <fa-icon *ngIf="!isRemoving" [icon]="trash"></fa-icon>
            <fa-icon *ngIf="isRemoving" [icon]="spinner"></fa-icon>
          </button>
        </li>
      </ng-container>
      <ng-template #noFavorites>
        <li class="favorites-menu-list-item">
          <span class="medium-text">You have no saved favourites to display...</span>
        </li>
      </ng-template>
    </ul>
  </div>
</div>