<div cdkTrapFocus aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title" id="modal-header" i18n="@@carsDetails">Car Details</h3>
    <button
      type="button"
      class="close"
      attr.aria-label="Car Details Close"
      ngbAutoFocus
      (click)="activeModal.dismiss()">
      <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="car_details_wrapper">
      <div>
        <div class="pickup">
          <h4><u i18n="@@carsPickUpAddress">Pick up address</u></h4>
          <span>{{ result.displayRecord.pickupLocation.name }}</span>
          <br />
          <span>{{ result.displayRecord.pickupLocation.topLine }}</span>
          <br />
          <span>{{ result.displayRecord.pickupLocation.city }}</span>
          <br />
          <span>{{ result.displayRecord.pickupLocation.postCode }}</span>
          <br />
        </div>
        <div>
          <h4><u i18n="@@carsDetailsTitle">Car Details</u></h4>
          <ul class="car_icons">
            <section
              (mouseleave)="tt.close()"
              *ngIf="result.displayRecord.vehicle.aircon === 'Aircon'"
              class="container">
              <li
                class="air_con_icon"
                triggers="manual"
                #tt="ngbTooltip"
                (mouseenter)="tt.open()"
                (focus)="tt.open()"
                (blur)="tt.close()"
                tabindex="0"
                i18n-ngbTooltip
                ngbTooltip="Air Conditioning"
                aria-label="Air Conditioning"></li>
            </section>
            <section (mouseleave)="tt2.close()" class="container">
              <li
                class="door_icon"
                ngbTooltip="{{ result.displayRecord.vehicle.type }}"
                attr.aria-label="{{ result.displayRecord.vehicle.type }}"
                triggers="manual"
                #tt2="ngbTooltip"
                (focus)="tt2.open()"
                (blur)="tt2.close()"
                tabindex="0"
                (mouseenter)="tt2.open()">
                <span>{{ getTypeForDisplayFromResult(result) }}</span>
              </li>
            </section>
          </ul>
          <p>
            <span i18n="@@estimatedCo2Usage">Estimated Co2 Usage:</span>
            {{ getCo2Usage(result) }} Kg
          </p>
          <!-- car details -->
          <div
            *ngIf="
              carHireAvailabilityDetailsObject[
                result.identifiers.vendor + '_' + result.identifiers.vehType
              ] as chad
            ">
            <div *ngFor="let additionalInfo of chad?.additionalInfo">
              <h4 *ngIf="additionalInfo?.name && !additionalInfo?.json">
                <u>{{ additionalInfo.name }}</u>
              </h4>
              <p *ngIf="additionalInfo?.text && !additionalInfo?.json">
                <ng-container *ngFor="let t of additionalInfo?.text">{{ t }}<br /> </ng-container>
              </p>
              <!-- handle case where there is neither text or json property data available -->
              <ng-container *ngIf="!additionalInfo?.text && !additionalInfo?.json">
                <p>n/a</p>
              </ng-container>
            </div>
          </div>
          <!-- end car details -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      i18n="@@carsDetailsClose"
      class="small-button"
      (click)="activeModal.close()">
      Close
    </button>
  </div>
</div>
