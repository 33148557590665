import { Component, OnInit } from '@angular/core';
import { LightningModalTypes } from '../../../../../vendor/classes/modal-types.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AmenitiesSearchHandler } from '@sabstravtech/obtservices/base';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-hotel-facilities',
  templateUrl: './hotel-facilities.component.html',
  styleUrls: ['./hotel-facilities.component.scss']
})
export class HotelFacilitiesComponent implements OnInit {
  hotel: any = null;
  AmenitiesSearchHandler = AmenitiesSearchHandler;
  faLeaf = faLeaf;
  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void { }
}

LightningModalTypes.HotelFacilitiesComponent.component = HotelFacilitiesComponent;
