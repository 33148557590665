import { Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, merge, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { OBTAirportDetails, ParkingEnterpriseSearchInterface, TerminalsType } from '@sabstravtech/obtservices/base';
import { EnterpriseSearchService, ServiceType, HelperRoutines } from '@sabstravtech/obtservices/angular';
import { UiHelpers } from '../../../vendor/classes/ui-helpers';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import moment from 'moment';

@Component({
  selector: 'app-parking-search',
  templateUrl: './parking-search.component.html',
  styleUrls: ['./parking-search.component.scss']
})
export class ParkingSearchComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
  @ViewChild('inputAirport') inputAirport: NgbTypeahead;

  timesArray: string[];
  loadingAirports = false;
  airportsClick$: Subject<string> = new Subject();
  airportsFocus$: Subject<string> = new Subject();
  airportsList: Observable<OBTAirportDetails[]>;
  searchParameters: ParkingEnterpriseSearchInterface = null;
  ServiceType: typeof ServiceType = ServiceType;
  isArrivalLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  min_date: NgbDateStruct;
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    private helpers: HelperRoutines,
    title: Title,
    public translateService: TranslateService
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.searchParameters = this.searchService.searches[ServiceType.Parking];
    this.timesArray = this.helpers.generateTimesList(0, 24);
    this.min_date = this.helpers.momentToNgDate(moment());
    this.searchParameters.terminals.subscribe(terminals => {
      if (terminals[0] && !this.searchParameters.terminal) { //Check we actually have a terminal to set first
        // When the terminals change, automatically select the first one so the select isn't empty
        this.searchParameters.terminal = terminals[0].value;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }

  @HostListener('document:click', ['$event'])
  onClick(event): void {
    UiHelpers.closeOpenCalendars(this.datepickerList, event);
  }

  formatAirports = (airport: OBTAirportDetails) => (airport.destination ? `${airport.destination} (${airport.gateway})` : '');

  getAirports = (text$: Observable<string>): Observable<unknown> => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.airportsClick$.pipe(filter(() => !this.inputAirport.isPopupOpen()));

    return merge(debouncedText$, this.airportsFocus$, clicksWithClosedPopup$).pipe(
      switchMap(term => (this.airportsList = this.searchService.getAirportsList(term, null, this.isArrivalLoading, null, true)))
    );
    // tslint:disable-next-line: semicolon
  };

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (activeButton.offsetTop + activeButton.clientHeight > typeAheadList.clientHeight + typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      // tslint:disable-next-line: quotemark
      console.warn("Couldn't find elements to scroll");
    }
  }
}
