import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EnterpriseSearchService,
  ServiceType,
  WithSubscriptionComponent,
  UserService
} from '@sabstravtech/obtservices/angular';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-to-favorites',
  templateUrl: './add-to-favorites.component.html',
  styleUrls: ['./add-to-favorites.component.scss']
})
export class AddToFavoritesComponent extends WithSubscriptionComponent {
  @Input() serviceType: ServiceType | string = '';
  @Output() showErrors = new EventEmitter<boolean>();
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    private snackBar: MatSnackBar
  ){
    super();
  }

  saveFavouriteSearch() {
    this.searchService.isFavouriteSearch = true;
    if (!this.searchService.searches[this.serviceType].isFavouriteValid.value) {
      this.searchService.validateForms(true);
      console.log('Final Errors:', this.searchService.errors.value);
      this.showErrors.emit(true);
      return;
    }

    this.subscribe(this.searchService.searches[this.serviceType].saveSearch(), (search) => {
      this.searchService.isFavouriteSearch = false;
      this.searchService.errors.next([]);
      this.showErrors.emit(false);
      if (search) {
        this.snackBar.open(this.serviceType.toUpperCase() + ' added to favourite search', '', {
          duration: 3000
        });
      }
    }, error => {
      console.error('Error loading favorite items:', error);
    });
  }
}
