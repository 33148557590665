<button
      i18n-title
      (keydown.enter)="saveFavouriteSearch()"
      (keydown.space)="saveFavouriteSearch()"
      id="addtofavourites-button"
      class="large-button large-button--search add-to-favorites-wrapper"
      title="Add to Favourites"
      (click)="saveFavouriteSearch()"
      i18n="@@addToFavourites">
      Add to Favourites
</button>