<div class="taxi-search-container background-46"
     [class.results-mode]="resultsMode">
  <div class="half-container">
    <div class="half-block half-container--tb-margin">
      <div class="flex-row-container"
      [ngClass]='{"error-box": searchParams.validationMessages?.includes("Taxi journey cannot be in the past.")}'>
        <ngbd-datepicker-i18n>
          <div class="flex-column-container combo-box-left">
            <label class="header-label date-label"
                   for="search-taxi-pickup-date"
                   i18n="@@taxiSearchDateAndTime">Date (DD/MM/YYYY)</label>
             <i class="ico-calendar combo-box-icon" aria-hidden="true"
             style="cursor:pointer"
             ></i>
            <app-datepicker
                [minDate]='today'
                [startDate]='searchParams.date'
                [model]="searchParams.date"
                [class]="'combo-box'"
                [componentId]="'search-taxi-pickup-date'"
                (newDate)="searchParams.date = $event"
              >
              </app-datepicker>
          </div>
          <div class="flex-column-container select-box-right">
            <label id="search-taxi-pickup-time"
                   class="header-label"
                   i18n="@@SearchTaxiPickupTime"
                   for="taxi_pickup-time">Time</label>
              <i class="ico-clock combo-box-icon" aria-hidden="true"></i>
              <select class="select-box"
                      id="taxi_pickup-time"
                      [(ngModel)]="searchParams.time">
                <option *ngFor="let time of timesArray"
                        [ngValue]="time">{{time}}</option>
              </select>
          </div>
      </ngbd-datepicker-i18n>
      </div>
    </div>
    <div class="half-block half-container--tb-margin">
      <div class="flex-row-container">
        <label id="taxi-search-transport-type"
          class="header-label"
          for="taxi_type"
          i18n="@@taxiSearchTransportType">Transport type:
        </label>
        <section (mouseleave)="tt.close()" *ngIf="hasGroundspan">
          <i
            class="ico ico-info-circle"
            ngbTooltip="If you require a Chauffer Driven car please select Limo as your transport type."
            aria-label="If you require a Chauffer Driven car please select Limo as your transport type."
            i18n-ngbTooltip
            i18n-aria-label
            placement="bottom"
            triggers="manual"
            #tt="ngbTooltip"
            (mouseenter)="tt.open()"
            (focus)="tt.open()"
            (blur)="tt.close()"
            tabindex="0"></i>
          </section>
      </div>
      <select class="select-box select-box--no-icon"
              [(ngModel)]="searchParams.vehicleType"
              aria-labelledby="taxi-search-transport-type"
              id="taxi_type">
        <option *ngFor="let transType of transTypes; let i = index"
                [ngValue]="transType.key"><span id="transport-type-{{i}}">{{transType.value | translate}}</span></option>
      </select>

      <div class="limo--wrapper" *ngIf="searchParams.vehicleType === CabHireVehicleType.Limo">
        <div class="flex-column-container">
          <label class="header-label" i18n="@@limoSearchOptions" id="limo-search-options">Select search options</label>
          <div class="flex-row-container">
            <mat-radio-group [(ngModel)]="searchParams.transportType" class="radio-buttons-container"  aria-labelledby="limo-search-options">
              <mat-radio-button
                class="light-primary"
                color="primary"
                [value]="TransportType.OneWay"
                i18n="@@LimoOneWay">One way
              </mat-radio-button>
              <mat-radio-button
                class="light-primary"
                color="primary"
                [value]="TransportType.Hourly"
                i18n="@@LimoBythehour">By The Hour
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="limo--options-container">
          <div class="limo--type-container">
            <label id="limo-search-type"
            class="header-label"
            for="limo_type"
            i18n="@@taxiSearchVehicleType">Vehicle type:</label>
            <select
              class="select-box select-box--no-icon"
              aria-labelledby="limo-search-type"
              [(ngModel)]="searchParams.limoType"
              id="limo_type">
              <option *ngFor="let limoType of searchParams.limoTypes; let i = index" [ngValue]="limoType.key">
                <span id="lime-type-{{i}}">{{limoType.value}}</span></option>
            </select>
          </div>
          <div class="limo--duration-container" *ngIf="searchParams.transportType === TransportType.Hourly">
            <label id="limo-search-duration"
            class="header-label"
            for="limo_duration"
            i18n="@@taxiSearchDuration">Duration:</label>
            <select
              class="select-box select-box--no-icon"
              aria-labelledby="limo-search-duration"
              [(ngModel)]="searchParams.limoAsDirectedDuration"
              id="limo_duration">
              <option *ngFor="let limoAsDirectedDuration of limoDurations; let i = index" [value]="limoAsDirectedDuration"><span id="limo-durations-{{i}}">{{limoAsDirectedDuration}}</span> <span i18n="@@taxiSearchHours">hours</span> </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span i18n="@@required_hint"
  >Required mandatory fields are marked with an asterisk (*) and must be filled in to complete
  the form.</span>
  <div class="half-container half-container--start half-container--tb-margin">
    <div class="half-block">
      <span id="aria-taxi-pickup-address"
            hidden
            i18n="@@AriaTaxiPickupAddress">Pickup address</span>
        <div [ngClass]='{"error-box": searchParams.validationMessages?.includes("From - Enter a location or postcode.")}'>
          <app-taxi-address-form [(address)]="searchParams.fromAddress"
                             [(station)]="searchParams.fromStation"
                             [required]="true"
                             [modeType]="searchParams.mode"
                             [limo]="searchParams.vehicleType === CabHireVehicleType.Limo"
                             i18n-title
                             title="Pick up"
                             (addressChanged)="fromAddressChange($event)"
                             (stationChange)="stationChange($event)"
                             aria-labelledby="aria-taxi-pickup-address"
                             >
          </app-taxi-address-form>
        </div>
      <span id="aria-taxi-via-address1"
            hidden
            i18n="@@AriaTaxiViaAddress">First via address</span>
      <app-taxi-address-form [(address)]="searchParams.viasFrom"
                             i18n-title
                             [limo]="searchParams.vehicleType === CabHireVehicleType.Limo"
                             title="Pickup Via"
                             (addressChanged)="viaPickupAddress($event)"
                             (stationChange)="viaStationChange($event)"
                             class="accordion-margin-top"></app-taxi-address-form>
    </div>
    <div class="half-block">
      <span id="aria-taxi-dropoff-address"
            hidden
            i18n="@@AriaTaxiDropoffAddress">Dropoff address</span>
          <div [ngClass]='{"error-box": searchParams.validationMessages?.includes("To - Enter a location or postcode.")}'>
              <app-taxi-address-form [(address)]="searchParams.toAddress"
                             [(station)]="searchParams.toStation"
                             (addressChanged)="toAddressChange($event)"
                             (stationChange)="stationChange($event, true)"
                             [modeType]="searchParams.modeTo"
                             [limo]="searchParams.vehicleType === CabHireVehicleType.Limo"
                             [required]="true"
                             i18n-title
                             title="Drop off"
                             aria-labelledby="aria-taxi-dropoff-address"
                             ></app-taxi-address-form>
          </div>
      <span id="aria-taxi-via-address2"
            hidden
            i18n="@@AriaTaxiViaAddress2">Second via address</span>
      <app-taxi-address-form [(address)]="searchParams.viasTo"
                             i18n-title
                             title="Dropoff Via"
                             class="accordion-margin-top"
                             [limo]="searchParams.vehicleType === CabHireVehicleType.Limo"
                             (addressChanged)="viaDropoffAddress($event)"
                             (stationChange)="viaDropoffChange($event)"
                             aria-labelledby="aria-taxi-via-address2"></app-taxi-address-form>
    </div>
  </div>
  <div class="flex-row-container flex-row-container--right"
       *ngIf="resultsMode">
    <button (click)="search()"
            class="large-button large-button--search"
            i18n="@@taxiSearchSearch">
      Search
    </button>
  </div>
</div>
