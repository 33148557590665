<div class="profile_information--content" *ngIf="miDefaultForm">
        <div class="profile_information--sub-header" i18n="@@ProfileInformation-AddNewMIDefaultValue">
                Add New MI Default Value
        </div>
        <div class="warning-block" i18n="@@newMIWARNING" *ngIf="newMi?.defaultValuePrepopulation === miPrepopulationTypes.LeaveBlank">WARNING: You are adding a default value to a MI that does not accept default values.</div>
        <div class="half-container">
                <div class="half-block flex-column-container">
                        <label class="header-label" id="mi_name_label">MI Name</label>
                        <select class="profile_information--input"
                        disableOptionCentering
                        [(ngModel)]="newMi"
                        class="profile_information--input"
                        panelClass="sort-select"
                        id="mi_name_input"
                        aria-labelledby="mi_name_label"
                        >
                                <option [ngValue]="null">Not selected</option>
                                <option *ngFor="let emptyMi of emptyMiDefaults"
                                        [ngValue]="emptyMi">
                                        {{ emptyMi.name }}
                                </option>
                        </select>
                </div>
                <div class="half-block flex-column-container">
                        <label class="header-label" id="mi_value_label">MI Value</label>
                        <input [(ngModel)]="newMiValue" aria-labelledby="mi_value_label" id="mi_nvalue_input" class="profile_information--input" placeholder="Enter new MI value..."/>
                </div>
        </div>
        <div class="save-row">
                <button class="small-button"
                        (click)="saveEmptyMIValue()"
                        [disabled]="!newMi || !newMiValue">
                        Add <span class="visuallyhidden">&nbsp; new MI Value</span>
                </button>
        </div>
        <div [formGroup]="miDefaultForm">
                <div formArrayName="userMiDefaultValues" class="profile_information--mi-default-values">
                        <div class="profile_information--sub-header" i18n="@@ProfileInformation-MIDefaultValues">
                                MI Default Values
                        </div>
                        <table *ngIf="userMiDefaultValues.length; else noValues" class="width-100">
                                <thead class="profile_information--table-thead">
                                <tr>
                                        <th i18n="@@ProfileInformation-MIName">MI Name</th>
                                        <th i18n="@@ProfileInformation-MIValue">MI Value</th>
                                        <th i18n="@@ProfileInformation-Save" *ngIf="isEditable">Save</th>
                                        <th i18n="@@ProfileInformation-Delete">Delete</th>
                                </tr>
                                </thead>
                                <tbody class="profile_information--table-tbody">
                                <tr *ngFor="let miValue of userMiDefaultValues.controls; let i = index" [formGroupName]="i">
                                        <td>{{miValue.get('managementInfo').value.name}}</td>
                                        <ng-container *ngIf="isEditable">
                                                <td><input formControlName="freeTextValue" attr.aria-labelledby="defaultMI-hint-{{i}}" id="defaultMI-input-value-{{i}}"/></td>
                                                <td><button class="small-button" 
                                                        [disabled]="miValue.get('managementInfo').value.value === miValue.get('freeTextValue').value || !miValue.get('freeTextValue').value" 
                                                        id="defaultMI-button-value-{{i}}"
                                                        (click)="saveMIValue(miValue.get('managementInfo').value, miValue.get('freeTextValue').value)"
                                                        i18n="@@ProfileInformation-Save">Save</button>
                                                   <span class="visuallyhidden" id="defaultMI-hint-{{i}}">&nbsp; New value for default MI {{miValue.get('managementInfo').value.name}}</span></td>
                                                <td><button class="small-button small-button--close" 
                                                        id="deleteMI-button-value-{{i}}"
                                                        (click)="saveMIValue(miValue.get('managementInfo').value, '')"
                                                        i18n="@@ProfileInformation-Delete">Delete</button>
                                                   <span class="visuallyhidden" id="deleteMI-hint-{{i}}">&nbsp; Remove default MI {{miValue.get('managementInfo').value.name}}</span></td>
                                        </ng-container>
                                        <td *ngIf="!isEditable">{{miValue.get('managementInfo').value.value}}</td>
                                </tr>
                                </tbody>
                        </table>

                        <ng-template #noValues>
                                <p class="no-data-container" i18n="@@ProfileInformation-No-MI-Value-to-display">No MI Value to display</p>
                        </ng-template>
                </div>
        </div>
        <div class="mi_default_loading" *ngIf="isLoading">
                <div class="loading-screen-box">
                        <i class="loading-screen-spinner"></i>
                        <span class="loading-screen-text">Loading...</span>
                </div>
        </div>
</div>