<ng-template class="light-dropdown" #al let-r="result" let-t="term">
  <span>{{ r.House }}, {{ r.Street }}, {{ r.PostCode }}</span>
  <i *ngIf="r.isLoading" class="button_spinner input_loading_spinner light-spinner"></i>
</ng-template>
<ng-template #airlinesTemplate let-r="result" let-t="term">
  {{ r.name }}
</ng-template>
<ng-template #sl let-r="result" let-t="term">
  <div class="typeahead-result">
    <i class="ico-{{ r.type === LocationTypes.Airport ? 'flight' : 'rail' }}"></i>
    <span>&nbsp;{{ getList(r) }}</span>
  </div>
</ng-template>
<mat-expansion-panel class="light-expansion-panel" [disabled]="required" [expanded]="required">
  <mat-expansion-panel-header>
    <mat-panel-title
      >{{ title }}&nbsp;<span *ngIf="required" class="light-color-warning">*</span></mat-panel-title
    >
  </mat-expansion-panel-header>
  <fieldset>
    <legend class="visuallyhidden">{{ title }}</legend>
    <mat-radio-group [(ngModel)]="mode" class="radio-buttons-container">
      <mat-radio-button class="light-primary" color="primary" [value]="TaxiFormMode.ADDRESS">
        <span [attr.aria-label]="title + ' Address'" i18n="@@taxiAddressAddress">Address</span>
      </mat-radio-button>
      <div class="radio-button-wrapper">
        <mat-radio-button class="light-primary" color="primary" [value]="TaxiFormMode.STATION">
          <span [attr.aria-label]="title + ' Train St. / Airport'" i18n="@@taxiAddressTrainAirport"
            >Train St. / Airport</span
          >
        </mat-radio-button>
        <section (mouseleave)="tt.close()">
          <i
            class="ico ico-info-circle"
            ngbTooltip="If you require a Train Station outside of the UK, please perform an Address Search."
            aria-label="If you require a Train Station outside of the UK, please perform an Address Search."
            i18n-ngbTooltip
            i18n-aria-label
            placement="bottom"
            triggers="manual"
            #tt="ngbTooltip"
            (mouseenter)="tt.open()"
            (focus)="tt.open()"
            (blur)="tt.close()"
            tabindex="0">
            <span class="visuallyhidden"
              >If you require a Train Station outside of the UK, please perform an Address
              Search.</span
            >
          </i>
        </section>
      </div>
    </mat-radio-group>
    <ng-container *ngIf="mode === TaxiFormMode.ADDRESS; else stationForm">
      <label
        id="{{ transformedTitle }}-taxi-address-pick-from-saved"
        class="header-label"
        for="{{ title }}address"
        i18n="@@taxiAddressPickFromSaved"
        >Pick from saved ({{ title }}):</label
      >
      <div class="flex-row-container savedAddressDiv">
        <i
          *ngIf="loadingAddresses | async"
          class="button_spinner input_loading_spinner_no_button light-spinner savedAddressButton"></i>
        <input
          readonly
          #inputAddress="ngbTypeahead"
          [inputFormatter]="formatAddresses"
          [ngbTypeahead]="getAddresses"
          autocomplete="off"
          type="text"
          id="{{ title }}address"
          i18n-placeholder
          placeholder="Select saved address"
          [resultTemplate]="al"
          onClick="this.select()"
          name="travellingFrom"
          [(ngModel)]="address"
          (selectItem)="selectedAddress($event)"
          (focus)="updateAddress($event)"
          (click)="updateAddress($event)"
          [resultFormatter]="resultFormatBandListValue"
          class="select-box input-box--no-icon savedAddressInput"
          attr.aria-labelledby="{{ transformedTitle }}-taxi-address-pick-from-saved"
          (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
          (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
      </div>
      <label
        id="{{ transformedTitle }}-taxi-address-house-no"
        class="header-label"
        i18n="@@taxiAddressNoName"
        for="{{ title }}taxihome"
        >{{ title }} House N°/Name*:</label
      >
      <input
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-house-no"
        class="input-box input-box--no-icon"
        #inputHouse
        required
        i18n-placeholder
        placeholder="Enter number or house name"
        id="{{ title }}taxihome"
        [(ngModel)]="address.House"
        (ngModelChange)="onChange($event)" />
      <label
        id="{{ transformedTitle }}-taxi-address-street"
        class="header-label"
        for="{{ title }}taxistreet"
        i18n="@@taxiAddressStreet"
        >{{ title }} Street:</label
      >
      <input
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-street"
        class="input-box input-box--no-icon"
        #inputStreet
        [required]="searchParams.vehicleType === CabHireVehicleType.Limo"
        i18n-placeholder
        id="{{ title }}taxistreet"
        placeholder="Enter street"
        [(ngModel)]="address.Street"
        (ngModelChange)="onChange($event)" />
      <label
        id="{{ transformedTitle }}-taxi-address-city"
        class="header-label"
        for="{{ title }}town"
        i18n="@@taxiAddressTownCity"
        >{{ title }} Town / City:<span *ngIf="isGRS">*</span></label
      >
      <input
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-city"
        class="input-box input-box--no-icon"
        #inputTown
        id="{{ title }}town"
        i18n-placeholder
        placeholder="Enter town or city"
        [required]="isGRS"
        [(ngModel)]="address.Town"
        (ngModelChange)="onChange($event)" />
      <label
        id="{{ transformedTitle }}-taxi-address-county"
        class="header-label"
        for="{{ title }}county"
        i18n="@@taxiAddressCounty"
        >{{ title }} State / Province / County:<span *ngIf="isGRS">*</span></label
      >
      <input
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-county"
        class="input-box input-box--no-icon"
        #inputCounty
        id="{{ title }}county"
        i18n-placeholder
        placeholder="Enter state, province or county"
        [required]="limo"
        [(ngModel)]="address.County"
        (ngModelChange)="onChange($event)" />
      <label
        id="{{ transformedTitle }}-taxi-address-postcode"
        class="header-label"
        for="{{ title }}postcode"
        i18n="@@taxiAddressPostCode"
        >{{ title }} Post code*:</label
      >
      <input
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-postcode"
        class="input-box input-box--no-icon"
        #inputPostCode
        required
        id="{{ title }}postcode"
        i18n-placeholder
        placeholder="Enter postcode"
        [(ngModel)]="address.PostCode"
        (ngModelChange)="onChange($event)" />

      <label
        id="{{ transformedTitle }}-taxi-address-country"
        class="header-label"
        for="{{ title }}country"
        i18n="@@taxiSearchCountry"
        >Country</label
      >
      <div class="flex-row-container">
        <i class="ico-marker select-box-icon"></i>
        <select
          [(ngModel)]="address.Country"
          attr.aria-labelledby="{{ transformedTitle }}-taxi-address-country"
          class="select-box"
          required
          (ngModelChange)="onChange($event)"
          id="{{ title }}country">
          <option disabled value="null" i18n="@@carSearchSelectCountry">Select country</option>
          <option [ngValue]="country.cCode" *ngFor="let country of searchService.countries">
            {{ country.cName }}
          </option>
        </select>
      </div>
      <div *ngIf="limosCannotBeBooked" i18n="@@LimosCannotBeBooked" class="pt-2">Unfortunately Limo’s to this Country cannot be booked online. Please contact your travel team to book.</div>
      <div
        class="action-buttons flex-row-container flex-row-container--tb-margin flex-column-mobile">
        <button
          id="{{ transformedTitle }}-taxi-clear-address-button"
          (click)="clearAddress()"
          class="small-button small-button--reduced-text"
          i18n="@@taxiAddressClear">
          Clear
        </button>
        <div class="flex-row-container flex-column-mobile">
          <button
            id="{{ transformedTitle }}-taxi-remove-address-button"
            (click)="openRemoveAddressesModal()"
            [disabled]="savingAddress"
            class="small-button small-button--reduced-text small-button--flex margin-right-5">
            <i *ngIf="savingAddress" class="button_spinner"></i>
            <span *ngIf="!savingAddress" i18n="@@taxiAddressRemoveAddress"
              >Remove Saved Addresses</span
            >
          </button>
          <button
            id="{{ transformedTitle }}-taxi-save-address-button"
            (click)="saveAddress()"
            [disabled]="!validAddress() || savingAddress"
            class="small-button small-button--reduced-text small-button--flex">
            <i *ngIf="savingAddress" class="button_spinner"></i>
            <span *ngIf="!savingAddress" i18n="@@taxiAddressSaveAddress">Save Address</span>
          </button>
        </div>
      </div>
    </ng-container>
  </fieldset>
  <ng-template #stationForm>
    <label
      class="header-label"
      for="{{ title }}station"
      id="{{ transformedTitle }}-taxi-address-choose-station"
      i18n="@@taxiAddressChooseStation"
      >Choose {{ title }} Station:</label
    >
    <div class="flex-row-container">
      <input
        #inputStation="ngbTypeahead"
        [inputFormatter]="formatStations"
        [resultTemplate]="sl"
        [ngbTypeahead]="getStations"
        autocomplete="off"
        type="text"
        id="{{ title }}station"
        i18n-placeholder
        placeholder="Select train station or airport"
        name="travellingFrom"
        container="body"
        placement="bottom-left"
        [(ngModel)]="station"
        (ngModelChange)="stationChange.emit(station); setAirportRail(station)"
        (focus)="updateStation($event)"
        (click)="updateStation($event)"
        class="button-box input-box--no-icon"
        attr.aria-labelledby="{{ transformedTitle }}-taxi-address-choose-station"
        (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
        (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
      <i
        *ngIf="loadingStations | async"
        class="button_spinner input_loading_spinner_no_button light-spinner"></i>
    </div>
    <label
      id="{{ transformedTitle }}-taxi-address-county"
      class="header-label"
      for="{{ title }}county"
      i18n="@@taxiAddressCounty"
      >{{ title }} State / Province / County:<span *ngIf="isGRS">*</span></label
    >
    <input
      attr.aria-labelledby="{{ transformedTitle }}-taxi-address-county"
      class="input-box input-box--no-icon"
      #inputCounty
      id="{{ title }}county"
      i18n-placeholder
      placeholder="Enter state, province or county"
      [required]="limo"
      [(ngModel)]="address.County"
      (ngModelChange)="onChange($event)" />
    <br />
    <div *ngIf="type === LocationTypes.Airport && address.FlightNumber || address.Terminal" flex-row-container>
      <h2 style="color: white" i18n="@@taxiAddressFlightDetails">Flight Details</h2>
      <label class="header-label" i18n="@@taxiAddressChooseTerminal" for="{{ title }}terminal"
        >Terminal*</label
      >
      <select
        class="button-box input-box--no-icon"
        [value]="terminals[0].key"
        (change)="addTerminal($event.target.value)"
        required>
        <option class="light-dropdown" *ngFor="let terminal of terminals" [value]="terminal.key">
          {{ terminal.key | translate }}
        </option></select
      ><br />
      <div class="flight-info-container">
        <div class="flight-info-item">
          <label
            class="header-label"
            i18n="@@taxiAddressFlightNo"
            id="{{ transformedTitle }}-taxi-address-fligh-number"
            for="{{ title }}flightNo"
            >Flight Number</label
          >
          <span *ngIf="searchParams.vehicleType === CabHireVehicleType.Limo">*</span>
          <input
            type="text"
            [required]="searchParams.vehicleType === CabHireVehicleType.Limo"
            id="{{ title }}flightNo"
            [(ngModel)]="address.FlightNumber"
            (ngModelChange)="onChange($event)"
            attr.aria-labelledby="{{ transformedTitle }}-taxi-address-fligh-number"
            i18n-placeholder
            maxlength="10"
            placeholder="e.g. BA123"
            maxlength="10"
            class="input-box input-box--no-icon" />
        </div>
        <div *ngIf="searchParams.vehicleType === CabHireVehicleType.Limo" class="flight-info-item">
          <label
            class="header-label"
            id="{{ transformedTitle }}-taxi-flight-time"
            for="{{ title }}flightTime">
            <span i18n="@@taxiFlight"> Flight </span>
            <span *ngIf="title.includes('Pick')" i18n="@@taxiFlightArrival"> Arrival </span>
            <span *ngIf="title.includes('Drop')" i18n="@@taxiFlightDeparture"> Departure </span>
            <span i18n="@@taxiFlightTime"> Time* </span>
          </label>

          <div class="flex-row-container">
            <i class="ico-clock combo-box-icon combo-box-icon--left" aria-hidden="true"></i>
            <select
              required
              id="{{ title }}flightTime"
              class="select-box"
              [(ngModel)]="address.FlightTrainDateTime"
              attr.aria-labelledby="{{ transformedTitle }}-taxi-flight-time"
              tabindex="0">
              <option
                *ngFor="let time of getTimeArray(searchParams.time, title.includes('Drop'))"
                [ngValue]="time">
                {{ time }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div *ngIf="searchParams.vehicleType === CabHireVehicleType.Limo">
        <label
          class="header-label"
          i18n="@@taxiAddressAirline"
          id="{{ transformedTitle }}-taxi-address-airline"
          for="{{ title }}Airline"
          >Airline*</label
        >
        <input
          autocomplete="off"
          [ngClass]="{ invalidAirline: invalidAirlineSelected }"
          required
          name="flight_airlines"
          i18n-placeholder
          placeholder="Select airline"
          container="body"
          placement="bottom-left"
          type="text"
          class="input-box input-box--no-icon"
          [(ngModel)]="carrier"
          [ngbTypeahead]="getAirline"
          [resultTemplate]="airlinesTemplate"
          [inputFormatter]="formatter_airlines"
          (focus)="updateAirline($event)"
          (click)="updateAirline($event)"
          (ngModelChange)="onChange($event)"
          attr.aria-labelledby="{{ transformedTitle }}-taxi-address-airline"
          id="{{ title }}Airline"
          (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
          (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
        <div
          *ngIf="invalidAirlineSelected"
          class="error-message mt-2"
          i18n="@@taxiPleaseSelectAnAirline">
          Please select an airline from the list.
        </div>
      </div>

      <!-- <label class="header-label"
                            i18n="@@taxiAddressFlightTime"
                            for="{{title}}flightTime">Time</label>
                     <input type="text"
                            id="{{title}}flightTime"
                            [(ngModel)]="searchParams.time"
                            class="button-box input-box--no-icon" /><br> -->
    </div>
    <div
      *ngIf="
        type === LocationTypes.TrainStation && searchParams.vehicleType === CabHireVehicleType.Limo
      "
      flex-row-container>
      <h2 style="color: white" i18n="@@taxiAddressTrainDetails">Train Details</h2>
      <div class="train-details">
        <div class="train-operator-container">
          <label
            class="header-label"
            i18n="@@taxiATrainOperator"
            id="{{ transformedTitle }}-taxi-train-operator"
            for="{{ title }}trainOperator"
            >Train Operator*</label
          >
          <input
            attr.aria-labelledby="{{ transformedTitle }}-taxi-address-train-operator"
            class="input-box input-box--no-icon"
            required
            i18n-placeholder
            id="{{ transformedTitle }}-taxi-train-operator"
            placeholder="Select Train Operator"
            [(ngModel)]="carrier"
            (ngModelChange)="onChange($event)" />
        </div>
        <div class="train-time-container">
          <label
            class="header-label"
            id="{{ transformedTitle }}-taxi-address-train-time"
            i18n="@@taxiAddressTrainTime"
            for="{{ title }}TrainTime">
            <ng-container [ngSwitch]="title">
              <ng-container *ngSwitchCase="'Pick up'">Arrival</ng-container>
              <ng-container *ngSwitchCase="'Pickup Via'">Arrival</ng-container>
              <ng-container *ngSwitchCase="'Drop off'">Departure</ng-container>
              <ng-container *ngSwitchCase="'Dropoff Via'">Departure</ng-container>
              Time*
            </ng-container>
          </label>
          <div class="train-time-wrapper">
            <i class="ico-clock select-box-icon--left" aria-hidden="true"></i>
            <select
              required
              id="{{ title }}TrainTime"
              class="select-box"
              [(ngModel)]="address.FlightTrainDateTime"
              attr.aria-labelledby="{{ transformedTitle }}-taxi-address-train-time"
              tabindex="0">
              <option
                *ngFor="let time of getTimeArray(searchParams.time, title.includes('Drop'))"
                [ngValue]="time">
                {{ time }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</mat-expansion-panel>
