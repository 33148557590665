import { AfterViewInit, Component, Input, OnInit, EventEmitter, Output, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  HotelAvalibilityQuoteResult,
  AmenitiesSearchHandler,
  HotelEnterpriseSearchInterface,
  HotelQuoteResult,
  URLS,
  ServiceProvider,
  HotelRoomWithLocalPrice,
  HotelQuoteResultWithExtension,
  HotelDetailTypes
} from '@sabstravtech/obtservices/base';
import {
  LightningSuppliers,
  LightningUserFavorurite
} from '../../../../vendor/classes/user-favourite.enum';
import {
  EnterpriseSearchService,
  ModalOpenerService,
  UserService,
  WithSubscriptionComponent,
  HotelRatingInfoAndTags,
  HotelGroupStaticTags,
  HotelStaticTagDisplay,
  HotelRoom,
  EnterpriseBasketService
} from '@sabstravtech/obtservices/angular';
import { ServiceType } from '@sabstravtech/obtservices/angular';

import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { Helpers } from '../../../../vendor/classes/helpers';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { Router } from '@angular/router';
import { HotelRoomStatus } from '../../../../vendor/enum/hotel-room-status.enum';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import {
  faLeaf,
  faLocationDot
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-item-hotels',
  templateUrl: './item-hotels.component.html',
  styleUrls: ['./item-hotels.component.scss']
})
export class ItemHotelsComponent
  extends WithSubscriptionComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Output() changeFilter: EventEmitter<string> = new EventEmitter<string>();

  @Input() hotelDetail: HotelQuoteResult;
  @Input() holidayCode: string;
  @Input() viewControl: any;
  @Input() noOfRooms: number;
  @Input() index: number;
  @Input() rooms: HotelAvalibilityQuoteResult;
  @Input() jitRulesReceived: boolean;
  @Input() distanceUnit: string;
  @Input() localCurrency: string;
  @Input() hotelRatingsInfo: HotelRatingInfoAndTags;
  @Input() hotelGroupTags: HotelGroupStaticTags;

  public ThumbUrl: string;

  public URLS: URLS;

  public code: string;
  public name: string;
  public noOfNights: number;
  public preferredLogoUrl: any;
  public rateSelected: string;
  public updateConfermaURL: string;
  public debug: boolean = false;
  public ServiceProvider: typeof ServiceProvider = ServiceProvider;
  private hotelFacilitiesModal: NgbModalRef;
  private Thmb2Url: string;
  resultItemType = resultIcons;

  public imageUrl: string = URLS.CONFIRMA_PHOTO_URL;
  public infoUrl: string = URLS.CONFIRMA_INFO_URL;
  public showAllPhotos: boolean = false;
  Suppliers: typeof LightningSuppliers = LightningSuppliers;
  searchParams: HotelEnterpriseSearchInterface;
  hotelRoomStatus: typeof HotelRoomStatus = HotelRoomStatus;
  hotelPreferredImg = null;
  showTMCPreferredLogo: boolean = false;
  tmcPreferredLogo: string = '';
  tmcPreferredMessage = '';
  preferred = false;
  companyPreferredMessage = '';
  officePreferredMessage = '';
  consumerPreferred =
    'https://images.sabscorp.com/images/enterprise/lightUK/assets/images/CTMPortraitLogo.png';
  staticTagDisplay = HotelStaticTagDisplay;
  faLocationDot = faLocationDot;
  displayLocationData = false;
  hotelDetailTypes = HotelDetailTypes;
  AmenitiesSearchHandler = AmenitiesSearchHandler;
  faLeaf = faLeaf;
  constructor(
    private searchService: EnterpriseSearchService,
    public modalOpenerService: ModalOpenerService,
    public userService: UserService,
    private readonly router: Router,
    public iconLibrary: FaIconLibrary,
    private element: ElementRef,
    private enterpriseBasketService: EnterpriseBasketService
  ) {
    super();

    this.searchParams = this.searchService.searches[ServiceType.Hotel];
    this.iconLibrary.addIconPacks(far);
  }

  open(content: any): void {
    let dataValue: any = {
      hotel: content
    };
    this.modalOpenerService.open(LightningModalTypes.HotelFacilitiesComponent, {}, dataValue);
  }

  closeFacilities(): void {
    this.hotelFacilitiesModal.close();
  }

  ngAfterViewInit(): void {
    if (this.viewControl?.hotelChosen) {
      console.log('hotel selected');
    }
  }

  openHotelPhotosDialog() {
    this.modalOpenerService.open(
      LightningModalTypes.SeePhotosDialogComponent,
      { centered: true, size: 'lg' },
      {
        hotelId: this.hotelDetail.id,
        isConferma: this.hotelDetail.source === ServiceProvider.Confirma,
        hotelImages:
          this.hotelDetail.source !== ServiceProvider.Confirma
            ? this.hotelDetail.availableRates?.additional.hotelImages
            : []
      }
    );
  }

  openHotelInfoDialog() {
    let address = this.getAddress(this.hotelDetail);
    this.modalOpenerService.open(
      LightningModalTypes.HotelInfoDialogComponent,
      { centered: true, size: 'md' },
      {
        hotelDetail: this.hotelDetail,
        thumbUrl: this.ThumbUrl,
        address: address
      }
    );
  }

  checkPreferred(HotelDetail: HotelQuoteResult, num: string): boolean {
    return false; // HotelDetail.Preferred === num || HotelDetail.Preferred === '3';
  }

  showOnMap(): void {
    this.searchParams.selectItemMapView.next([this.hotelDetail]);
  }

  openRoomRates(): void {
    this.rooms.rooms.sort(
      (a: HotelRoomWithLocalPrice, b: HotelRoomWithLocalPrice) => a.total - b.total
    );

    let railTravellers: number;
    if (this.router.url.includes('wellbeing')) {
      railTravellers = this.searchService.searches[ServiceType.Rail].originalUserSearch.noOfAdults;
    }
    const modalData = {
      hotel: this.hotelDetail,
      rooms: this.rooms,
      // for number of rooms we should make this match no of travellers for now if we do wellbeing
      noOfRooms: this.noOfRooms || railTravellers,
      changeFilterChain: this.changeFilter
    };

    //this.filterHotelService.setLastSelectedHotel(modalData);
    this.modalOpenerService.open(
      LightningModalTypes.RatesHotelComponent,
      { windowClass: 'modal-extra-large' },
      modalData
    );

    // this.subscribe(roomRateDialog.afterClosed(), result => {
    //   if (result && result.length > 0) {
    //     for (let i = 0; i < result.length; i++) {
    //       this.addToBasket(result[i].rate, this.HotelDetail);
    //     }
    //   }
    // });
  }

  ngOnInit(): void {
    this.code = this.hotelDetail.id.toString();
    const preferredLogo = this.userService.getUserFavoriteObject(
      LightningUserFavorurite.hotelPreferredImage
    );
    if (preferredLogo) {
      this.preferredLogoUrl = preferredLogo;
    }

    this.ThumbUrl = this.Thmb2Url =
      URLS.CONFERMA_THUMBNAIL_URL +
      this.code.charAt(0) +
      '/' +
      this.code.charAt(1) +
      '/' +
      this.code +
      '.jpg';

    this.noOfNights = Helpers.ngDateToMoment(this.searchParams.checkout_date_ngb).diff(
      Helpers.ngDateToMoment(this.searchParams.checkin_date_ngb),
      'days'
    );
    this.debug = (<any>window).showDebug;

    this.imageUrl = this.hotelDetail.availableRates?.imageUrl
      ? this.hotelDetail.availableRates.imageUrl
      : this.hotelDetail.availableRates?.additional.hotelImages[0]
        ? this.hotelDetail.availableRates?.additional.hotelImages[0]
        : this.ThumbUrl;
    if (
      this.hotelDetail.availableRates?.rooms?.[0]?.tmcPreferred
    ) {
      this.showTMCPreferredLogo = true;
      this.tmcPreferredLogo = this.hotelDetail.availableRates?.rooms?.[0]?.tmcPreferredLogoUrl;
      this.tmcPreferredMessage = this.hotelDetail.availableRates?.rooms?.[0]?.tmcPreferredMessage;
    }

    this.preferred = this.hotelDetail.availableRates?.rooms?.[0]?.companyPreferred || this.hotelDetail.availableRates?.rooms?.[0]?.officePreferred;
    this.companyPreferredMessage = (this.hotelDetail.availableRates?.rooms?.[0]?.companyPreferredMessage || '');
    this.officePreferredMessage = (this.hotelDetail.availableRates?.rooms?.[0]?.officePreferredMessage || '');


    this.showAllPhotos =
      this.hotelDetail.source !== ServiceProvider.SabreCSL
        ? true
        : this.hotelDetail.availableRates?.additional.hotelImages
          ? true
          : false;
    this.applyCustomCSS();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.applyCustomCSS();
    this.imageUrl = this.hotelDetail.availableRates?.imageUrl
      ? this.hotelDetail.availableRates.imageUrl
      : this.hotelDetail.availableRates?.additional.hotelImages[0]
        ? this.hotelDetail.availableRates?.additional.hotelImages[0]
        : this.ThumbUrl;
    this.setDisplayLocationData();
  }

  /**
  @desc - add a room to the basket - place it in a ChosenRoom - param for the hotel
  **/
  addToBasket(room: any, hotel: HotelQuoteResult): void {
    // this.searchParams.addToBasket(
    //   room,
    //   hotel,
    //   this.userService.getUserHotelTransactionFee(),
    //   this.basketService,
    //   this.travelService.getSearchParams(TravelTypeEnum.HOTEL)
    // );
  }

  /**
   * @desc - get the convertedTotal from the room with the lowest total price (displays in the template for non GB results)
   * @param availabilityQuoteResult
   **/
  getConvertedTotal(availabilityQuoteResult: HotelAvalibilityQuoteResult): number {
    let convertedTotal = 0;
    if (availabilityQuoteResult && availabilityQuoteResult.rooms?.length > 1) {
      const rooms = availabilityQuoteResult.rooms;
      const lowestTotal = rooms.reduce(
        (curr, prev) => (prev.total < curr ? prev.total : curr),
        rooms[0].total
      );
      const roomWithLowestTotal = rooms.find(room => room.total === lowestTotal);
      convertedTotal = roomWithLowestTotal?.currencyConversion?.convertedTotal ?? 0;
    }
    return convertedTotal;
  }

  getUrlFromTags(tags: { Text: string; }[], fieldName: string) {
    return Helpers.fromObject(
      tags.find(tag => {
        return tag.Text === fieldName;
      }),
      'URL'
    );
  }

  getAddress(HotelDetail: HotelQuoteResult) {
    if (HotelDetail?.address?.addressLine2 && HotelDetail?.address?.addressLine3) {
      return `${HotelDetail?.address?.addressLine2}, ${HotelDetail?.address?.addressLine3}, ${HotelDetail?.address?.postOrZipCode}`;
    } else {
      return `${HotelDetail?.address?.addressLine1}, ${HotelDetail?.address?.postOrZipCode}`;
    }
  }

  getPrice(rooms: any) {
    if (rooms && rooms?.rooms?.length >= 1) {
      const roomsWithMinPrice = rooms.rooms.filter(room => room.total === rooms.minprice);
      const roomPrice = roomsWithMinPrice.length ? rooms.minprice : rooms.rooms[0].total;
      return roomPrice * this.noOfRooms;
    }
  }

  log(e, f) {
    console.log(e, f);
  }

  openHotelResultModal(index: number = 0) {
    this.rooms.rooms.sort(
      (a: HotelRoomWithLocalPrice, b: HotelRoomWithLocalPrice) => a.total - b.total
    );

    let railTravellers: number;
    if (this.router.url.includes('wellbeing')) {
      railTravellers = this.searchService.searches[ServiceType.Rail].originalUserSearch.noOfAdults;
    }

    const address = this.getAddress(this.hotelDetail);

    const modalData = {
      hotel: this.hotelDetail,
      rooms: this.rooms,
      // for number of rooms we should make this match no of travellers for now if we do wellbeing
      noOfRooms: this.noOfRooms || railTravellers,
      changeFilterChain: this.changeFilter,
      hotelId: this.hotelDetail.id,
      isConferma: this.hotelDetail.source === ServiceProvider.Confirma,
      hotelImages:
        this.hotelDetail.source === ServiceProvider.SabreCSL
          ? this.hotelDetail.availableRates?.additional.hotelImages
          : [],
      thumbUrl: this.ThumbUrl,
      address: address,
      index: index,
      hotelRatingsInfo: this.hotelRatingsInfo,
      distanceUnit: this.distanceUnit
    };

    //this.filterHotelService.setLastSelectedHotel(modalData);
    this.modalOpenerService.open(
      LightningModalTypes.HotelResultModalComponent,
      { windowClass: 'modal-extra-large' },
      modalData
    );
  }

  openHotelReviewsDialog() {
    this.modalOpenerService.open(
      LightningModalTypes.ModalHotelReviewsComponent,
      { centered: true, size: 'md' },
      {
        hotelRatingsInfo: this.hotelRatingsInfo
      }
    );
  }

  applyCustomCSS(): void {
    if (this.rooms?.rooms?.length) {
      const customCSS = this.rooms.rooms[0].customCSS;
      if (customCSS) {
        const styleElement = document.createElement('style');
        this.element.nativeElement.id = `custom_css_${this.hotelDetail.id}`;
        styleElement.appendChild(document.createTextNode(`app-item-hotels#custom_css_${this.hotelDetail.id} {${customCSS}}`));
        this.element.nativeElement.appendChild(styleElement);
      }
    }
  }

  /**
    @desc - adds hotel room to basket
  **/
    async addToCart(hotelRoom: HotelRoom): Promise<void> {
      const ogSearch = this.searchService.searches[ServiceType.Hotel].originalUserSearch;
      // now overwrite the search
      if (ogSearch?.no_of_rooms > 1) {
        await this.modalOpenerService.open(LightningModalTypes.WarningModalComponent);
        await this.searchService.searches[ServiceType.Hotel].addHotelRoomToBasket(
          this.hotelDetail,
          hotelRoom,
          this.searchService.searches[ServiceType.Hotel].getSearchQuery(true)
        );
      } else {
        await this.searchService.searches[ServiceType.Hotel].addHotelRoomToBasket(
          this.hotelDetail,
          hotelRoom,
          this.searchService.searches[ServiceType.Hotel].getSearchQuery(true)
        );
      }
      this.enterpriseBasketService.toggleMenu();
    }

    openFareRules(room: HotelRoomWithLocalPrice, source: string, name: string): void {
      this.modalOpenerService.open(
        LightningModalTypes.GmtHotelModalFareRulesComponent,
        {
          windowClass: 'fare-rules-modal',
          centered: true
        },
        {
          room: room,
          source: source,
          name: name
        }
      );
    }

    setDisplayLocationData() : void {
      if (this.hotelDetail.transports?.length) {
        const displayableLocations = this.hotelDetail.transports.filter((transport) => {
          return transport.type === this.hotelDetailTypes.METRO || transport.type === this.hotelDetailTypes.AIRPORT || transport.type === this.hotelDetailTypes.TRAIN;
        });
        this.displayLocationData = displayableLocations.length > 0;
      } else {
        this.displayLocationData = false;
      }
    }
}

