<ng-template #railairtemp let-r="result" let-t="term">
  <div class="light-flex">
    <i class="ico-flight item-icon" *ngIf="!r.group" aria-hidden="true"></i>
    <span *ngIf="r.group" class="multiairportgrouping">
      <i class="ico-flight" aria-hidden="true"></i>
    </span>
    <span class="item-code">{{ r.code }}</span>
    <span class="item-dest">{{ r.destination | flightFormat }} ({{ r.gateway }})</span>
  </div>
</ng-template>

<ng-template #airlinesTemplate let-r="result" let-t="term">
  {{ r.name }}
</ng-template>

<ng-container
  *ngIf="{
    criteria: (searchParams.timeCriteriaOptions | async) || [],
    flightOperators: (searchParams.flightOperators | async) || [],
    avaliableClasses: (searchParams.avaliableClasses | async) || []
  } as data">
  <div id="flightSearchForm">
    <p>
      <app-light-toggle-group>
        <app-light-toggle-btn
          [selected]="searchParams.chosenSearchType === FlightSearchType.oneWay"
          (click)="searchParams.chosenSearchType = FlightSearchType.oneWay; searchService.isFavoritesSelected = false"
          iconClass="ico-oneway"
          id="flight-tab-one-way"
          i18n="@@flightSearchOneWay"
          >One Way Only</app-light-toggle-btn
        >
        <app-light-toggle-btn
          [selected]="searchParams.chosenSearchType === FlightSearchType.return"
          (click)="searchParams.chosenSearchType = FlightSearchType.return; searchService.isFavoritesSelected = false"
          iconClass="ico-return"
          id="flight-tab-return"
          i18n="@@flightSearchReturn"
          >Return trip</app-light-toggle-btn
        >
        <app-light-toggle-btn
          [selected]="searchParams.chosenSearchType === FlightSearchType.multiCity"
          *ngIf="showMultiCity"
          (click)="setUpMultiCitySearch()"
          iconClass="ico-return"
          id="flght-tab-multi-city"
          i18n="@@flightSearchMultiCity"
          >Multi-City</app-light-toggle-btn
        >
        <app-light-toggle-btn
          [selected]="searchService.isFavoritesSelected"
          *ngIf="showFavourites"
          (click)="searchService.isFavoritesSelected = true; searchParams.chosenSearchType = null"
          iconClass="ico-return"
          id="flght-tab-favourites"
          i18n="@@searchFavourites"
          >Favourites
        </app-light-toggle-btn>
      </app-light-toggle-group>
    </p>

    <ng-container *ngIf="!searchService.isFavoritesSelected;else favoriteSearchContainer">
    <div *ngIf="searchParams.chosenSearchType !== FlightSearchType.multiCity">
      <fieldset>
        <legend class="visuallyhidden">Outbound Flight</legend>
        <div class="inputs-container">
          <div class="two-thirds-container flex-row-container--end">
            <div class="two-third">
              <div class="flex-row-container flight-input-mobile flex-row-container--end">
                <div class="flex-column-container combo-box-left">
                  <label
                    id="outboundFlightDep"
                    class="header-label split-label"
                    i18n="@@flightSearchOutboundDep"
                    >Outbound Flight Departure</label
                  >
                  <i class="ico-icon-takeoff combo-box-icon" aria-hidden="true"></i>
                  <input
                    autocomplete="off"
                    name="flight1"
                    i18n-placeholder
                    placeholder="Depart from.."
                    type="text"
                    id="flight-input-outbound-flight-depart"
                    class="input-box"
                    [(ngModel)]="searchParams.departLocation"
                    [ngbTypeahead]="flightLocations"
                    [resultTemplate]="railairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (click)="$event.currentTarget.select()"
                    (focus)="focus$.next($event.target.value)"
                    aria-labelledby="outboundFlightDep"
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                    [ngClass]="{
                      'error-box': searchParams.validationMessages?.includes(
                        'Please add in a departure location'
                      )
                    }" />
                  <i
                    *ngIf="departLocationIsLoading | async"
                    class="button_spinner input-loading-spinner-left"></i>
                </div>
                <div class="flex-column-container combo-box-right">
                  <label
                    id="outboundFlightArr"
                    class="header-label split-label"
                    i18n="@@flightSearchOutboundArr"
                    >Outbound Flight Arrival</label
                  >
                  <i class="ico-icon-landing combo-box-icon" aria-hidden="true"></i>
                  <input
                    #ofa
                    autocomplete="off"
                    name="flight1"
                    i18n-placeholder
                    id="flight-input-outbound-flight-arrive"
                    placeholder="Flying to.."
                    type="text"
                    class="input-box"
                    [(ngModel)]="searchParams.arriveLocation"
                    (selectItem)="checkHotelCheckbox()"
                    [ngbTypeahead]="flightLocations2"
                    [resultTemplate]="railairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (click)="$event.currentTarget.select()"
                    (focus)="focus2$.next($event.target.value)"
                    aria-labelledby="outboundFlightArr"
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                    [ngClass]="{
                      'error-box': searchParams.validationMessages?.includes(
                        'Please add in an arrival destination'
                      )
                    }" />
                  <i
                    *ngIf="arriveLocationIsLoading | async"
                    class="button_spinner input-loading-spinner-right"></i>
                </div>
              </div>
            </div>
            <div class="one-third">
              <div
                class="flex-row-container"
                [ngClass]="{
                  'error-box': !searchService.isFavouriteSearch && searchParams.validationMessages?.includes(
                    'Departure date can not be in the past'
                  )
                }">
                <ngbd-datepicker-i18n>
                  <div class="flex-column-container combo-box-left">
                    <label class="header-label date-label" i18n="@@flightSearchFlightDate" for="outbound_flight_date">Flight Date (DD/MM/YYYY)</label>
                    <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                    <app-datepicker
                      [minDate]="searchParams.minimumOutBoundDate"
                      [startDate]="searchParams.departDate"
                      [model]="searchParams.departDate"
                      [class]="'combo-box'"
                      [componentId]="'outbound_flight_date'"
                      (newDate)="searchParams.departDate = $event; checkHotelCheckbox()">
                    </app-datepicker>
                  </div>
                  <div class="flex-column-container select-box-right">
                    <label
                      class="header-label"
                      i18n="@@flightSearchFlightTime"
                      for="outbound_flight_time"
                      >Flight Time</label
                    >
                    <i class="ico-clock select-box-icon"></i>
                    <select
                      #oft
                      id="outbound_flight_time"
                      class="select-box"
                      [ngClass]="{
                        time_disabled: searchParams.outTimeCriteria === TimeWindow.Anytime,
                        'error-box': !searchService.isFavouriteSearch && searchParams.validationMessages?.includes(
                          'The time of arrival cannot be less than the time of departure'
                        )
                      }"
                      [(ngModel)]="searchParams.departTime"
                      [disabled]="searchParams.outTimeCriteria === TimeWindow.Anytime"
                      aria-labelledby="aria-flight-outbound-time_1"
                      tabindex="0">
                      <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                        {{ time.display }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="time-flexibility-container"
                    *ngIf="searchParams.outTimeCriteria === TimeWindow.Depart">
                    <span
                      id="aria-out-time-flexibility"
                      hidden
                      i18n="@@AriaOutboundTimeControlFlight"
                      >Ability to increase / decrease outbound Flight time window in hours</span
                    >
                    <div class="flex-column-container">
                      <label
                        class="header-label"
                        i18n="@@flightSearchFlex"
                        for="outbound_flight_flexibility"
                        >Flexibility</label
                      >
                      <app-plus-minus-icon></app-plus-minus-icon>
                      <select
                        [ngClass]="{
                          time_disabled: searchParams.outTimeCriteria === TimeWindow.Anytime
                        }"
                        [disabled]="searchParams.outTimeCriteria === TimeWindow.Anytime"
                        name="out-time-flexibility"
                        i18n-placeholder
                        aria-describedby="aria-out-time-flexibility"
                        class="select-box"
                        [(ngModel)]="searchParams.outTimeFlexibility"
                        id="outbound_flight_flexibility">
                        <option *ngFor="let hour of flexibilityHours" [ngValue]="hour">
                          {{ hour }}
                        </option>
                      </select>
                    </div>
                  </div>
                </ngbd-datepicker-i18n>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex-row-container flex-row-container--right flex-row-container--tb-margin flex-row-container--reset">
          <mat-radio-group
            [(ngModel)]="searchParams.outTimeCriteria"
            aria-label="outbound flight time preferences"
            id="flight-outbound-flight-time-pref"
            i18n-aria-label="@@AriaOutboundFlightTimePreferences"
            class="triple-radio-buttons">
            <mat-radio-button
              class="light-primary"
              color="primary"
              [value]="flightcrit"
              id="flight-radio-crit-{{ flightcrit ? (flightcrit | removeSpaces) : '' }}"
              *ngFor="let flightcrit of data.criteria">
              <span>{{ flightcrit | translate }}</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </fieldset>
    </div>
    <!-- Multi-city search -->
    <div *ngIf="searchParams.chosenSearchType === FlightSearchType.multiCity">
      <app-multi-city-search
        [flightDestinationArray]="multiCitySearch"
        [flightLocations]="flightLocations"
        [flightLocations2]="flightLocations2"
        [formatter_rail_air]="formatter_rail_air"
        [minDate]="searchParams.minDate"
        [minNextDate]="minimumMultiCityDate"
        [outTimeCriteria]="searchParams.outTimeCriteria"
        [criteria]="data.criteria"
        (selectedInput)="updateMultiCityDateTime($event)"></app-multi-city-search>
    </div>
    <fieldset>
      <legend class="visuallyhidden">Return Flight</legend>
      <div
        class="two-thirds-container flex-row-container--end"
        *ngIf="searchParams.chosenSearchType === FlightSearchType.return">
        <div class="two-third">
          <div class="flex-row-container flight-input-mobile flex-row-container--end">
            <div class="flex-column-container combo-box-left">
              <label
                class="header-label split-label"
                id="returnFlightDep"
                i18n="@@flightSearchReturnFlightDep"
                >Return Flight Departure</label
              >
              <i class="ico-icon-landing combo-box-icon" aria-hidden="true"></i>
              <input
                #ofa
                autocomplete="off"
                name="flight2"
                i18n-placeholder
                placeholder="Depart from.."
                id="flight-input-return-flight-depart"
                type="text"
                class="input-box"
                [(ngModel)]="searchParams.arriveLocation"
                [ngbTypeahead]="flightLocations3"
                [resultTemplate]="railairtemp"
                [inputFormatter]="formatter_rail_air"
                (click)="$event.currentTarget.select()"
                (focus)="focus3$.next($event.target.value)"
                aria-labelledby="returnFlightDep"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
              <i
                *ngIf="arriveLocationIsLoading | async"
                class="button_spinner input-loading-spinner-right"></i>
            </div>
            <div class="flex-column-container combo-box-right">
              <label
                class="header-label split-label"
                id="returnFlightArr"
                i18n="@@flightSearchReturnFlightArr"
                >Return Flight Arrival</label
              >
              <i class="ico-icon-takeoff combo-box-icon" aria-hidden="true"></i>
              <input
                autocomplete="off"
                name="flight3"
                i18n-placeholder
                placeholder="Flying to.."
                type="text"
                id="flight-input-return-flight-arrive"
                class="input-box"
                [(ngModel)]="searchParams.departLocation"
                (selectItem)="checkHotelCheckbox()"
                [ngbTypeahead]="flightLocations4"
                [resultTemplate]="railairtemp"
                [inputFormatter]="formatter_rail_air"
                (click)="$event.currentTarget.select()"
                (focus)="focus4$.next($event.target.value)"
                aria-labelledby="returnFlightArr"
                (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
              <i
                *ngIf="departLocationIsLoading | async"
                class="button_spinner input-loading-spinner-left"></i>
            </div>
          </div>
        </div>
        <div class="one-third">
          <div class="flex-row-container">
            <ngbd-datepicker-i18n>
              <div class="flex-column-container combo-box-left">
                <label class="header-label date-label" i18n="@@flightSearchFlightDate" for="return_flight_date">Flight Date (DD/MM/YYYY)</label>
                <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                <app-datepicker
                  [minDate]="searchParams.departDate"
                  [startDate]="searchParams.returnDate"
                  [model]="searchParams.returnDate"
                  [class]="'combo-box'"
                  [componentId]="'return_flight_date'"
                  (newDate)="searchParams.returnDate = $event; checkHotelCheckbox()">
                </app-datepicker>
              </div>
              <div class="flex-column-container select-box-right">
                <label class="header-label" i18n="@@flightSearchFlightTime" for="return_flight_time"
                  >Flight Time</label
                >
                <i class="ico-clock select-box-icon select-box-icon"></i>
                <select
                  class="select-box"
                  id="return_flight_time"
                  [(ngModel)]="searchParams.arriveTime"
                  [disabled]="searchParams.inTimeCriteria === TimeWindow.Anytime"
                  [ngClass]="{
                    time_disabled: searchParams.inTimeCriteria === TimeWindow.Anytime,
                    'error-box': !searchService.isFavouriteSearch && searchParams.validationMessages?.includes(
                      'The time of arrival cannot be less than the time of departure'
                    )
                  }"
                  aria-describedby="returnFlight"
                  tabindex="0"
                  aria-labelledby="aria-flight-outbound-time_2">
                  <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                    {{ time.display }}
                  </option>
                </select>
              </div>
              <div
                class="time-flexibility-container"
                *ngIf="searchParams.inTimeCriteria == TimeWindow.Depart">
                <span id="aria-in-time-flexibility" hidden i18n="@@AriaInboundTimeControl"
                  >Ability to increase / decrease inbound flight time window in hours</span
                >
                <div class="flex-column-container">
                  <label
                    class="header-label"
                    i18n="@@flightSearchFlex"
                    for="return_flight_flexibility"
                    >Flexibility</label
                  >
                  <app-plus-minus-icon></app-plus-minus-icon>
                  <select
                    [ngClass]="{
                      time_disabled: searchParams.inTimeCriteria === TimeWindow.Anytime
                    }"
                    [disabled]="searchParams.inTimeCriteria === TimeWindow.Anytime"
                    name="in-time-flexibility"
                    i18n-placeholder
                    aria-describedby="aria-in-time-flexibility"
                    class="select-box"
                    [(ngModel)]="searchParams.inTimeFlexibility"
                    id="return_flight_flexibility">
                    <option *ngFor="let hour of flexibilityHours" [ngValue]="hour">
                      {{ hour }}
                    </option>
                  </select>
                </div>
              </div>
            </ngbd-datepicker-i18n>
          </div>
        </div>
      </div>
      <div
        class="flex-row-container flex-row-container--right flex-row-container--tb-margin flex-row-container--reset"
        *ngIf="searchParams.chosenSearchType === FlightSearchType.return">
        <mat-radio-group
          [(ngModel)]="searchParams.inTimeCriteria"
          aria-label="return flight time preferences"
          id="flight-return-flight-time-pref"
          class="triple-radio-buttons timecrit-buttons">
          <mat-radio-button
            class="light-primary"
            color="primary"
            [value]="flightcrit"
            *ngFor="let flightcrit of data.criteria"
            id="flight-radio-return-crit-{{ flightcrit ? (flightcrit | removeSpaces) : '' }}">
            <span>{{ flightcrit | translate }}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </fieldset>
    <div class="flex-row-container">
      <h2 i18n="@@flightSearchOptions" class="rem-1-5">Options</h2>
    </div>
    <div class="flex-row-container flex-column-mobile">
      <div class="option_checkbox">
        <div>
          <mat-checkbox
            class="light-primary flight-search-checkbox"
            color="primary"
            i18n="@@flightSearchDirectOnly"
            [checked]="searchParams.maxConnections === 0"
            #checked
            id="flight-checkbox-direct-only"
            (change)="direct(checked.checked)"
            (keydown.enter)="direct(checked)">
            Direct Only
          </mat-checkbox>
        </div>
      </div>
      <div
        class="option_checkbox"
        *ngIf="searchParams.chosenSearchType !== FlightSearchType.multiCity">
        <div>
          <mat-checkbox
            class="light-primary flight-search-checkbox"
            color="primary"
            i18n="@@flightSearchForAirports"
            id="flight-checkbox-nearby-airports"
            [(ngModel)]="searchParams.includeNearbyAirports"
            (keydown.enter)="
              searchParams.includeNearbyAirports = !searchParams.includeNearbyAirports
            ">
            Search for Nearby Airports
          </mat-checkbox>
        </div>
      </div>
      <div class="option_checkbox" *ngIf="showVia">
        <div>
          <mat-checkbox
            class="light-primary flight-search-checkbox"
            color="primary"
            [(ngModel)]="searchParams.showVia"
            id="flight-checkbox-via"
            i18n="@@flightSearchVia"
            (change)="viaChanged()">Via</mat-checkbox>
        </div>
      </div>
    </div>
    <div *ngIf="searchParams.showVia">
      <div class="two-third">
        <label class="header-label" id="aria-flight-via-destination" i18n="@@flightSearchVia"
          >Via</label
        >
        <div class="flex-row-container flight-input-mobile">
          <i class="ico-icon-takeoff combo-box-icon" aria-hidden="true"></i>
          <input
            autocomplete="off"
            name="flight1"
            i18n-placeholder
            placeholder="Depart from.."
            type="text"
            class="input-box combo-box-left"
            [(ngModel)]="searchParams.via"
            [ngbTypeahead]="flightLocationsVia"
            [resultTemplate]="railairtemp"
            [inputFormatter]="formatter_rail_air"
            (click)="$event.currentTarget.select()"
            (focus)="focusVia$.next($event.target.value)"
            aria-labelledby="aria-flight-via-destination"
            id="flight-input-via"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
          <i
            *ngIf="viaLocationIsLoading | async"
            class="button_spinner input-loading-spinner-left"></i>
        </div>
      </div>
    </div>
    <div *ngIf="searchParams.showPreferredAirlines">
      <div class="flex-row-container">
        <label class="header-label" id="preferredAirline" i18n="@@flightSearchChoosePreferred"
          >Choose Airlines</label
        >
      </div>
      <div class="quarter-container">
        <div class="quarter-block">
          <input
            autocomplete="off"
            name="flight_airlines"
            i18n-placeholder
            placeholder="Any"
            type="text"
            class="input-box"
            [(ngModel)]="searchParams.operator1"
            [ngbTypeahead]="firstAirline"
            [resultTemplate]="airlinesTemplate"
            [inputFormatter]="formatter_airlines"
            (click)="$event.currentTarget.select()"
            (focus)="focus5$.next($event.target.value)"
            aria-labelledby="preferredAirline1"
            id="flight-input-airline-1"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
          <span id="preferredAirline1" hidden i18n="@@flightSearchFirstPreferredAirline"
            >First airline</span
          >
        </div>
        <div class="quarter-block">
          <input
            autocomplete="off"
            name="secondAirline"
            i18n-placeholder
            placeholder="Any"
            type="text"
            class="input-box"
            [(ngModel)]="searchParams.operator2"
            [ngbTypeahead]="secondAirline"
            [resultTemplate]="airlinesTemplate"
            [inputFormatter]="formatter_airlines"
            (click)="$event.currentTarget.select()"
            (focus)="focus6$.next($event.target.value)"
            aria-labelledby="preferredAirline2"
            id="flight-input-airline-2"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
          <span id="preferredAirline2" hidden i18n="@@flightSearchSecondPreferredAirline"
            >Second airline</span
          >
        </div>
        <div class="quarter-block">
          <input
            autocomplete="off"
            name="thirdAirline"
            i18n-placeholder
            placeholder="Any"
            type="text"
            class="input-box"
            [(ngModel)]="searchParams.operator3"
            [ngbTypeahead]="thirdAirline"
            [resultTemplate]="airlinesTemplate"
            [inputFormatter]="formatter_airlines"
            (click)="$event.currentTarget.select()"
            (focus)="focus7$.next($event.target.value)"
            aria-labelledby="preferredAirline3"
            id="flight-input-airline-3"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
          <span id="preferredAirline3" hidden i18n="@@flightSearchThirdPreferredAirline"
            >Third airline</span
          >
        </div>
        <div class="quarter-block">
          <input
            autocomplete="off"
            name="fourthAirline"
            i18n-placeholder
            placeholder="Any"
            type="text"
            class="input-box"
            [(ngModel)]="searchParams.operator4"
            [ngbTypeahead]="fourthAirline"
            [resultTemplate]="airlinesTemplate"
            [inputFormatter]="formatter_airlines"
            (click)="$event.currentTarget.select()"
            (focus)="focus8$.next($event.target.value)"
            aria-labelledby="preferredAirline4"
            id="flight-input-airline-4"
            (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
            (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
          <span id="preferredAirline4" hidden i18n="@@flightSearchFourthPreferredAirline"
            >Fourth airline</span
          >
        </div>
      </div>
    </div>
    <div class="half-container" *ngIf="searchParams.showCabinClassSelection">
      <div class="half-block">
        <label
          class="header-label"
          id="PreferredClassO"
          i18n="@@flightSearchChoosePreferredOut"
          for="flight-input-class"
          >Choose Preferred Class Outbound</label
        >
        <select
          [(ngModel)]="searchParams.cabinClass"
          class="select-box select-box--no-icon"
          aria-labelledby="PreferredClassO"
          id="flight-input-class">
          <option *ngFor="let class of data.avaliableClasses" [value]="class.type">
            {{ class.name | translate }}
          </option>
        </select>
      </div>
      <div class="half-block">
        <div *ngIf="searchParams.chosenSearchType === FlightSearchType.return">
          <label
            class="header-label"
            id="PreferredClassI"
            i18n="@@flightSearchChoosePreferredIn"
            for="flight-input-return-class"
            >Choose Preferred Class Inbound</label
          >
          <select
            [(ngModel)]="searchParams.returnCabinClass"
            class="select-box select-box--no-icon"
            aria-labelledby="PreferredClassI"
            id="flight-input-return-class">
            <option *ngFor="let class of data.avaliableClasses" [value]="class.type">
              {{ class.name | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      class="flex-row-container hotel-check"
      *ngIf="
        searchParams.chosenSearchType === FlightSearchType.return &&
        displayHotelCheck &&
        toggleHotelSearchRequested.observed
      ">
      <button
        class="large-button"
        color="primary"
        [attr.aria-pressed]="hotelsearchRequested"
        id="flight-checkbox-hotel-{{hotelsearchRequested ? 'remove' : 'add'}}"
        (click)="toggleHotelCheckbox()">
        <span i18n="@@eurostarAddHotelcheck" *ngIf="!hotelsearchRequested"> Add Hotel </span>
        <span i18n="@@eurostarRemoveHotelcheck" *ngIf="hotelsearchRequested"> Remove Hotel </span>
      </button>
    </div>
    </ng-container>
    
    <ng-template #favoriteSearchContainer> 
      <app-favorite-items
        [serviceType]="ServiceType.Flight">
      </app-favorite-items>
    </ng-template>
  </div>
  <ng-container
    *ngIf="
      hotelsearchRequested &&
      toggleHotelSearchRequested.observed &&
      searchParams.chosenSearchType === FlightSearchType.return
    ">
    <h2 class="hotel-header">Hotel</h2>
    <app-hotel-search></app-hotel-search>
  </ng-container>
</ng-container>
<app-flight-loading-screen></app-flight-loading-screen>
