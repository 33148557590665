import {
  CabHireVehicleType,
  CarHireClass,
  CarHireTransmission,
  CarHireType,
  EnterpriseSearchService,
  FareType,
  FlightCabinClass,
  IrlJourneyType,
  RailClass,
  RailSearchJourneyType,
  ServiceType,
  TransportType,
  SeasonTicketType,
  SeasonTicketClassType,
  SeasonTicketLondonUnderground,
  SeasonTicketDeliveryType
} from '@sabstravtech/obtservices/angular';
import { Injectable } from '@angular/core';
import {
  CabHireTempParams,
  CarHireTempParams,
  EurostarSearchType,
  EurostarTempParams,
  FlightSearchType,
  FlightTempParams,
  HotelTempParams,
  IrlTempParams,
  LocationTypes,
  LoungesTempParams,
  ParkingTempParams,
  RailSearchCriteria,
  RailTempParams,
  SearchObjects,
  TimeWindow,
  FastTrackTempParams,
  SeasonTicketsTempParams
} from '@sabstravtech/obtservices/base';
import moment from 'moment';
import { LightningUserFavorurite } from '../classes/user-favourite.enum';

@Injectable()
export class LightningEnterpriseSearchService extends EnterpriseSearchService {
  public init_search_objects(): SearchObjects {
    return {
      [ServiceType.Flight]: {
        type: ServiceType.Flight,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/flights',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Rail]: {
        type: ServiceType.Rail,
        chosen: false,
        priority: 2,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/rail',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Eurostar]: {
        type: ServiceType.Eurostar,
        chosen: false,
        priority: 3,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/eurostar',
        searchUrl: '/search',
        autosearch: false
      },

      [ServiceType.Hotel]: {
        type: ServiceType.Hotel,
        chosen: false,
        priority: 4,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/hotels',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Car]: {
        type: ServiceType.Car,
        chosen: false,
        priority: 5,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/cars',
        searchUrl: '/search',
        autosearch: false
      },
      // airportexpress: {
      //     type: ServiceType.AirExpress,
      //     chosen: false,
      //     priority: 5,
      //     payload: null,
      //     booking_chosen: false,
      //     resultUrl: 'airportexpress', // airport express is just a different kind of rail search
      //     autosearch: false
      //   },
      [ServiceType.FastTrack]: {
        type: ServiceType.FastTrack,
        priority: 6,
        chosen: false,
        booking_chosen: false,
        resultUrl: '/results/fast-track',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Lounge]: {
        type: ServiceType.Lounge,
        chosen: false,
        priority: 7,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/lounges',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Parking]: {
        type: ServiceType.Parking,
        chosen: false,
        priority: 8,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/parkings',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.InternationalRail]: {
        type: ServiceType.InternationalRail,
        chosen: false,
        priority: 9,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/europeanrail',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Cab]: {
        type: ServiceType.Cab,
        chosen: false,
        priority: 10,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/taxis',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Coach]: {
        type: ServiceType.Coach,
        chosen: false,
        priority: 11,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/coach',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Ferry]: {
        type: ServiceType.Ferry,
        chosen: false,
        priority: 12,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/ferry',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.Apartment]: {
        type: ServiceType.Apartment,
        chosen: false,
        priority: 12,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/apartment',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.SeasonTicket]: {
        type: ServiceType.SeasonTicket,
        chosen: false,
        priority: 14,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/season-tickets',
        searchUrl: '/search',
        autosearch: false
      },
      [ServiceType.MeetingRoom]: {
        type: ServiceType.MeetingRoom,
        chosen: false,
        priority: 15,
        payload: null,
        booking_chosen: false,
        resultUrl: '/results/meeting-room',
        searchUrl: '/search',
        autosearch: false
      }
    };
  }
  /********************************************************
   * SEARCH OBJECTS FOR GET ME THERE DOOR 2 DOOR          *
   * Difference(s):                                       *
   * All objects have a priority of 1 so that they are    *
   * searched at the same time                            *
   * resultUrls changed to getmethere                           *
   *******************************************************/
  protected getMeThereSearchObjects(): SearchObjects {
    return {
      [ServiceType.Flight]: {
        type: ServiceType.Flight,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.Rail]: {
        type: ServiceType.Rail,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.Hotel]: {
        type: ServiceType.Hotel,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.Car]: {
        type: ServiceType.Car,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      /*airportexpress: {
          type: TravelTypeEnum.AIR_EXP,
          chosen: false,
          priority: 1,
          payload: null,
          booking_chosen: false,
          resultUrl: 'getmethere', // airport express is just a different kind of rail search
          autosearch: false
        },*/
      [ServiceType.Lounge]: {
        type: ServiceType.Lounge,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.Parking]: {
        type: ServiceType.Parking,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      /*eurostar: {
          type: TravelTypeEnum.EUROSTAR,
          chosen: false,
          priority: 1,
          payload: null,
          booking_chosen: false,
          resultUrl: 'getmethere',
          autosearch: false
        },*/
      [ServiceType.Cab]: {
        type: ServiceType.Cab,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.Coach]: {
        type: ServiceType.Coach,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      },
      [ServiceType.FastTrack]: {
        type: ServiceType.FastTrack,
        chosen: false,
        priority: 1,
        payload: null,
        booking_chosen: false,
        resultUrl: 'getmethere',
        searchUrl: 'getmethere',
        autosearch: false
      }
    };
  }

  public shouldFetchAllOffices(): boolean {
    return true;
  }
  public makeLoungesQuery(...args: any): LoungesTempParams {
    console.log('makeLoungesQuery in Lightning');
    const momentArrivalDateTime = moment().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const defaultVal = {
      adults: 0,
      children: 0,
      infants: 0,
      locationCode: null,
      arrivalDatetime: momentArrivalDateTime,
      terminal: null,
      countryCode: "GB"
    } as LoungesTempParams;

    return this.helpers.sanatizeReturns<LoungesTempParams>(defaultVal, Object.assign({}, ...args), [
      'arrivalDatetime'
    ]);
  }

  /*
   * Creates a Hotel search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeHotelQuery(data.query, { override: "property" })
   * @return {QuerySearchHotelsArgs}         Returns QuerySearchHotelsArgs type
   */
  public makeHotelQuery(...args: any): HotelTempParams {
    const defaultVal = {
      checkin_date: moment().set('hour', 23).set('minute', 59),
      checkout_date: moment().add(1, 'day').set('hour', 23).set('minute', 59),
      country: { cCode: 'GB', cName: 'United Kingdom' },
      currency: 'GBP',
      distance: 5, // default 5 miles radius
      hotel_availability: null,
      hotel_chain: null,
      hotel_name: null,
      location_type_select: LocationTypes.City,
      location: null, // might want to split this out to city, airport, office, railstation - this will do for now
      no_of_occupants: 1, // similarly think this is a max of 3
      no_of_rooms: 1, // max of 4 in old sabs, not sure here
      office: null,
      postcode: null,
      prefered_hotels_only: false,
      trainsStation: null
    } as HotelTempParams;

    return this.helpers.sanatizeReturns<HotelTempParams>(defaultVal, Object.assign({}, ...args), [
      'checkin_date',
      'checkout_date'
    ]);
  }

  /*
   * Creates a Eurostar search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeEurostarQuery(data.query, { override: "property" })
   * @return {EurostarTempParams}         Returns QuerySearchEurostarArgs type
   */
  public makeEurostarQuery(...args: any): EurostarTempParams {
    const now = this.helpers.makeMomentDayTime();
    const tomorrow = this.helpers.makeMomentDayTime(1);
    const momentOutDateTime = this.helpers.momentRoundMinutes(now, 30);
    const momentInDateTime = this.helpers.momentRoundMinutes(tomorrow, 30);

    const defaultVal: EurostarTempParams = {
      adults: 0,
      arriveLocation: null,
      cabinClass: FlightCabinClass.Any,
      children: 0,
      chosenSearchType: EurostarSearchType.return,
      departLocation: null,
      fareType: FareType.Flexible,
      inboundDate: momentOutDateTime,
      inTimeCriteria: TimeWindow.Anytime,
      maxConnections: null,
      includeNearbyAirports: false,
      multiDestination: [],
      outBoundDate: momentInDateTime,
      outTimeCriteria: TimeWindow.Anytime,
      preferredCarriers: [null, null, null, null],
      returnCabinClass: FlightCabinClass.Any
    };

    return this.helpers.sanatizeReturns<EurostarTempParams>(
      defaultVal,
      Object.assign({}, ...args),
      ['outBoundDate', 'inboundDate']
    );
  }

  /*
   * Creates a Parking search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeParkingQuery(data.query, { override: "property" })
   * @return {QuerySearchParkingArgs}         Returns QuerySearchParkingArgs type
   */
  public makeParkingQuery(...args: any): ParkingTempParams {
    const momentOutDateTime = moment().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    });
    const momentInDateTime = moment().set({
      hour: 15,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    const defaultVal = {
      arrivalDatetime: momentOutDateTime,
      departDatetime: momentInDateTime,
      locationCode: null,
      outFlightNumber: null,
      recommended: null,
      onAirport: null,
      meetAndGreet: null,
      parkAndRide: null,
      parkAndStroll: null,
      carParkedForYou: null,
      terminal: null
    } as ParkingTempParams;

    return this.helpers.sanatizeReturns<ParkingTempParams>(defaultVal, Object.assign({}, ...args), [
      'arrivalDatetime',
      'departDatetime'
    ]);
  }

  /* Create Query Defaults Start */
  /**
   * Create the Cab Hire search query with supplied param, auto fill with defaults if fields are empty.
   * @param  {any}           ...args example - searchService.setCabHireQuery(data.query, { departs: momentDateTime })
   * @return {QuerySearchCabHireArgs}         Returns QuerySearchCabHireArgs type
   */
  public makeCabHireQuery(...args: any): CabHireTempParams {
    let now = new Date();
    let hours;
    let minutes;
    if (now.getMinutes() >= 0 && now.getMinutes() <= 14) {
      hours = now.getHours();
      minutes = 30;
    } else if (now.getMinutes() >= 15 && now.getMinutes() <= 29) {
      hours = now.getHours();
      minutes = 45;
    } else if (now.getMinutes() >= 30 && now.getMinutes() <= 44) {
      now.setHours(now.getHours() + 1);
      hours = now.getHours();
      minutes = 0;
    } else if (now.getMinutes() >= 45 && now.getMinutes() <= 59) {
      now.setHours(now.getHours() + 1);
      hours = now.getHours();
      minutes = 15;
    } else {
      //If for whatever reason we can't set the hours
      hours = 12;
      minutes = 0;
    }
    const momentDepartsDateTime = moment().set({
      hour: hours,
      minute: minutes,
      second: 0,
      millisecond: 0
    });
    moment;
    const defaultVal: CabHireTempParams = {
      airport: null,
      adults: 0,
      children: 0,
      // ENT-9207 GB - should be moved to UIconfig later
      countryCode: 'GB',
      departs: momentDepartsDateTime,
      fromAddress: null,
      fromStation: null,
      infants: 0,
      toAddress: null,
      toStation: null,
      vehicleType: CabHireVehicleType.Any,
      limoType: null,
      viasFrom: null,
      viasTo: null,
      fromLocationType: null,
      toLocationType: null,
      limoAsDirectedDuration: 2,
      transportType: TransportType.OneWay,
      specialRequirements: ''
    };

    return this.helpers.sanatizeReturns<CabHireTempParams>(defaultVal, Object.assign({}, ...args), [
      'departs'
    ]);
  }

  public makeFlightQuery(...args: any): FlightTempParams {
    const momentOutDateTime = moment().set({
      hour: 9,
      minute: 30,
      second: 0,
      millisecond: 0
    });
    const momentInDateTime = moment()
      .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
      .add(1, 'day');

    const defaultVal: FlightTempParams = {
      adults: 0,
      arriveLocation: null,
      cabinClass: FlightCabinClass.Economy,
      children: 0,
      departLocation: null,
      fareType: FareType.Flexible,
      inboundDate: momentInDateTime,
      inTimeCriteria: TimeWindow.Depart,
      multiDestination: [
        {
          orig: null,
          dest: null,
          date: moment(momentOutDateTime),
          outTimeCriteria: TimeWindow.Depart,
          outTimeFlexibility: 2
        },
        {
          orig: null,
          dest: null,
          date: moment(momentOutDateTime),
          outTimeCriteria: TimeWindow.Depart,
          outTimeFlexibility: 2
        }
      ],
      outBoundDate: momentOutDateTime,
      outTimeCriteria: TimeWindow.Depart,
      preferredCarriers: [null, null, null, null],
      returnCabinClass: FlightCabinClass.Economy,
      maxConnections: null,
      outTimeFlexibility: 2,
      inTimeFlexibility: 2,
      includeNearbyAirports: false,
      bySchedule: false,
      chosenSearchType: FlightSearchType.return,
      via: null,
      showVia: false
    };

    return this.helpers.sanatizeReturns<FlightTempParams>(defaultVal, Object.assign({}, ...args), [
      'outBoundDate',
      'inboundDate'
    ]);
  }

  /*
   * Creates a Rail search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeRailQuery(data.query, { override: "property" })
   * @return {QuerySearchRailArgs}         Returns QuerySearchRailArgs type
   */
  public makeRailQuery(...args: any): RailTempParams {
    const outTime = this.helpers.momentRoundMinutes(moment().add(1, 'hour'), 15);
    const inTime = moment().set('hour', 16).set('minute', 0).set('second', 0);
    const defaultVal: RailTempParams = {
      airportExpressStation: null,
      applyRailcards: false,
      carnetTicket: false,
      chosenSearchClass: RailClass.All,
      chosenSearchType: RailSearchJourneyType.ReturnJourney,
      disableAutoDiscounts: false,
      extraTime: false,
      inBoundDateTime: inTime,
      isAirportExpress: false,
      noOfAdults: 0,
      noOfChildren: 0,
      outboundCriteria: RailSearchCriteria.Depart,
      outBoundDateTime: outTime,
      prioritiseByPrice: false,
      returnCriteria: RailSearchCriteria.Depart,
      travellingFrom: null,
      travellingTo: null,
      via: false,
      viaLocation: null,
      railCards: [],
      transactionIdentifier: null
    };

    return this.helpers.sanatizeReturns<RailTempParams>(defaultVal, Object.assign({}, ...args), [
      'inBoundDateTime',
      'outBoundDateTime'
    ]);
  }

  public isFlightsGrouped(): boolean {
    return false;
  }

  public isEuroStarGrouped(): boolean {
    return true;
  }

  public makeCarHireQuery(...args: any): CarHireTempParams {
    const defaultVal: CarHireTempParams = {
      adults: 0,
      airCon: null,
      carClass: '' as CarHireClass,
      carType: '' as CarHireType,
      children: 0,
      chosenVendor: '-',
      chosenDistance: '5',
      cityDropoff: null,
      cityDropoffDepot: null,
      cityPickup: null,
      cityPickupDepot: null,
      dropoff_country: 'GB',
      dropoff_datetime: moment({
        hours: 7,
        minutes: 30,
        second: 0,
        millisecond: 0
      }).add(1, 'day'),
      dropoff_location: null,
      estimatedMileage: 100,
      locationType: LocationTypes.Airport,
      pickup_country: 'GB',
      pickup_datetime: moment({
        hours: 7,
        minutes: 30,
        second: 0,
        millisecond: 0
      }),
      pickup_location: null,
      postcodeDropoff: null,
      postcodeDropoffRaw: null,
      postcodePickup: null,
      postcodePickupRaw: null,
      return_to_pickup: false,
      transmission: '' as CarHireTransmission,
      delivery: false,
      collection: false,
      driverAge: null
    };

    return this.helpers.sanatizeReturns<CarHireTempParams>(defaultVal, Object.assign({}, ...args), [
      'pickup_datetime',
      'dropoff_datetime'
    ]);
  }

  /*
   * Creates an Irl search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeIrlQuery(data.query, { override: "property" })
   * @return {IrlTempParams}         Returns IrlTempParams type
   */
  public makeIrlQuery(...args: any): IrlTempParams {
    const outTime = moment().set('hour', 7).set('minute', 0).set('second', 0);
    const inTime = moment().set('hour', 16).set('minute', 0).set('second', 0);
    const defaultVal: IrlTempParams = {
      supplier: null,
      countryCode: '',
      journeyType: IrlJourneyType.ReturnJourney,
      class: null,
      outboundDepartureDateTime: outTime,
      travellingFrom: null,
      travellingTo: null,
      adults: 0,
      children: 0,
      seniors: 0,
      youth: 0,
      returnDepartureDateTime: inTime,
      irlDiscountCards: null,
      offlineRequest: false
    };

    return this.helpers.sanatizeReturns<IrlTempParams>(defaultVal, Object.assign({}, ...args), [
      'returnDepartureDateTime',
      'outboundDepartureDateTime'
    ]);
  }

  /*
   * Creates a fast track search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeFastTrackQuery(data.query, { override: "property" })
   * @return {QuerySearchFastTrackArgs}         Returns QuerySearchFastTrackArgs type
   */
  public makeFastTrackQuery(...args: any): FastTrackTempParams {
    const momentOutDateTime = moment().set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    const defaultVal = {
      adults: 0,
      arrivalDatetime: momentOutDateTime,
      children: 0,
      locationCode: null
    } as FastTrackTempParams;

    return this.helpers.sanatizeReturns<FastTrackTempParams>(
      defaultVal,
      Object.assign({}, ...args),
      ['arrivalDatetime']
    );
  }
  shouldAddTravellerSubscription(): boolean {
    return true;
  }

  /*
   * Creates a SeasonTickets search query with supplied params, auto fills with defaults if fields are empty.
   * @param  {any}                    ...args Example - searchService.makeSeasonTicketsQuery(data.query, { override: "property" })
   * @return {QuerySearchSeasonTicketsArgs}         Returns QuerySearchSeasonTicketsArgs type
   */
  public makeSeasonTicketsQuery(...args: any): SeasonTicketsTempParams {
    // const time = this.helpers.momentRoundMinutes(moment().set('hours', 23).set('minutes', 59), 30);
    const defaultVal: SeasonTicketsTempParams = {
      fromLocation: null,
      toLocation: null,
      route: null,
      ticketType: SeasonTicketType.Weekly,
      classOfService: SeasonTicketClassType.Standard,
      railOperator: '',
      isLondonUndergroundRequired: null,
      deliveryType: SeasonTicketDeliveryType.FirstClass,
      deliveryAddressAndPostcode: null,
      photoCardNumber: null,
      additionalInformation: null,
      startDate: moment().set('hour', 23).set('minute', 59),
      onBehalfOfEmail: null
    };

    return this.helpers.sanatizeReturns<SeasonTicketsTempParams>(defaultVal, Object.assign({}, ...args), [
      'startDate'
    ]);
  }

}

