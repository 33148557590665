<ng-template #rt
             let-r="result"
             let-t="term">
  {{ r.name}}
</ng-template>
<ng-template #railairtemp
             let-r="result"
             let-t="term">
  <ng-container *ngIf="r.destination as destination">{{ destination }}</ng-container>
  <ng-container *ngIf="r.gateway as gateway"> ({{gateway}})</ng-container>
</ng-template>
<ng-template #cityRt
             let-r="result"
             let-t="term">
  <span> {{r.name}} ({{r.countryCode}})</span>
</ng-template>
<ng-template #cityRt1
             let-r="result"
             let-t="term">
  <span [innerHTML]="r.name +
  ' (' + r.countryCode + ')'"></span>
</ng-template>
<div id="carSearchForm"
     class='car_search_form'>
  <div>
    <label id="car-location-selection" class="header-label" i18n="@@CarLocationSelection">Location Selection</label>
    <div class="flex-row-container flex-column-mobile">
      <mat-radio-group [class]="showFavourites ? 'quad-radio-buttons' : 'triple-radio-buttons'"
                       aria-labelledby="car-location-selection"
                       (ngModelChange)="checkHotelCheckbox($event); searchService.isFavoritesSelected = $event === null"
                       [(ngModel)]="searchParams.locationType">
        <mat-radio-button class="light-primary"
                          color="primary"
                          id="car-location-type-{{item | removeSpaces}}"
                          [value]="item"
                          *ngFor="let item of searchParams.getLocationTypes() | async">{{item | translate}}
        </mat-radio-button>
         <!-- Favourites Option -->
        <mat-radio-button
          *ngIf="showFavourites"
          class="light-primary"
          id="hotel-location-select-favourites"
          color="primary"
          [value]="null">
          Favourites
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="!searchService.isFavoritesSelected;else favoriteSearchContainer">
      @if ((searchParams.locationType === LocationTypes.Postcode || searchParams.locationType === LocationTypes.City) && isEnterpriseCarHire) {
        <div class="flex-row-container">
          <label
            class="header-label"
            i18n="@@carDeliveryCollection">Delivery & Collection</label>
          <section (mouseleave)="ttDeliveryCollection.close()" class="container">
            <i class='ico ico-info-circle milage_info'
              i18n-ngbTooltip
              ngbTooltip='Please Note: Collection & Delivery is restricted to Enterprise Car Hire only. 
              Additional charges may apply for delivery and collection service.'
              triggers="manual"
              #ttDeliveryCollection="ngbTooltip"
              (mouseenter)="ttDeliveryCollection.open()"
              (focus)="ttDeliveryCollection.open()"
              (blur)="ttDeliveryCollection.close()"
              tabindex="0"
              aria-label="Please Note: Collection & Delivery is restricted to Enterprise Car Hire only. 
              Additional charges may apply for delivery and collection service."><span
              class="visuallyhidden">Please Note: Collection & Delivery is restricted to Enterprise Car Hire only. 
              Additional charges may apply for delivery and collection service.</span>
            </i>
          </section>
        </div>
        <div class="flex-row-container delivery-collection-container">
          <mat-checkbox
            class="light-primary"
            color="primary"
            [(ngModel)]="searchParams.delivery"
            (change)="toggleDeliveryCheckbox()"
            i18n="@@carIwantmyrentaldelivered"
            aria-label="I want my rental delivered"
            aria-checked="{{searchParams.delivery}}">I want my rental delivered</mat-checkbox>
          <mat-checkbox
            class="light-primary"
            color="primary"
            [(ngModel)]="searchParams.collection"
            (change)="toggleCollectionCheckbox()"
            i18n="@@carIwantmyrentalcollected"
            aria-label="I want my rental collected"
            aria-checked="{{searchParams.collection}}">I want my rental collected</mat-checkbox>
        </div> 
      }
      <div class='thirds-container'
          *ngIf="searchParams.locationType === LocationTypes.City || searchParams.locationType === LocationTypes.Postcode">
          <div class='thirds-block' *ngIf="searchParams.locationType === LocationTypes.Postcode">
            <label id="car-search-pickup-country"
                    class="header-label"
                    for="car-select-pickup-country"
                    i18n="@@carSearchCountryAirport">Country</label>
            <div class="flex-row-container">
              <i class='ico-marker select-box-icon'></i>
              <select [(ngModel)]="searchParams.pickup_country"
                      aria-labelledby="car-search-pickup-country"
                      class='select-box'
                      (ngModelChange)="updatePostcodes()"
                      id="car-select-pickup-country">
                <option disabled
                        value="null"
                        i18n="@@carSearchSelectCountry">Select country</option>
                <option [ngValue]="country.cCode"
                        *ngFor="let country of searchService.countries">{{country.cName}}
                </option>
              </select>
            </div>
        </div>
        <div class='thirds-block' *ngIf="!searchParams.delivery && !searchParams.collection">
          <label id="car-search-option-vendor"
                class="header-label"
                i18n="@@carSearchVendor"
                for="car-select-vendor">Vendor</label>
          <select class='select-box'
                  [(ngModel)]="searchParams.chosenVendor"
                  aria-labelledby="car-search-option-vendor"
                  (ngModelChange)="updateLocation()"
                  id="car-select-vendor">
            <option *ngFor='let vendor of searchParams.vendors'
                    [value]='vendor.code'>
              <!-- <img class='vendor_image_option'
                    src='https://images.sabscorp.com/images/carhire/{{vendor.code}}.gif'
                    alt="" /> -->
              {{vendor.name | translate}}
            </option>
          </select>
        </div>
        <div class='thirds-block'>
          <div class="half-container">
            <div class="half-block">
              <label id="car-search-within-distance"
                    class="header-label"
                    i18n="@@carSearchWithinDistance"
                    for="car-within-distance">Within Distance</label>
              <select class='select-box select-box--no-icon select-box--border'
                      [(ngModel)]="searchParams.chosenDistance"
                      id="car-within-distance"
                      aria-labelledby="car-search-within-distance"
                      (ngModelChange)="updateLocation()">
                <option *ngFor="let item of searchParams.withinDistance"
                        [value]="item.value">{{item.name}}</option>
              </select>
            </div>
            <div class="half-block" *ngIf="searchParams.delivery || searchParams.collection">
              <label class="header-label"
                    i18n="@@carSearchDriverAage"
                    for="driver-age">Driver age</label>
              <select class='select-box select-box--no-icon select-box--border'
                      [(ngModel)]="searchParams.driverAge"
                      id="driver-age">
                <option *ngFor="let age of searchParams.driverAges" [value]="age.value">
                  {{age.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
      
        <div class='thirds-block' *ngIf="searchParams.locationType === LocationTypes.City"></div>
      </div>
      <div class="thirds-container">
        <ng-container *ngIf='searchParams.locationType === LocationTypes.Airport'>
          <div class="thirds-block">
            <label id="car-search-pickup-country"
                  class="header-label"
                  for="car-select-pickup-country"
                  i18n="@@carSearchPickupCountryAirport">Pickup Country</label>
            <div class="flex-row-container">
              <i class='ico-marker select-box-icon'></i>
              <select [(ngModel)]="searchParams.pickup_country"
                      aria-labelledby="car-search-pickup-country"
                      class='select-box'
                      (change)='onOptionsSelected($event)'
                      id="car-select-pickup-country">
                <option disabled
                        value="null"
                        i18n="@@carSearchSelectCountry">Select country</option>
                <option [ngValue]="country.cCode"
                        *ngFor="let country of searchService.countries">{{country.cName}}
                </option>
              </select>
            </div>
          </div>
          <div class="thirds-block">
            <label id="car-search-pickup-location"
                  class="header-label"
                  for="car-pickup-location"
                  i18n="@@carSearchPickupLocationAirport">Pickup
              Location</label>
            <div class="flex-row-container">
              <i class='ico-marker combo-box-icon'></i>
              <input i18n-placeholder
                    placeholder="Enter pickup location"
                    name="pickup_location"
                    autocomplete="off"
                    type="text"
                    id="car-pickup-location"
                    class="input-box"
                    (selectItem)="selectedPickup('Airport', $event); checkHotelCheckbox()"
                    [(ngModel)]="searchParams.pickup_location"
                    aria-labelledby="car-search-pickup-location"
                    [ngbTypeahead]="pickupLocations"
                    [resultTemplate]="railairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (focus)="focus$.next($event.target.value);"
                    onClick='this.select()'
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                    [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please supply a pickup location")}'  />
              <!-- <i *ngIf='searchService.tempDestArray[7].loading'
                class='button_spinner input-loading-spinner-right'></i> -->
            </div>
          </div>
        </ng-container>
        <ng-template [ngIf]="searchParams.locationType === LocationTypes.City">
          <div class='thirds-block'>
            <label id="car-search-pickup-city"
                  class="header-label"
                  i18n="@@carSearchPickupCity"
                  for="car-input-pickup-city">Pickup City</label>
            <div class="flex-row-container">
              <i class='ico-marker combo-box-icon'></i>
              <input i18n-placeholder
                    placeholder="Enter pickup city"
                    name="pickup_location"
                    autocomplete="off"
                    type="text"
                    id="car-input-pickup-city"
                    class="input-box"
                    [(ngModel)]="searchParams.cityPickup"
                    (ngModelChange)="updateCityPickupDepot($event)"
                    (selectItem)="selectedPickup('City', $event)"
                    [formControl]='pickupCityControl'
                    [ngbTypeahead]="pickupCities"
                    [resultTemplate]="cityRt"
                    [inputFormatter]="cityFormatter"
                    aria-labelledby="car-search-pickup-country"
                    (focus)="pickupFocus$.next($event.target.value);"
                    onClick='this.select()'
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            </div>
          </div>
          <div class='thirds-block'>
            <label id="car-search-pickup-city-location"
                  class="header-label"
                  i18n="@@carSearchPickupLocation"
                  for="car-city-location">Location</label>
            <div class="flex-row-container">
              <select class="select-box"
                      [compareWith]="compareDepot"
                      (ngModelChange)="selectedPickupDepot('City', $event)"
                      [(ngModel)]="searchParams.cityPickupDepot"
                      aria-labelledby="car-search-pickup-city-location"
                      [disabled]="!cityLocations.hasPickupResults"
                      id="car-city-location">
                <option value="null"
                        disabled
                        i18n="@@carSearchSelectLocation">
                  <span *ngIf="cityLocations.filteredPickupDepots.length===0;else selectLocation">
                    No location found
                  </span>
                  <ng-template #selectLocation>
                    Select location
                  </ng-template>
                </option>
                <option *ngFor='let depot of cityLocations.filteredPickupDepots'
                        [ngValue]="depot">
                  <!-- <img class='vendor_image_option'
                      src='https://images.sabscorp.com/images/carhire/{{depot.vendorCode}}.gif'
                      alt="" /> -->
                  {{depot?.vendor}} - {{depot?.cityName}} - {{depot?.addressLines[0]}}
                </option>
              </select>
              <i *ngIf='cityLocations.pickupLoading'
                class='button_spinner input-loading-spinner-right'></i>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]='searchParams.locationType === LocationTypes.Postcode'>
          <div class='thirds-block'>
            <label id="car-search-pickup-postcode"
                  class="header-label"
                  i18n="@@carSearchPostcode"
                  for="car-select-postcode">Postcode</label>
            <div class="flex-row-container">
              <i class='ico-marker combo-box-icon'></i>
              <input name="pickup_location"
                    autocomplete="off"
                    i18n-placeholder
                    placeholder="Enter valid postcode"
                    type="text"
                    id="car-select-postcode"
                    class="input-box"
                    [(ngModel)]="searchParams.postcodePickupRaw"
                    aria-labelledby="car-search-pickup-postcode"
                    (ngModelChange)="changePickupPostcode($event)"
                    (selectItem)="selectedPickup('Postcode', $event)"
                    [formControl]='pickupPostcodeControl'
                    [ngbTypeahead]="pickupPostcodes"
                    [resultTemplate]="rt"
                    [inputFormatter]="postcodeFormatter" />
            </div>
          </div>
          <div class='thirds-block'>
            <label id="car-search-pickup-postcode-location"
                  class="header-label"
                  i18n="@@carSearchPostcodeLocation"
                  for="car-select-location">Location</label>
            <div class="flex-row-container">
              <select class='select-box'
                      [compareWith]="compareDepot"
                      (ngModelChange)="selectedPickupDepot('Postcode', $event)"
                      [(ngModel)]="searchParams.postcodePickup"
                      aria-labelledby="car-search-pickup-postcode-location"
                      [disabled]='!postcodeLocations.hasPickupResults'
                      id="car-select-location">
                <option value="null"
                        disabled
                        i18n="@@carSearchSelectLocationPostcode">
                  <span *ngIf="postcodeLocations.filteredPickupDepots.length===0;else selectLocation">
                    No location found
                  </span>
                  <ng-template #selectLocation>
                    Select location
                  </ng-template>
                </option>
                <option *ngFor='let depot of postcodeLocations.filteredPickupDepots'
                        [ngValue]='depot'>
                  <!-- <img class='vendor_image_option'
                      src='https://images.sabscorp.com/images/carhire/{{depot.vendorCode}}.gif'
                      alt="" /> -->
                  {{depot?.vendor}} - {{depot?.cityName}} - {{depot?.addressLines[0]}}
                </option>
              </select>
              <i *ngIf='postcodeLocations.pickupLoading'
                class='button_spinner input-loading-spinner-right'></i>
            </div>
          </div>
        </ng-template>
        <div class="thirds-block">
          <div class="flex-row-container"
          [ngClass]='{"error-box": !searchService.isFavouriteSearch && searchParams.validationMessages?.includes("Pickup date/time must be after present date/time")}' >
            <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <label class="header-label date-label"
                for="car-outbound-date"
                i18n="@@carSearchPickupDate">Pickup Date (DD/MM/YYYY)</label>
              <i class='ico-calendar combo-box-icon'
              ></i>

              <app-datepicker
                [minDate]='searchParams.minDate'
                [startDate]='searchParams.ngbPickupDate'
                [model]="searchParams.ngbPickupDate"
                [class]="'combo-box'"
                [componentId]="'car-outbound-date'"
                (newDate)="searchParams.ngbPickupDate = $event; checkHotelCheckbox();"
              >
              </app-datepicker>
            </div>
            <div class="flex-column-container select-box-right">
              <label class="header-label"
                for="car-select-pickup-time"
                i18n="@@carSearchPickupTime">Pickup Time</label>
              <i class='ico-clock combo-box-icon'></i>
              <select id="car-select-pickup-time"
                      class="select-box"
                      [(ngModel)]="searchParams.pickupTime"
                      aria-label="Pickup time">
                <option *ngFor="let time of filterTimeArray(searchParams.timeArray)"
                        [ngValue]="time.value">{{time.display}}</option>
              </select>
            </div>
          </ngbd-datepicker-i18n>
          </div>
        </div>
      </div>
  
      <!-- Dropoff  -->
      <div class="thirds-container">
        <ng-template [ngIf]="searchParams.locationType === LocationTypes.City">
          <div class='thirds-block'>
            <label id="car-search-dropoff-city"
                  class="header-label"
                  i18n="@@carSearchDropoffCity"
                  for="car-dropoff-city-input">Dropoff City</label>
            <div class="flex-row-container">
              <i class='ico-marker select-box-icon'></i>
              <!-- [disabled]="searchService.car.use_pickup_for_dropoff" -->
              <input i18n-placeholder
                    placeholder="Enter dropoff city"
                    name="dropoff_location"
                    autocomplete="off"
                    type="text"
                    id="car-search-dropoff-city"
                    class="input-box"
                    [(ngModel)]="searchParams.cityDropoff"
                    (ngModelChange)="updateCityDropoffDepot($event);"
                    [formControl]='dropOffCityControl'
                    [ngbTypeahead]="dropOffCities"
                    [resultTemplate]="cityRt"
                    [inputFormatter]="cityFormatter"
                    aria-labelledby="car-search-dropoff-city"
                    (focus)="dropoffFocus$.next($event.target.value);"
                    onClick='this.select()'
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            </div>
          </div>
          <div class='thirds-block'>
            <label class="header-label"
                  i18n="@@carSearchLocation"
                  for="car-dropoff-location-select">Location</label>
            <div class="flex-row-container">
              <!-- (ngModelChange)='cityDropoffAltered($event)' -->
              <select class='select-box'
                      [compareWith]="compareDepot"
                      [(ngModel)]="searchParams.cityDropoffDepot"
                      [disabled]='!cityLocations.hasDropoffResults'
                      aria-labelledby="car-search-dropoff-city-location"
                      id="car-dropoff-location-select">
                <option value="null"
                        disabled
                        i18n="@@carSearchSelectLocationCity">
                  <span *ngIf="cityLocations.filteredDropoffDepots.length===0;else selectLocation">
                    No location found
                  </span>
                  <ng-template #selectLocation>
                    Select location
                  </ng-template>
                </option>
                <option *ngFor='let depot of cityLocations.filteredDropoffDepots'
                        [ngValue]="depot">
                  <!-- <img class='vendor_image_option'
                      src='https://images.sabscorp.com/images/carhire/{{depot.vendorCode}}.gif'
                      alt="" /> -->
                  {{depot?.vendor}} - {{depot?.cityName}} - {{depot?.addressLines[0]}}
                </option>
              </select>
              <span id="car-search-dropoff-city-location"
                    hidden
                    i18n="@@AriaCarSearchCitySropoffLocation">Select Dropoff Location</span>
              <i *ngIf='cityLocations.dropoffLoading'
                class='button_spinner input-loading-spinner-right'></i>
            </div>
          </div>
        </ng-template>
        <ng-template [ngIf]="searchParams.locationType === LocationTypes.Postcode">
          <div class='thirds-block'>
            <label id="car-search-dropoff-postcode"
                  class="header-label"
                  i18n="@@carSearchDropoffPostcode"
                  for="car-search-dropoff-postcode-input">Dropoff Postcode</label>
            <div class="flex-row-container">
              <i class='ico-marker select-box-icon'></i>
              <input placeholder="Enter valid postcode"
                    name="dropoff_location"
                    autocomplete="off"
                    type="text"
                    id="car-search-dropoff-postcode-input"
                    class="input-box"
                    [value]="postCode"
                    [(ngModel)]="searchParams.postcodeDropoffRaw"
                    [formControl]='dropOffPostcodeControl'
                    aria-labelledby="car-search-dropoff-postcode"
                    (ngModelChange)="changeDropoffPostcode($event); checkHotelCheckbox()"
                    [formControl]='pickupPostcodeControl'
                    [ngbTypeahead]="pickupPostcodes"
                    [resultTemplate]="rt"
                    [inputFormatter]="postcodeFormatter" />
            </div>
          </div>
          <div class='thirds-block'>
            <label class="header-label"
                  i18n="@@carSearchLocationPostcode"
                  for="carsearchlocation-input">Location</label>
            <div class="flex-row-container">
              <!-- searchService.car.use_pickup_for_dropoff ||  (ngModelChange)='postcodeDropoffAltered()'-->
              <select class='select-box'
                      [compareWith]="compareDepot"
                      (ngModelChange)="checkHotelCheckbox();"

                      [(ngModel)]="searchParams.postcodeDropoff"
                      [disabled]=' !postcodeLocations.hasDropoffResults'
                      aria-labelledby="car-search-dropoff-postcode-location"
                      id="carsearchlocation-input">
                <option disabled
                        value="null"
                        i18n="@@carSearchSelectLocationPostcode2">
                  <span *ngIf="postcodeLocations.filteredDropoffDepots.length===0;else selectLocation">
                    No location found
                  </span>
                  <ng-template #selectLocation>
                    Select location
                  </ng-template>
                </option>
                <option *ngFor='let depot of postcodeLocations.filteredDropoffDepots'
                        [ngValue]='depot'>
                  <!-- <img class='vendor_image_option'
                      src='https://images.sabscorp.com/images/carhire/{{depot.vendorCode}}.gif'
                      alt="" /> -->
                  {{depot?.vendor}} - {{depot?.cityName}} - {{depot?.addressLines[0]}}
                </option>
              </select>
              <i *ngIf='postcodeLocations.dropoffLoading'
                class='button_spinner input-loading-spinner-right'></i>
              <span id="car-search-dropoff-postcode-location"
                    hidden
                    i18n="@@AriaCarSearchDropoffPostcodeLocation">Dropoff Location</span>
            </div>
          </div>
        </ng-template>
        <ng-container *ngIf='searchParams.locationType === LocationTypes.Airport'>
          <div class="thirds-block">
            <label id="car-search-dropoff-airport-country"
                  class="header-label"
                  for="car-select-dropoff-country"
                  i18n="@@carSearchDropoffCountry">Dropoff Country</label>
            <div class="flex-row-container">
              <i class='ico-marker combo-box-icon'></i>
              <select [(ngModel)]="searchParams.dropoff_country"
                      class='select-box'
                      id="car-select-dropoff-country"
                      aria-labelledby="car-search-dropoff-airport-country">
                <option disabled
                        value="null"
                        i18n="@@carSearchSelectCountry">Select country</option>
                <option [ngValue]="country.cCode"
                        *ngFor="let country of searchService.countries">{{country.cName}}
                </option>
              </select>
            </div>
          </div>
          <div class="thirds-block">
            <label id="car-search-dropoff-airport-location"
                  class="header-label"
                  for="car-input-dropoff-location"
                  i18n="@@carSearchDropoffLocation">Dropoff Location</label>
            <div class="flex-row-container">
              <i class='ico-marker combo-box-icon'></i>
              <input i18n-placeholder
                    placeholder="Enter dropoff location"
                    name="dropoff_location-input"
                    autocomplete="off"
                    type="text"
                    id="car-input-dropoff-location"
                    class="input-box"
                    [(ngModel)]="searchParams.dropoff_location"
                    [ngbTypeahead]="dropoffLocations"
                    [resultTemplate]="railairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (ngModelChange)="checkHotelCheckbox();"
                    (focus)="focus2$.next($event.target.value);"
                    onClick='this.select()'
                    (selectItem)="checkHotelCheckbox()"
                    aria-labelledby="car-search-dropoff-airport-location"
                    (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
                    (keyup.arrowup)="ensureElementIsScrolledTo($event)"
                    [ngClass]='{"error-box": searchParams.validationMessages?.includes("Please supply a dropoff location")}'  />
              <!-- <i *ngIf='searchService.tempDestArray[8].loading'
                class='button_spinner input-loading-spinner-right'></i> -->
            </div>
          </div>
        </ng-container>
        <div class="thirds-block">
          <div class="flex-row-container">
            <ngbd-datepicker-i18n>
            <div class="flex-column-container combo-box-left">
              <i class='ico-calendar combo-box-icon'
              ></i>
              <label for="car-inbound-date"
                class="header-label date-label"
                i18n="@@carSearchDropoffTime">Dropoff Date (DD/MM/YYYY)</label>
              <app-datepicker
                [minDate]='searchParams.minInDate'
                [startDate]='searchParams.ngbDropoffDate'
                [model]="searchParams.ngbDropoffDate"
                [class]="'combo-box'"
                [componentId]="'car-inbound-date'"
                (newDate)="searchParams.ngbDropoffDate = $event; checkHotelCheckbox();"
              >
              </app-datepicker>
            </div>
            <div class="flex-column-container select-box-right">
              <i class="ico-clock combo-box-icon" aria-hidden="true"></i>
              <label id="car-search-dropoff-date"
                    i18n="@@carSearchDropoffDate"
                    class="header-label"
                    for="car-select-dropoff-time">Dropoff Time</label>
              <select id="car-select-dropoff-time"
                      class="select-box"
                      [(ngModel)]="searchParams.dropoffTime">
                <option *ngFor="let time of filterTimeArray(searchParams.timeArray)"
                        [ngValue]="time.value">{{time.display}}</option>
              </select>
            </div>
            </ngbd-datepicker-i18n>
          </div>
        </div>
      </div>
      <div class="flex-column-container"
          *ngIf="!hideCarHireOptions()">
        <label class="header-label"
              i18n="@@carSearchOptions">Options</label>

        <div class='fifth-container'>
          <div class='fifth-block'
              *ngIf="searchParams.locationType === LocationTypes.Airport">
            <label id="car-search-option-vendor"
                  class="header-label"
                  i18n="@@carSearchVendor"
                  for="car-select-vendor">Vendor</label>
            <select class='select-box select-box--no-icon'
                    [(ngModel)]="searchParams.chosenVendor"
                    aria-labelledby="car-search-option-vendor"
                    (ngModelChange)="updateLocation()"
                    id="car-select-vendor">
              <option *ngFor='let vendor of searchParams.vendors'
                      [value]='vendor.code'>
                <!-- <img class='vendor_image_option'
                      src='https://images.sabscorp.com/images/carhire/{{vendor.code}}.gif'
                      alt="" /> -->
                {{vendor.name | translate}}
              </option>
            </select>
          </div>
          <div class='fifth-block'>
            <label id="car-search-option-car-class"
                  class="header-label"
                  i18n="@@carSearchCarClass"
                  for="car-class-select">Car Class</label>
            <select class='select-box select-box--no-icon'
                    id="car-class-select"
                    [(ngModel)]="searchParams.carClass"
                    aria-labelledby="car-search-option-car-class">
              <option value=''
                      i18n="@@CarHireCarClassAny">Any</option>
              <option *ngFor="let item of searchParams.getCarClasses() | async"
                      [value]="item.key">
                {{item.name | translate}}
              </option>
            </select>
          </div>
          <div class='fifth-block'>
            <label id="car-search-option-car-type"
                  class="header-label"
                  i18n="@@carSearchCarType"
                  for="car-type-select">Car Type</label>
            <select class='select-box select-box--no-icon'
                    [(ngModel)]="searchParams.carType"
                    aria-labelledby="car-search-option-car-type"
                    id="car-type-select">
              <option value=''
                      i18n="@@CarHireCarTypeAny"
                      selected="selected">Any</option>
              <option *ngFor="let item of searchParams.carTypeCodes"
                      [value]='item[2]'>
                {{item[1] | translate}}
              </option>
            </select>
          </div>
          <div class='fifth-block'>
            <label id="car-search-option-transmission"
                  class="header-label"
                  i18n="@@carSearchTransmission"
                  for="car-transmission-select">Transmission</label>
            <select class='select-box select-box--no-icon'
                    [(ngModel)]="searchParams.transmission"
                    (ngModelChange)="updateAircon($event)"
                    aria-labelledby="car-search-option-transmission"
                    id="car-transmission-select">
              <option value=''
                      i18n="@@CarHireCarTransmission"
                      selected="selected">Any</option>
              <option *ngFor="let item of searchParams.carTransmissionCodes"
                      [value]='removeExtra(item[2])'>
                {{item[1] | translate}}
              </option>
            </select>
          </div>
          <div class='fifth-block'>
            <label id="car-search-option-aircon"
                  class="header-label"
                  i18n="@@carSearchAirConditioning"
                  for="car-conditioning-select">Air Conditioning</label>
            <select class='select-box select-box--no-icon'
                    [(ngModel)]="searchParams.airCon"
                    aria-labelledby="car-search-option-aircon"
                    id="car-conditioning-select">
              <option *ngFor="let item of searchParams.carAirConditionTypes"
                      [disabled]="item[2] === null && searchParams.transmission"
                      [ngValue]="item[2]">
                {{item[1] | translate}}
              </option>
            </select>
          </div>
          <div class='fifth-block'>
            <div class="flex-row-container">
              <label id="car-search-option-Milage"
                    class="header-label"
                    i18n="@@carSearchMilage"
                    for="carsearch_select_milage">Estimated Mileage</label>
              <section (mouseleave)="tt.close()"
                      class="container"><i class='ico ico-info-circle milage_info'
                  i18n-ngbTooltip
                  ngbTooltip='To calculate your carbon consumption and/or allowance, please enter the estimated number of miles you plan to do during your trip. If you leave this field blank, the system will automatically default to 100 estimated miles per day.'
                  triggers="manual"
                  #tt="ngbTooltip"
                  (mouseenter)="tt.open()"
                  (focus)="tt.open()"
                  (blur)="tt.close()"
                  tabindex="0"
                  aria-label="To calculate your carbon consumption and/or allowance, please enter the estimated number of miles you plan to do during your trip. If you leave this field blank, the system will automatically default to 100 estimated miles per day"><span
                        class="visuallyhidden">To calculate your carbon consumption and/or allowance, please enter the
                    estimated number of miles you plan to do during your trip. If you leave this field blank, the system
                    will automatically default to 100 estimated miles per day</span></i></section>
            </div>

            <input type="number"
                  class='input-box input-box--no-icon'
                  [(ngModel)]="searchParams.estimatedMileage"
                  min="0"
                  step="1"
                  aria-labelledby="car-search-option-mileage"
                  id="car-input-select-mileage"
                  placeholder="Enter est. mileage"
                  i18n-placeholder>
          </div>
        </div>
      </div>
      <div class="flex-row-container hotel-check" *ngIf="displayHotelCheck && toggleHotelSearchRequested.observed">
        <button
            class="large-button"
            color="primary"
            [attr.aria-pressed]="hotelsearchRequested"
            id="car-checkbox-hotel-{{hotelsearchRequested ? 'remove' : 'add'}}"
            (click)="toggleHotelCheckbox()">
            <span i18n="@@carAddHotelcheck" *ngIf="!hotelsearchRequested">
                Add Hotel
            </span>
            <span i18n="@@carRemoveHotelcheck" *ngIf="hotelsearchRequested">
                Remove Hotel
            </span>
        </button>
      </div>
    </ng-container>
  </div>
  <ng-template #favoriteSearchContainer> 
    <app-favorite-items
      [serviceType]="ServiceType.Car">
    </app-favorite-items>
  </ng-template>
 
  <ng-container *ngIf="hotelsearchRequested && toggleHotelSearchRequested.observed">
    <h2 class="hotel-header">Hotel</h2>
    <app-hotel-search></app-hotel-search>
  </ng-container>
</div>
<app-car-loading-screen></app-car-loading-screen>
