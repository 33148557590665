import { Component, HostListener, Output, OnInit, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, of, Subscription, BehaviorSubject } from 'rxjs';

import { FlightPipe } from '../../../startup/pipes/flight-pipe';
import { Title } from '@angular/platform-browser';
import moment from 'moment';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { TranslateService } from '@ngx-translate/core';
import { OBTAirportDetails, OBTAirlinesDetails, FlightEnterpriseSearchInterface, FlightSearchType, TimeWindow, IMultiDestination, LocationTypes } from '@sabstravtech/obtservices/base';
import { EnterpriseSearchService, UserService, ServiceType, HelperRoutines } from '@sabstravtech/obtservices/angular';
import { debounceTime, distinctUntilChanged, switchMap, merge } from 'rxjs/operators';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Helpers } from '../../../vendor/classes/helpers';
import { MenuSlideEnum } from '@sabstravtech/obtservices/base';
import _ from 'lodash';
@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss'],
  animations: [
    trigger('slideUpAndDown', [
      state(MenuSlideEnum.IN, style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(100%)' }), //
        animate(200)
      ]),
      transition('* => void', [animate(200, style({ transform: 'translateY(50%)' }))])
    ])
  ]
})
export class FlightSearchComponent extends BaseComponent implements OnInit {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;

  focusVia$ = new Subject<string>();
  focus$ = new Subject<string>();
  focus2$ = new Subject<string>();
  focus3$ = new Subject<string>();
  focus4$ = new Subject<string>();
  focus5$ = new Subject<string>();
  focus6$ = new Subject<string>();
  focus7$ = new Subject<string>();
  focus8$ = new Subject<string>();

  testArrayVia: Observable<OBTAirportDetails[]> = of([]);
  testArray: Observable<OBTAirportDetails[]> = of([]);
  testArray2: Observable<OBTAirportDetails[]> = of([]);
  testArray4: Observable<OBTAirportDetails[]> = of([]);
  testArray3: Observable<OBTAirportDetails[]> = of([]);
  firstAirlineArray: Observable<OBTAirlinesDetails[]> = of([]);
  secondAirlineArray: Observable<OBTAirlinesDetails[]> = of([]);
  thirdAirlineArray: Observable<OBTAirlinesDetails[]> = of([]);
  fourthAirlineArray: Observable<OBTAirlinesDetails[]> = of([]);
  testflightVias: Observable<OBTAirportDetails[]> = of([]);
  departLocationIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  arriveLocationIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  viaLocationIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  step = 30;
  showNearby: boolean;
  showVia: boolean = true;
  showAnytime: boolean = false;
  criteria: string[];
  userProfileSubscription: Subscription;
  searchParams: FlightEnterpriseSearchInterface;
  TimeWindow: typeof TimeWindow = TimeWindow;
  FlightSearchType: typeof FlightSearchType = FlightSearchType;
  multiCitySearch: IMultiDestination[] = [];
  minimumMultiCityDate: NgbDateStruct;
  showMultiCity = false;
  showFavourites = false;
  flexibilityHours: number[] = [];
  hotelsearchRequested: boolean = false;
  displayHotelCheck: boolean = false;
  @Output() toggleHotelSearchRequested: EventEmitter<boolean> = new EventEmitter();
  ServiceType = ServiceType;
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public deviceDetector: DeviceDetector,
    protected flightPipe: FlightPipe,
    title: Title,
    public translateService: TranslateService,
    private helpers: HelperRoutines,
  ) {
    super(title, translateService);
    for (let i = 1; i <= 7; i++) {
      this.flexibilityHours.push(i);
    }
  }

  ngOnInit(): void {

    this.getDefaultJourneyTimes();

    this.searchParams = this.searchService.searches[ServiceType.Flight];
    if (this.searchParams.directDefaultValue) this.searchParams.maxConnections = 0;
    if (this.searchParams.via) {
      this.searchParams.maxConnections = 1;
    }

    this.showVia = !this.userService.isUserFavoriteSet(LightningUserFavorurite.HideFlightVia);


    // let defPeriod = this.userService.getUserFavoriteValue(LightningUserFavorurite.DefFlightTimePeriod) || 'Anytime';

    this.showAnytime = this.userService.isUserFavoriteSet(LightningUserFavorurite.ShowFlightAnytimePeriod);
    // this.criteria = this.searchService.flight.criteria.filter((item: string) => {
    //   return this.showAnytime ? true : item !== 'Anytime';
    // });

    // if (defPeriod === 'Anytime' && !this.showAnytime) {
    //   defPeriod = this.criteria[0];
    // }
    // this.searchService.flight.flights[0].timeCriteria = defPeriod;
    // this.searchService.flight.flights[1].timeCriteria = defPeriod;

    this.showMultiCity = (this.userService.getUserFavoriteObject(LightningUserFavorurite.FlightSearchDefaults) as { enableMultiCityForFlight: boolean; }
    )?.enableMultiCityForFlight;
    if (this.searchParams.chosenSearchType === FlightSearchType.multiCity) {
      this.setUpMultiCitySearch();
    }
    this.showFavouritesOption();
  }

  showFavouritesOption(){
    this.searchService.isFavoritesSelected = false;
    this.showFavourites = (this.userService.getUserFavoriteObject(LightningUserFavorurite.FlightSearchDefaults) as { enableFavoritesOptionForFlights: boolean; }
    )?.enableFavoritesOptionForFlights;
  }

  getDefaultJourneyTimes(): void {
    // this.searchService.flight.flights[0].time = '09:00';
    // this.searchService.flight.flights[1].time = '18:00';
    const outboundFave = this.userService.getUserFavoriteValue(LightningUserFavorurite.DefaultTimeOutbound);
    const inboundFave = this.userService.getUserFavoriteValue(LightningUserFavorurite.DefaultTimeInbound);
    if (outboundFave) {
      if (this.isValidTime(outboundFave)) {
        // this.searchService.flight.flights[0].time = this.roundToNearest30(outboundFave);
      }
    }
    if (inboundFave) {
      if (this.isValidTime(inboundFave)) {
        // this.searchService.flight.flights[1].time = this.roundToNearest30(inboundFave);
      }
    }
  }

  isValidTime(favouriteTime: string): boolean {
    return !!favouriteTime.match(/^(\d{2}):(\d{2})$/);
  }

  roundToNearest30(time: string) {
    const m = moment(`2018-12-08 ${time}`);
    return m.minute() >= 30 ? m.minutes(30).format('HH:mm') : m.minutes(0).format('HH:mm');
  }

  @HostListener('document:click', ['$event'])
  public onClick(event): void {
    Helpers.closeOpenCalendars(this.datepickerList, event);
  }

  // selectTakeOffLocation = (location: string, flightId: number): void =>
  //   this.searchService.flight.setFlightLocation(location, flightId, 'take_off_location');

  formatter_rail_air = (x: { destination: string; }) => this.flightPipe.transform(x.destination);
  formatter_airlines = (x: { name: string; }) => x.name;

  /**
  @desc - add another flight - switches the next flight in the array to true
  **/
  addFlight(): void {
    // search through the flight object and show the next flight to be shown in any - max 5
    // for (let i = 0; i < this.searchService.flight.flights.length; i++) {
    //   if (this.searchService.flight.flights[i].chosen === false) {
    //     this.searchService.flight.flights[i].chosen = true;
    //     return;
    //   }
    // }
  }

  /**
  @desc - remove a specified flight
  **/
  removeFlight(num: number): void {
    // this.searchService.flight.flights[num].chosen = false;
  }

  test(): void {
    // console.log(this.searchService.flight.flights);
  }

  flightLocationsVia = (text$: Observable<string>): Observable<OBTAirportDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusVia$),
      switchMap((term: string): Observable<OBTAirportDetails[]> => {
        return term.length < 2
          ? this.testArrayVia
          : (this.testArrayVia = this.searchService.getAirportsList(term, null, this.viaLocationIsLoading));
      })
    );

  flightLocations = (text$: Observable<string>): Observable<OBTAirportDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus$),
      switchMap(
        (term: string): Observable<OBTAirportDetails[]> =>
          term.length < 2 ? this.testArray : (this.testArray = this.searchService.getAirportsList(term, null, this.departLocationIsLoading))
      )
    );

  flightLocations2 = (text$: Observable<string>): Observable<OBTAirportDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus2$),
      switchMap(
        (term: string): Observable<OBTAirportDetails[]> =>
          term.length <= 2
            ? this.testArray2
            : (this.testArray2 = this.searchService.getAirportsList(term, null, this.arriveLocationIsLoading))
      )
    );

  flightLocations3 = (text$: Observable<string>): Observable<OBTAirportDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus3$),
      switchMap(
        (term: string): Observable<OBTAirportDetails[]> =>
          term.length <= 2
            ? this.testArray3
            : (this.testArray3 = this.searchService.getAirportsList(term, null, this.arriveLocationIsLoading))
      )
    );

  flightLocations4 = (text$: Observable<string>): Observable<OBTAirportDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus4$),
      switchMap(
        (term: string): Observable<OBTAirportDetails[]> =>
          term.length <= 2
            ? this.testArray4
            : (this.testArray4 = this.searchService.getAirportsList(term, null, this.arriveLocationIsLoading))
      )
    );

  firstAirline = (text$: Observable<string>): Observable<OBTAirlinesDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus5$),
      switchMap(
        (term: string): Observable<OBTAirlinesDetails[]> =>
          term.length <= 2
            ? this.firstAirlineArray
            : (this.firstAirlineArray = this.searchService.getAirlinesList(term, this.arriveLocationIsLoading))
      )
    );

  secondAirline = (text$: Observable<string>): Observable<OBTAirlinesDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus6$),
      switchMap(
        (term: string): Observable<OBTAirlinesDetails[]> =>
          term.length <= 2
            ? this.secondAirlineArray
            : (this.secondAirlineArray = this.searchService.getAirlinesList(term, this.arriveLocationIsLoading))
      )
    );

  thirdAirline = (text$: Observable<string>): Observable<OBTAirlinesDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus7$),
      switchMap(
        (term: string): Observable<OBTAirlinesDetails[]> =>
          term.length <= 2
            ? this.thirdAirlineArray
            : (this.thirdAirlineArray = this.searchService.getAirlinesList(term, this.arriveLocationIsLoading))
      )
    );

  fourthAirline = (text$: Observable<string>): Observable<OBTAirlinesDetails[]> =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focus8$),
      switchMap(
        (term: string): Observable<OBTAirlinesDetails[]> =>
          term.length <= 2
            ? this.fourthAirlineArray
            : (this.fourthAirlineArray = this.searchService.getAirlinesList(term, this.arriveLocationIsLoading))
      )
    );

  hideFlightFarePreferences(): boolean {
    return this.userService.isUserFavoriteSet(LightningUserFavorurite.HideFlightFarePreferences);
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (activeButton.offsetTop + activeButton.clientHeight > typeAheadList.clientHeight + typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      // tslint:disable-next-line: quotemark
      console.log("Couldn't find elements to scroll");
    }
  }

  direct(direct: boolean): void {
    this.searchParams.maxConnections = direct ? 0 : null;
  }

  setUpMultiCitySearch() {
    this.searchService.isFavoritesSelected = false;
    this.searchParams.chosenSearchType = FlightSearchType.multiCity;
    const cloneMultiDestination: IMultiDestination[] = _.cloneDeep(
      this.searchParams.multiDestination
    );

    this.multiCitySearch = cloneMultiDestination.map((item) => {
      item.date = moment(item.date);
      return item;
    });
    this.setMultiCityMinDate(this.multiCitySearch[0].date);
  }

  updateMultiCityDateTime(index: number = 0) {
    if (index === 0) {
      this.setMultiCityMinDate((this.multiCitySearch[0].date));
      let nextDay = moment(this.multiCitySearch[0].date).add(1, 'day');
      this.multiCitySearch.map((item, i) => {
        if (
          i !== 0 &&
          moment(item.date).isBefore(this.multiCitySearch[0].date)
        ) {
          item.date = nextDay;
        }
        return item;
      });
    }

    this.searchParams.multiDestination = this.multiCitySearch;
    this.searchParams.departLocation = this.multiCitySearch[0].orig;
    this.searchParams.arriveLocation = this.multiCitySearch[0].dest;
    this.searchParams.outBoundDate = this.multiCitySearch[0].date;
  }

  setMultiCityMinDate(value: moment.Moment) {
    this.minimumMultiCityDate = {
      year: value.year(),
      month: value.month() + 1,
      day: value.date()
    };
  }

  viaChanged() {
    if (!this.searchParams.showVia) {
      this.searchParams.via = '';
      this.searchParams.maxConnections = null;
    } else {
      this.searchParams.maxConnections = 1;
    }
  }

  checkHotelCheckbox() {
    this.displayHotelCheck = (this.checkDiffBetweenDates(this.searchParams.outBoundDate, this.searchParams.inboundDate) && this.searchParams.chosenSearchType === this.FlightSearchType.return && !!this.searchParams.departLocation && !!this.searchParams.arriveLocation);
    if (!this.displayHotelCheck && this.toggleHotelSearchRequested.observed) {
      this.hotelsearchRequested = false;
      this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
    } else if (this.displayHotelCheck) {
      this.searchService.searches[ServiceType.Flight].setHotelParams();
    }
  }

  checkDiffBetweenDates(
    outBoundDate: moment.Moment,
    inboundDate: moment.Moment
  ): boolean {
    return (!outBoundDate.isSame(inboundDate, 'date') && inboundDate > outBoundDate); //If they're not on the same date, and inbound is later than outbound, then the stay must be atleast over 1 night
  }

  toggleHotelCheckbox() {
    this.hotelsearchRequested = !this.hotelsearchRequested;

    this.requireHotelCheckbox(this.hotelsearchRequested);
    this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);

  }

  requireHotelCheckbox(isHotelRequired = false): void {
    if (!isHotelRequired) return;

    this.searchService.searches[ServiceType.Flight].setHotelParams();
  }

}
