import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FlightPipe } from '../../../startup/pipes/flight-pipe';
// import { SLIDE_UP_AND_DOWN } from '../../obtservices/vendor/data/animations';
// import { EurostarClassEnum, EurostarStation } from '../../obtservices/vendor/models/eurostar.model';
import { EurostarSearchType, EurostarEnterpriseSearchInterface, TimeWindow, OBTAirportDetails, LocationTypes } from '@sabstravtech/obtservices/base';
import { EnterpriseSearchService, UserService, ServiceType, ModalOpenerService } from '@sabstravtech/obtservices/angular';
// import { PreferredClass } from '../../obtservices/vendor/models/preferred-class.model';
// import { TaxiAddress } from '../../obtservices/vendor/models/taxi.model';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, of, Subject } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';


// import { ModalTypes, ModalOpenerService } from '../../obtservices/vendor/services/modal-opener.service';
// import { SetDefaultService } from '../../obtservices/vendor/services/set-default.service';

/**
 * Serch form for eurostar separate product
 *
 * @export
 * @class EurostarSearchComponent
 * @extends {EurostarSearchComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-eurostar-search',
  templateUrl: './eurostar-search.component.html',
  styleUrls: ['./eurostar-search.component.scss', '../flight.search/flight-search.component.scss'],
  // animations: [SLIDE_UP_AND_DOWN]
})
export class EurostarSearchComponent extends BaseComponent implements OnInit {
  searchParams: EurostarEnterpriseSearchInterface;
  EurostarSearchType: typeof EurostarSearchType = EurostarSearchType;
  @ViewChild('ofd', { read: NgbTypeahead })
  ofd: NgbTypeahead;
  /** Ref to inbound airport input */
  @ViewChild('ofa', { read: NgbTypeahead })
  ofa: NgbTypeahead;
  /** List of found outbound airports */
  outboundClick$: Subject<string> = new Subject();
  outboundFocus$: Subject<string> = new Subject();
  outboundList: Observable<any[]>;
  inboundClick$: Subject<string> = new Subject();
  inboundFocus$: Subject<string> = new Subject();
  /** List of found inbound airports */
  inboundList: Observable<any[]>;
  loadingOutbound: boolean = false;
  loadingInbound: boolean = false;
  TimeWindow: typeof TimeWindow = TimeWindow;
  allowedClasses;
  hotelsearchRequested: boolean = false;
  displayHotelCheck: boolean = false;
  showFavourites: boolean = false;
  ServiceType = ServiceType;
  EurostarToCity = {
    QDH:  {
      countryCode: "GB",
      id: "11845",
      latitude: 51.14648,
      longitude: 0.87376,
      name: "Ashford",
      type: "City"
   }, // ashford
    ZYR:  {
      countryCode: "BE",
      id: "2335",
      latitude: 50.85045,
      longitude: 4.34878,
      name: "Brussels",
      type: "City"
   }, // brussels
    XQE:  {
      countryCode: "GB",
      id: "11054",
      latitude: 51.3140,
      longitude: 1.3521,
      name: "Swanscombe",
      type: "City"
   }, // ebbsfleet
    XDB:  {
      countryCode: "FR",
      id: "10424",
      latitude: 50.63297,
      longitude: 3.05858,
      name: "Lille",
      type: "City"
   }, // lille
    QQS:  {
      countryCode: "GB",
      id: "11343",
      latitude: 51.5128,
      longitude: -0.0918,
      name: "London",
      type: "City"
   }, // london
    XED:  {
      countryCode: "FR",
      id: "10267",
      latitude: 48.85341,
      longitude: 2.3488,
      name: "Paris",
      type: "City",
   }, // disneyland - assume paris
    XPG:  {
      countryCode: "FR",
      id: "10267",
      latitude: 48.85341,
      longitude: 2.3488,
      name: "Paris",
      type: "City",
   }, // paris
   QRH:  {
      countryCode: "NL",
      id: "20850",
      latitude: 51.9225,
      longitude: 4.47917,
      name: "Rotterdam",
      type: "City",
    },
    ZYA:  {
      countryCode: "NL",
      id: "21039",
      latitude: 52.37403,
      longitude: 4.88969,
      name: "Amsterdam",
      type: "City",
    }
  };
  @Output() toggleHotelSearchRequested: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public deviceDetector: DeviceDetector,
    public userService: UserService,
    public searchService: EnterpriseSearchService,
    private modalOpenerService: ModalOpenerService,
    protected flightPipe: FlightPipe,
    protected title: Title,
    public translateService: TranslateService
    // protected eurostarService: EurostarService,
  ) {
    super(title, translateService);
  }

  ngOnInit(): void {
    this.searchParams = this.searchService.searches[ServiceType.Eurostar];
    this.showFavouritesOption();
  }


  showFavouritesOption(){
    this.searchService.isFavoritesSelected = false;
    this.showFavourites = (this.userService.getUserFavoriteObject(LightningUserFavorurite.EurostarSearchDefaults) as { enableFavoritesOptionForEurostar: boolean; }
    )?.enableFavoritesOptionForEurostar;
  }

  formatter_rail_air = (x: { destination: string; }) => x.destination; // used for formatting the output on the dropdown


  /**
   * Fetch airports for outbount input
   *
   * @memberof EurostarSearchComponent
   */
  getOutStations = (text$: Observable<string>) => {
    return this.getStations(text$, {
      click: this.outboundClick$,
      focus: this.outboundFocus$,
      loader: this.loadingOutbound,
      input: this.ofd,
      list: this.outboundList
    });
  };

  /**
   * Fetch airports for inbound input
   *
   * @memberof EurostarSearchComponent
   */
  getInStations = (text$: Observable<string>) => {
    return this.getStations(text$, {
      click: this.inboundClick$,
      focus: this.inboundFocus$,
      loader: this.loadingInbound,
      input: this.ofa,
      list: this.inboundList
    });
  };

  /**
   * Global get airport function to avoid duplicated code
   * gets
   *
   * @private
   * @memberof EurostarSearchComponent
   */
  private getStations = (
    text$: Observable<string>,
    {
      click,
      focus,
      loader,
      input,
      list
    }: {
      click: Subject<string>;
      focus: Subject<string>;
      loader: boolean;
      input: NgbTypeahead;
      list: Observable<any[]>;
    }
  ): Observable<unknown> => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged());
    const clicksWithClosedPopup$ = focus.pipe(filter(() => !input.isPopupOpen()));
    // return of([])
    return merge(click, clicksWithClosedPopup$, debouncedText$).pipe(
      tap(() => (loader = true)),
      switchMap((term: string) => list = this.searchService.lookupEurostarStations(term)),
      tap(() => {
        loader = false;
      })
    );
  };

  // openFareRules(): void {
  //   this.modalService.open(
  //     ModalTypes.FareRulesModalComponent,
  //     {
  //       windowClass: 'fare-rules-modal',
  //       centered: true
  //     },
  //     {}
  //   );
  // }

  openFareRulesAndFocus(): void {
    this.openFareRules();
    setTimeout(function () {
      document.getElementById('standardHeader').focus();
    }, 0);
  }

  openFareRulesWithSpace(event: KeyboardEvent): void {
    if (event.keyCode === 32) {
      this.openFareRulesAndFocus();
    }
  }

  openFareRules(): void {
    this.modalOpenerService
      .open(
        LightningModalTypes.ModalFareRulesComponent,
        { windowClass: 'fare-rules-modal', centered: true },
        {}
      )
      .then();
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if ((activeButton.offsetTop + activeButton.clientHeight) > (typeAheadList.clientHeight + typeAheadList.scrollTop)) {
        typeAheadList.scrollTop = (activeButton.offsetTop + activeButton.clientHeight) - typeAheadList.clientHeight;
      }
      else if ((activeButton.offsetTop) < (typeAheadList.scrollTop)) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      console.log('Couldn\'t find elements to scroll');
    }
  }

  direct(direct: boolean): void {
    this.searchParams.maxConnections = direct ? 0 : null;
  }

  checkHotelCheckbox() {
    this.displayHotelCheck = (this.checkDiffBetweenDates(this.searchParams.outBoundDate, this.searchParams.inboundDate) && this.searchParams.chosenSearchType === this.EurostarSearchType.return && !!this.searchParams.departLocation && !!this.searchParams.arriveLocation);
    if (!this.displayHotelCheck && this.toggleHotelSearchRequested.observed) {
      this.hotelsearchRequested = false;
      this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
    }
  }

  checkDiffBetweenDates(
    outBoundDate: moment.Moment,
    inboundDate: moment.Moment
  ): boolean {
    return (!outBoundDate.isSame(inboundDate, 'date') && inboundDate > outBoundDate) //If they're not on the same date, and inbound is later than outbound, then the stay must be atleast over 1 night
  }

  toggleHotelCheckbox() {
    this.hotelsearchRequested = !this.hotelsearchRequested;
    this.requireHotelCheckbox(this.hotelsearchRequested);
    this.toggleHotelSearchRequested.emit(this.hotelsearchRequested);
  }

  requireHotelCheckbox(isHotelRequired = false): void {
    if (!isHotelRequired) return;
    this.searchService.searches[ServiceType.Hotel].checkin_date = this.searchParams.outBoundDate;
    this.searchService.searches[ServiceType.Hotel].checkout_date = this.searchParams.inboundDate;
    const location = this.searchParams.arriveLocation as OBTAirportDetails;
    this.searchService.searches[ServiceType.Hotel].location = this.EurostarToCity[location?.gateway];

    let hotelCounty = {
      cCode: this.EurostarToCity[location?.gateway]?.countryCode,
      cName:
        this.EurostarToCity[location?.gateway]?.countryCode === 'FR'
          ? 'France'
          : this.EurostarToCity[location?.gateway]?.gateway === 'BE'
            ? 'Belgium'
            : 'United Kingdom'
    };

    this.searchService.searches[ServiceType.Hotel].country = hotelCounty;
    this.searchService.searches[ServiceType.Hotel].location_type_select = LocationTypes.City;
  }

}
