import { Component, Input, OnInit } from '@angular/core';
import { EnterpriseSearchService, ServiceType, RailSearchJourneyType } from '@sabstravtech/obtservices/angular';
import { BaseComponent, } from '../../../vendor/components/base_components/base-componet';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { 
  FlightEnterpriseSearchInterface, 
  RailEnterpriseSearchInterface, 
  EurostarEnterpriseSearchInterface,
  HotelEnterpriseSearchInterface,
  GetUserFavouriteSearchesResultArray,
  CarhireEnterpriseSearchInterface,
  LocationTypes,
  GetUserFavouriteSearchesResult } from '@sabstravtech/obtservices/base';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AirportExpressPipe } from '../../../startup/pipes/airport-express.pipe';

@Component({
  selector: 'app-favorite-items',
  templateUrl: './favorite-items.component.html',
  styleUrl: './favorite-items.component.scss'
})
export class FavoriteItemsComponent extends BaseComponent implements OnInit{
  @Input() serviceType: ServiceType;
  private airportExpressPipe = new AirportExpressPipe();
  trash = faTrash;
  spinner = faSpinner;
  ServiceType = ServiceType;

  favouriteItems: GetUserFavouriteSearchesResultArray = [];
  dropdownOpen: boolean = false;
  isRemoving: boolean = false;

  constructor(
    public searchService: EnterpriseSearchService,
    title: Title,
    public translateService: TranslateService
  ){
    super(title, translateService);
  }

  ngOnInit() {
    this.subscribe(this.searchService.getUserFavouriteSearches(this.serviceType), (data: GetUserFavouriteSearchesResultArray) => {
      this.favouriteItems = data;
    }, error => {
      console.error('Error loading favorite items:', error);
    });
  }

  // Determine the title based on service type
  getTitle(item: GetUserFavouriteSearchesResult): string {
    switch (this.serviceType) {
      case ServiceType.Flight:
        return this.getFlightTitle(item);
      case ServiceType.Rail:
        return this.getRailTitle(item);
      case ServiceType.Eurostar:
        return this.getEurostarTitle(item);
      case ServiceType.Hotel:
        return this.getHotelTitle(item);
      case ServiceType.Car:
        return this.getCarTitle(item);
      default:
        return this.translateService.instant('Unknown');
    }
  }

  private getFlightTitle(item: GetUserFavouriteSearchesResult): string {
    let title = '';
  
    // Check if it's a multi-city flight
    if (item.searchArgs?.multiDestination?.length) {
        title = this.translateService.instant('Multi-City');
        // Get the origin and destination from the multiDestination array
        const firstLeg = item.searchArgs.multiDestination[0];
        const lastLeg = item.searchArgs.multiDestination[item.searchArgs.multiDestination.length - 1];
        
        const origin = firstLeg.orig
          ? `${firstLeg.orig}` // Adjust if airportIata filter is needed here
          : 'Unknown';

        const destination = lastLeg.dest
          ? `${lastLeg.dest}` // Adjust if airportIata filter is needed here
          : 'Unknown';
        
        title = `${title}: ${origin} - ${destination}`;

    } else {
        // Handle One-way or Return flight
        title = item.searchArgs.returnTrip ? this.translateService.instant('Return for') : this.translateService.instant('One-way for');

        const origin = item.searchArgs.originAirportFull 
            ? `${item.searchArgs.originAirportFull} (${item.searchArgs.originAirport})` 
            : item.searchArgs.originAirport || this.translateService.instant('Unknown');
            
        const destination = item.searchArgs.destinationAirportFull 
            ? `${item.searchArgs.destinationAirportFull} (${item.searchArgs.destinationAirport})` 
            : item.searchArgs.destinationAirport || this.translateService.instant('Unknown');

        const outboundCabinClass = item.searchArgs.outboundCabinClass 
            ? item.searchArgs.outboundCabinClass[0] 
            : this.translateService.instant('Unknown');
        const returnCabinClass = item.searchArgs.returnTrip && item.searchArgs.returnCabinClass 
            ? item.searchArgs.returnCabinClass[0] 
            : null;

        title = `${title} ${origin} - ${destination} ${this.translateService.instant('in class')} ${outboundCabinClass}`;

        if (item.searchArgs.returnTrip) {
            title += `, ${returnCabinClass}` || this.translateService.instant('Unknown');
        }
    }

    return title;
}

  private getRailTitle(item: GetUserFavouriteSearchesResult): string {
    if (item.searchArgs.isAirportExpress) {
      return `${this.airportExpressPipe.transform(item.searchArgs.airportExpressStation)} (${this.translateService.instant('Airport Express')})`;
    } else {
      const { travellingFrom, travellingTo, journeyType, class: travelClass } = item.searchArgs;
      const departureLocation = travellingFrom?.name
      const arrivalLocation = travellingTo?.name
      const journeyTypeText = journeyType === RailSearchJourneyType.ReturnJourney ? this.translateService.instant('return') : this.translateService.instant('one-way');
      const classText = travelClass.toLowerCase();
      return `${this.translateService.instant('Train')} ${departureLocation} - ${arrivalLocation}: ${journeyTypeText} ${this.translateService.instant('in class')} ${classText}`;
    }
   
  }

  private getEurostarTitle(item: GetUserFavouriteSearchesResult): string {
    const { departLocation, arriveLocation, returnTrip, outboundCabinClassName, returnCabinClassName} = item.searchArgs;
    let title = returnTrip ? this.translateService.instant('Return for') : this.translateService.instant('One-way for');
    const departureLocation = departLocation?.destination;
    const arrivalLocation = arriveLocation?.destination;
    const outboundCabinClass = outboundCabinClassName 
      ? outboundCabinClassName[0] 
      : this.translateService.instant('Unknown');
    const returnCabinClass = returnTrip && returnCabinClassName 
      ? returnCabinClassName[0] 
      : null;

    title = `${title} ${departureLocation} - ${arrivalLocation} ${this.translateService.instant('in class')} ${outboundCabinClass}`;

    if (returnTrip) {
        title += `, ${returnCabinClass}` || this.translateService.instant('Unknown');
    }

    return title;
  }

  private getHotelTitle(item: GetUserFavouriteSearchesResult): string {
    const { location_type_select, iata, airportDetail, cityName, office, trainStationCode, countryCode, postOrZipCode,
      distance, hotelName, groupName
     } = item.searchArgs;
    let title = "";

  // Determine title based on location type
  switch (location_type_select) {
    case LocationTypes.Airport:
      const airportName = airportDetail?.destination 
        ? `${airportDetail.destination} (${iata})` 
        : iata || this.translateService.instant('Unknown');
      title = `${this.translateService.instant('Airport')}: ${airportName}`;
      break;
    case LocationTypes.City:
      title = cityName ? `${this.translateService.instant('City')}: ${cityName}` : postOrZipCode ? `${this.translateService.instant('City')}: ${postOrZipCode}` : this.translateService.instant('Unknown');
      break;
    case LocationTypes.Office:
      title = office?.name ? `${this.translateService.instant('Office')}: ${office.name}` : this.translateService.instant('Unknown');
      break;
    case LocationTypes.TrainStation:
      console.log(trainStationCode);
      title = trainStationCode ? `${this.translateService.instant('Train Station')}: ${trainStationCode}` : this.translateService.instant('Unknown');
      break;
    case LocationTypes.Shortlist:
      title = countryCode ? `${this.translateService.instant('Shortlist')}: ${countryCode}` : this.translateService.instant('Unknown');
      break;
    default:
      title = this.translateService.instant('Unknown');
    }

    if (distance) {
      title += ` | ${this.translateService.instant('Distance')}: ${distance}`;
    }

    if (hotelName) {
      title += ` | ${this.translateService.instant('Hotel')}: ${hotelName}`;
    }

    if (groupName) {
      title += ` | ${this.translateService.instant('Chain')}: ${groupName}`;
    }
    return title;
  }

  private getCarTitle(item: GetUserFavouriteSearchesResult): string{
    let title = '';
    const { city, airport, depot, chosenDistance, aircon, carClass, transmission, vendor, type, estimatedDailyDistance, collection, delivery, driverAge } = item.searchArgs;
    if (city) {
      // Format for city pickup and dropoff
      const pickupCity = `${city.pickupName} (${city.pickupCountryCode})`;
      const dropoffCity = `${city.dropOffName} (${city.dropoffCountryCode})`;
      title = `${pickupCity} - ${dropoffCity}`;
    } else if (airport) {
      // Format for airport pickup and dropoff
      const pickupAirport = airport.pickupAirportFullName
        ? `${airport.pickupAirportFullName} (${airport.pickupCode})`
        : airport.pickupCode || this.translateService.instant('Unknown');
      const dropoffAirport = airport.dropoffAirportFullName
        ? `${airport.dropoffAirportFullName} (${airport.dropoffCode})`
        : airport.dropoffCode || this.translateService.instant('Unknown');
      title = `${pickupAirport} - ${dropoffAirport}`;
    } else if (depot && depot.pickupVendor) {
      // Format for depot pickup and dropoff
      const pickupDepot = `${depot.pickupVendor} ${depot.pickupAddressLines}, ${depot.pickupCityName}`;
      const dropoffDepot = `${depot.dropoffVendor} ${depot.dropoffAddressLines}, ${depot.dropoffCityName}`;
      title = `${pickupDepot} - ${dropoffDepot}`;
    } else {
      this.translateService.instant('Unknown');
    }
    // Add additional parameters if they exist
    if (chosenDistance) {
      title += ` | ${this.translateService.instant('Distance')}: ${chosenDistance}`;
    }
    if (aircon) {
      title += ` | ${this.translateService.instant('Air Conditioning')}: ${aircon ? this.translateService.instant('Yes') : this.translateService.instant('No')}`;
    }
    if (carClass) {
      title += ` | ${this.translateService.instant('Car Class')}: ${carClass}`;
    }
    if (transmission) {
      title += ` | ${this.translateService.instant('Transmission')}: ${transmission}`;
    }
    if (vendor) {
      title += ` | ${this.translateService.instant('Vendor')}: ${vendor}`;
    }
    if (type) {
      title += ` | ${this.translateService.instant('Car Type')}: ${type}`;
    }
    if (estimatedDailyDistance) {
      title += ` | ${this.translateService.instant('Estimated Mileage')}: ${estimatedDailyDistance}`;
    }
    if (collection) {
      title += ` | ${this.translateService.instant('Collection')}: ${collection ? this.translateService.instant('Yes') : this.translateService.instant('No')}`;
    }
    if (delivery) {
      title += ` | ${this.translateService.instant('Delivery')}: ${delivery ? this.translateService.instant('Yes') : this.translateService.instant('No')}`;
    }
    if (driverAge) {
      title += ` | ${this.translateService.instant('Driver Age')}: ${driverAge}`;
    }
    return title;
  }

  favouritySelected(selectedItem: GetUserFavouriteSearchesResult){
    this.searchService.isFavoritesSelected = false;
    console.log('Selected favorite:', selectedItem);
    switch (this.serviceType) {
      case ServiceType.Flight:
        const flightParams: FlightEnterpriseSearchInterface = this.searchService.searches[ServiceType.Flight];
        flightParams.applyFavourite(selectedItem.searchArgs);
        break;
      case ServiceType.Rail:
        const railParams: RailEnterpriseSearchInterface = this.searchService.searches[ServiceType.Rail];
        railParams.applyFavourite(selectedItem.searchArgs);
        console.log('Rail Search Params:', railParams);
        break;
      case ServiceType.Eurostar:
        const eurostarParams: EurostarEnterpriseSearchInterface = this.searchService.searches[ServiceType.Eurostar];
        eurostarParams.applyFavourite(selectedItem.searchArgs);
        console.log('Eurostar Search Params:', eurostarParams);
      case ServiceType.Hotel:
        const hotelParams: HotelEnterpriseSearchInterface = this.searchService.searches[ServiceType.Hotel];
        hotelParams.applyFavourite(selectedItem.searchArgs);
      case ServiceType.Car:
        const carParams: CarhireEnterpriseSearchInterface = this.searchService.searches[ServiceType.Car];
        carParams.applyFavourite(selectedItem.searchArgs);
      default:
        return this.translateService.instant('Unknown');
    }
  }

  toggleFavouritesList() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  removeFavourite(item: GetUserFavouriteSearchesResult) {
    this.isRemoving = true;
    this.subscribe(this.searchService.deleteFavouriteSearchByID(item.id), () => {
      this.isRemoving = false;
      this.favouriteItems = this.favouriteItems.filter(fav => fav !== item);
    });
  }
}
