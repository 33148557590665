<div id="breakdown-dialog">
  <div class="modal-header">
    <h3 class="modal-title">Hotel rate information</h3>
    <button
      type="button"
      class="light-icon-button close"
      aria-label="Close"
      (click)="activeModal.close(false)"
      ngbAutoFocus>
      <i class="ico-cancel AA-standard-grey" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <ngb-datepicker
      class="datepicker"
      [startDate]="hotelRoom.nights[0].date"
      [markDisabled]="isDisabled"
      navigation="arrows"
      [dayTemplate]="dayTemplate">
    </ngb-datepicker>
    <ng-template #dayTemplate let-date let-disabled="disabled">
      <span class="custom-day" [class.text-muted]="disabled">
        <span>
          {{ date.day }}
        </span>
        <div *ngFor="let night of hotelRoom.nights; let j = index" class="room-rate">
          <span
            *ngIf="
              date.day === night.date.day &&
              date.month === night.date.month &&
              date.year === night.date.year
            ">
            {{ night.rate | currency : hotelRoom.currencyCode : 'symbol' : '1.2-2' }}
          </span>
        </div>
      </span>
    </ng-template>

    <div class="dates-block">
      <b *ngIf="firstDay && lastDay"
        >{{ firstDay | sabsDate : 1 }} - {{ lastDay | sabsDate : 1 }}</b
      >
      <b class="dates-block-number-of-nights" *ngIf="numberOfNights"
        >({{ numberOfNights }} nights)</b
      >
    </div>

    <div class="price-block">
      <span class="average-price"
        >Avg {{ hotelRoom.total / hotelRoom.nights.length | currency : hotelRoom.currencyCode }}
        <span i18n="@@itemHotelsPRPN">prpn</span>&nbsp;</span
      >
      <span class="room-total-price">{{ hotelRoom.total | number : '1.2-2' }}</span>
      <sup class="room-currency-code">{{ hotelRoom.currencyCode }}</sup>
      <!-- #tick -->
      <app-warning-tags [ticket]="hotelRoom" [displayCheck]="true"></app-warning-tags>
      <!-- end #tick -->
    </div>
  </div>
</div>
