<ng-template #rt let-r="result" let-t="term">
  <div class="item-search-select">
    <span *ngIf="r.railstation?.stationCode" class="item-dest"
      >{{ r.name | flightFormat }} ({{ r.railstation?.stationCode }})</span
    >
    <span *ngIf="!r.railstation?.stationCode" class="item-dest">{{ r.name }}</span>
  </div>
</ng-template>
<div id="railSearchForm1" class="rail-search-form">
  <div *ngIf="trainlineEnabled && euSearchEnabled" class="rail-search-type">
    <label class="header-label" i18n="@@railSelectRailOptions" id="rail-search-options">Select rail options</label>
    <mat-radio-group [(ngModel)]="searchParams.location" (ngModelChange)="changeLocation($event)" aria-labelledby="rail-search-options">
      <mat-radio-button
        *ngFor="let location of searchParams.railLocation"
        class="light-primary"
        color="primary"
        [value]="location.value"
        [name]="location.display">
        {{ location.display }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <p>
    <app-light-toggle-group tabindex="-1">
      <app-light-toggle-btn
        #onewayBtn
        [selected]="searchParams.chosenSearchType === RailJourneyType.SingleJourney"
        (click)="searchJourneyType(RailJourneyType.SingleJourney)"
        (keydown.enter)="searchJourneyType(RailJourneyType.SingleJourney)"
        iconClass="ico-oneway"
        tabindex="-1"
        id="rail-toggle-one-way"
        i18n="@@railSearchTravellingOneWay"
        >One Way Only</app-light-toggle-btn
      >
      <app-light-toggle-btn
        #returnBtn
        [selected]="searchParams.chosenSearchType === RailJourneyType.ReturnJourney"
        (click)="searchJourneyType(RailJourneyType.ReturnJourney)"
        (keydown.enter)="searchJourneyType(RailJourneyType.ReturnJourney)"
        [disabled]="searchParams.carnetTicket"
        iconClass="ico-return"
        tabindex="-1"
        id="rail-toggle-return"
        i18n="@@railSearchTravellingReturn"
        >Return trip</app-light-toggle-btn
      >
      <app-light-toggle-btn
        #returnBtn
        *ngIf="!hideLiveRail && searchParams.ukRail"
        (click)="goToLiveSearch()"
        (keydown.enter)="goToLiveSearch()"
        iconClass="ico-rail"
        [isLink]="true"
        i18n="@@railSearchTravellingLive"
        aria-label="Live Rail and Station Information"
        class="rail-info"
        id="rail-live-rail-button"
        >Live Rail and Station Information</app-light-toggle-btn
      >
      <app-light-toggle-btn
        [selected]="searchService.isFavoritesSelected"
        (click)="searchService.isFavoritesSelected = true; searchParams.chosenSearchType = null"
        *ngIf="showFavourites"
        iconClass="ico-return"
        id="flght-tab-favourites"
        i18n="@@searchFavourites"
        >Favourites
      </app-light-toggle-btn>
    </app-light-toggle-group>
  </p>

  <ng-container *ngIf="!searchService.isFavoritesSelected;else favoriteSearchContainer">
    <label *ngIf="searchParams.ukRail" class="header-label">Airport Express</label>
    <!-- Airport Express is not translated  -->
    &nbsp;&nbsp;
    <input
      *ngIf="searchParams.ukRail"
      #checkbox
      type="checkbox"
      id="rail-airport-express-button"
      aria-label="Airport Express"
      [checked]="searchParams.isAirportExpress"
      (change)="
        searchParams.isAirportExpress = checkbox.checked; searchParams.airportExpressStation = null
      " />
    <app-airport-express-search *ngIf="searchParams.isAirportExpress"></app-airport-express-search>
    <div *ngIf="!searchParams.isAirportExpress" class="inputs-container">
      <div class="half-container">
        <div class="half-block">
          <label
            id="rail-travelling-from"
            class="header-label"
            for="rail-travel-from-input"
            i18n="@@railSearchTravellingFrom"
            >Travelling from:</label
          >
          <i class="ico-rail combo-box-icon" aria-hidden="true"></i>
          <div class="flex-row-container">
            <input
              #tf
              autocomplete="off"
              type="text"
              id="rail-travel-from-input"
              i18n-placeholder
              placeholder="Enter a location"
              onClick="this.select()"
              (selectItem)="checkHotelCheckbox()"
              class="input-box"
              name="travellingFrom"
              [ngbTypeahead]="railLocations"
              [(ngModel)]="searchParams.travellingFrom"
              [resultTemplate]="rt"
              [inputFormatter]="formatter2"
              aria-labelledby="rail-travelling-from"
              tabindex="0"
              (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
              (keyup.arrowup)="ensureElementIsScrolledTo($event)"
              [ngClass]="{
                'error-box': searchParams.validationMessages?.includes(
                  'Please Enter a From Location.'
                )
              }" />
            <!-- <i *ngIf='searchService.tempDestArray[5].loading'
              class='button_spinner input-loading-spinner-left'></i> -->
          </div>
        </div>
        <div class="half-block">
          <label
            id="rail-travelling-to"
            class="header-label"
            for="rail-travel-to-input"
            i18n="@@railSearchTravellngTo">
            Travelling to:
          </label>
          <i class="ico-rail combo-box-icon" aria-hidden="true"></i>
          <div class="flex-row-container">
            <input
              #tt
              autocomplete="off"
              type="text"
              id="rail-travel-to-input"
              i18n-placeholder
              placeholder="Enter a location"
              onClick="this.select()"
              class="input-box"
              (selectItem)="checkHotelCheckbox()"
              name="travellingTo"
              [ngbTypeahead]="railLocations2"
              [(ngModel)]="searchParams.travellingTo"
              [resultTemplate]="rt"
              [inputFormatter]="formatter2"
              aria-labelledby="rail-travelling-to"
              tabindex="0"
              (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
              (keyup.arrowup)="ensureElementIsScrolledTo($event)"
              [ngClass]="{
                'error-box': searchParams.validationMessages?.includes('Please Enter a To Location.')
              }" />
            <!-- <i *ngIf='searchService.tempDestArray[6].loading'
              class='button_spinner input-loading-spinner-left'></i> -->
          </div>
        </div>
      </div>
      <div class="half-container" *ngIf="searchParams.ukRail">
        <div class="half-block">
          <label id="rail-travelling-via" class="header-label" for="rail-travel-via-input"
            >Via:
            <section (mouseleave)="tt1.close()" class="container">
              <i
                class="ico ico-info-circle"
                placement="auto"
                i18n-ngbTooltip
                triggers="manual"
                #tt1="ngbTooltip"
                (mouseenter)="tt1.open()"
                (focus)="tt1.open()"
                (blur)="tt1.close()"
                tabindex="0"
                aria-label='Please be aware that any station using the" Via" field must be between the stations selected in your search
                criteria.'
                ngbTooltip='Please be aware that any station using the" Via" field must be between the stations selected in your search
                criteria.'>
                <span class="visuallyhidden"
                  >Please be aware that any station using the "Via" field must be between the stations
                  selected in your search criteria.</span
                >
              </i>
            </section>
          </label>
          <div class="flex-row-container">
            <i class="ico-rail combo-box-icon" aria-hidden="true"></i>
            <input
              #tl
              autocomplete="off"
              type="text"
              id="rail-travel-via-input"
              i18n-placeholder
              placeholder="Enter a location"
              onClick="this.select()"
              class="input-box"
              name="viaLocation"
              [ngbTypeahead]="railLocations3"
              [(ngModel)]="searchParams.viaLocation"
              [resultTemplate]="rt"
              [inputFormatter]="formatter2"
              aria-labelledBy="rail-travelling-via"
              tabindex="0"
              (keyup.arrowdown)="ensureElementIsScrolledTo($event)"
              (keyup.arrowup)="ensureElementIsScrolledTo($event)" />
            <!-- <i *ngIf='searchService.tempDestArray[13].loading'
              class='button_spinner input-loading-spinner-left'></i> -->
          </div>
        </div>
        <div class="half-block"></div>
      </div>
      <div class="half-container flex-start">
        <div class="half-block">
          <fieldset>
            <legend>
              <label
                class="visuallyhidden"
                for="dp1"
                id="outbounddateandtime"
                i18n="@@railSearchOutboundDateAndTime"
                >Outbound Date and Time</label
              >
            </legend>
            <div
              class="flex-row-container clearfix"
              [ngClass]="{
                'error-box': !searchService.isFavouriteSearch && searchParams.validationMessages?.includes(
                  'Departure Date/Time cannot be in the past.'
                )
              }">
              <ngbd-datepicker-i18n>
                <div class="flex-column-container combo-box-left">
                  <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                  <label class="header-label date-label" for="rail-outbound-rail-date" i18n="@@railSearchOutboundDate">Outbound Date (DD/MM/YYYY)</label>
                  <app-datepicker
                    [minDate]="searchParams.minOutboundDate"
                    [startDate]="searchParams.outbound_date"
                    [model]="searchParams.outbound_date"
                    [class]="'combo-box'"
                    [componentId]="'rail-outbound-rail-date'"
                    (newDate)="searchParams.outbound_date = $event; checkHotelCheckbox()">
                  </app-datepicker>
                </div>
                <div class="flex-column-container select-box-right">
                  <i class="ico-clock combo-box-icon" aria-hidden="true"></i>
                  <label class="header-label" for="rail-select-outbound-time" i18n="@@railSearchOutboundTime">Outbound Time</label>
                  <select
                    class="combo-box"
                    [(ngModel)]="searchParams.outbound_time"
                    aria-labelledby="rail-outbound-time"
                    id="rail-select-outbound-time">
                    <option
                      *ngFor="let time of searchParams.timeArrayQuarterAnHour"
                      [ngValue]="time.value">
                      {{ time.display }}
                    </option>
                  </select>
                </div>
              </ngbd-datepicker-i18n>
            </div>

            <div class="flex-row-container flex-row-container--tb-margin">
              <mat-radio-group
                [(ngModel)]="searchParams.outboundCriteria"
                class="triple-radio-buttons">
                <mat-radio-button
                  class="light-primary"
                  color="primary"
                  [value]="retcrit"
                  id="rail-outbound-crit-checkbox-{{ retcrit | removeSpaces }}"
                  *ngFor="let retcrit of searchParams.railCriteria">
                  {{ retcrit | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </fieldset>
        </div>
        <div
          class="half-block rail_return_container"
          [ngClass]="{
            rail_return_disabled: searchParams.chosenSearchType === RailJourneyType.SingleJourney
          }">
            <fieldset>
            <legend class="visuallyhidden">
              Return Date and Time
            </legend>
            <div class="flex-row-container">
              <ngbd-datepicker-i18n>
                <div class="flex-column-container combo-box-left">
                  <label
                    class="header-label date-label"
                    for="rail-return-rail-date"
                    id="returndateandtime"
                    i18n="@@railSearchReturnDate"
                    >Return Date (DD/MM/YYYY)</label
                  >
                  <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                  <app-datepicker
                    [minDate]="searchParams.minInboundDate"
                    [startDate]="searchParams.return_date"
                    [model]="searchParams.return_date"
                    [class]="'combo-box'"
                    [componentId]="'rail-return-rail-date'"
                    [disabled]="!(searchParams.chosenSearchType === RailJourneyType.ReturnJourney)"
                    [ngClass]="{
                      rail_return_disabled:
                        searchParams.chosenSearchType === RailJourneyType.SingleJourney ||
                        searchParams.openReturn
                    }"
                    (newDate)="searchParams.return_date = $event; checkHotelCheckbox()">
                  </app-datepicker>
                </div>
                <div class="flex-column-container select-box-right">
                  <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                  <div class="flex-row-container flex-row-container--between flex-row-container--end">
                    <label
                      id="rail-return-date"
                      class="header-label"
                      i18n="@@RailSearchReturnTime"
                      for="rail-select-return-time"
                      >Return Time</label
                    >
                    <mat-checkbox
                      color="primary"
                      #checkbox
                      [disabled]="searchParams.chosenSearchType === RailJourneyType.SingleJourney"
                      *ngIf="!hideOpenReturn && allowOpenReturn && searchParams.ukRail"
                      class="light-primary"
                      i18n="@@railSearchOpenReturn"
                      id="rail-open-return-checkbox"
                      [(ngModel)]="searchParams.openReturn"
                      (change)="searchParams.openReturn = checkbox.checked"
                      (keydown.enter)="searchParams.openReturn = checkbox.checked">
                      Open return
                    </mat-checkbox>
                  </div>
                  <select
                    class="select-box"
                    [disabled]="!(searchParams.chosenSearchType === RailJourneyType.ReturnJourney)"
                    [ngClass]="{
                      rail_return_disabled:
                        searchParams.chosenSearchType === RailJourneyType.SingleJourney ||
                        searchParams.openReturn
                    }"
                    [(ngModel)]="searchParams.return_time"
                    aria-labelledby="rail-return-time"
                    id="rail-select-return-time">
                    <option
                      *ngFor="let time of searchParams.timeArrayQuarterAnHour"
                      [ngValue]="time.value">
                      {{ time.display }}
                    </option>
                  </select>
                </div>
              </ngbd-datepicker-i18n>
            </div>
            <div class="flex-row-container flex-row-container--tb-margin">
              <mat-radio-group
                [(ngModel)]="searchParams.returnCriteria"
                layout="row"
                class="triple-radio-buttons">
                <mat-radio-button
                  class="light-primary"
                  id="rail-return-crit-checkbox-{{ retcrit | removeSpaces }}"
                  color="primary"
                  [value]="retcrit"
                  *ngFor="let retcrit of searchParams.railCriteria">
                  {{ retcrit | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="flex-column-container">
        <h2 class="header-label rem-1-5" i18n="@@railSearchOptions">Options</h2>
        <div class="quarter-container" *ngIf="!hideClasses">
          <div class="quarter-block">
            <label id="rail-service-class" i18n="@@RailSearchMaxClass" for="rail-select-class">
              Class of Service
            </label>
            <select
              class="combo-box"
              [(ngModel)]="searchParams.chosenSearchClass"
              aria-labelledby="rail-service-class"
              id="rail-select-class">
              <option
                *ngFor="let serviceClass of searchParams.classTypes | async; trackBy: trackByClass"
                [value]="serviceClass.value">
                {{ serviceClass.display | translate }}
              </option>
            </select>
          </div>
        </div>
        <mat-checkbox
          *ngIf="searchParams.ukRail && !trainlineEnabled"
          color="primary"
          id="rail-checkbox-extra-time"
          class="light-primary"
          [(ngModel)]="searchParams.extraTime"
          i18n="@@railSearchExtraTimeToChange"
          (keydown.enter)="searchParams.extraTime = !searchParams.extraTime">
          Extra time to change
        </mat-checkbox>
        <mat-checkbox
          *ngIf="searchParams.ukRail"
          color="primary"
          class="light-primary"
          id="rail-railcard-checkbox"
          [(ngModel)]="searchParams.applyRailcards"
          i18n="@@railSearchApplyRailcard"
          (change)="showApplyRailModal()"
          (keydown.enter)="searchParams.applyRailcards = !searchParams.applyRailcards">
          Apply Railcard/Concession
        </mat-checkbox>
        <div class="railcard_container" [hidden]="!searchParams.applyRailcards">
          <label class="container_label">
            <h3 i18n="@@railSearchSelectRailCard">
              Select Rail Card
              <span>
                <section (mouseleave)="ttip.close()" class="container">
                  <i
                    class="ico ico-info-circle"
                    placement="top"
                    i18n-ngbTooltip
                    triggers="manual"
                    #ttip="ngbTooltip"
                    tooltipClass="railcard-tooltip"
                    (mouseenter)="ttip.open()"
                    (focus)="ttip.open()"
                    (blur)="ttip.close()"
                    tabindex="0"
                    aria-label="The system will automatically work out the best combination of discounts based on the type of Railcard selected. Travellers may also be asked to produce evidence of entitlement to Railcard discounts when travelling, please refer to your railcard terms and conditions."
                    ngbTooltip="The system will automatically work out the best combination of discounts based on the type of Railcard selected. Travellers may also be asked to produce evidence of entitlement to Railcard discounts when travelling, please refer to your railcard terms and conditions.">
                    <span class="visuallyhidden"
                      >The system will automatically work out the best combination of discounts based
                      on the type of Railcard selected. Travellers may also be asked to produce
                      evidence of entitlement to Railcard discounts when travelling, please refer to
                      your railcard terms and conditions.</span
                    >
                  </i>
                </section>
              </span>
            </h3>
          </label>
          <span class="railcard_please_note"
            ><b i18n="@@railCardPleaseNote">Please Note:&nbsp;</b>
            <span i18n="@@railCardPleaseNoteMessage"
              >Travellers may be asked to produce evidence of entitlement to railcard discounts when
              travelling. If travelling together discounts are used, travellers must travel together
              to qualify for the fare.</span
            >
          </span>
          <p *ngIf="travellers.length === 0" i18n="@@railSearchNoTravellers">
            No travellers selected
          </p>
          <ng-container>
            <ng-container *ngIf="railCards?.length && searchService.getSelectedtravellers().length">
              <div
                class="railcard_select_container"
                *ngFor="let traveller of searchService.getSelectedtravellers(); let i = index">
                <label for="rail-select-tickets-{{ i }}"
                  >{{ traveller.forename }} {{ traveller.surname }}</label
                >
                <i class="ico-ticket input_icon" aria-hidden="true"></i>
                <span class="visuallyhidden">Select TravelCard from the list.</span>
                <select
                  name="railcard_select{{ i }}"
                  id="rail-select-tickets-{{ i }}"
                  [(ngModel)]="selectedTravelCards[i]"
                  (change)="changeRailcardOption(i, traveller.id)"
                  [compareWith]="cardComparator">
                  <option [ngValue]="undefined" i18n="@@railSearchNone">None</option>
                  <option *ngFor="let card of railCards" [ngValue]="card">
                    {{ card.name }}
                  </option>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="flex-row-container">
          <section (mouseleave)="tt2.close()" *ngIf="ShowCarnet" class="container">
            <mat-checkbox
              color="primary"
              class="light-primary"
              (ngModelChange)="onCarnetTicketChange($event)"
              [(ngModel)]="searchParams.carnetTicket"
              alt="Carnet tickets are valid for multiple journeys across the same route and are available in different quantities. Please
              check the conditions and validity before confirming"
              i18n-ngbTooltip
              ngbTooltip="Carnet tickets are valid for multiple journeys across the same route and are available in different quantities.
              Please check the conditions and validity before confirming"
              placement="top"
              triggers="manual"
              #tt2="ngbTooltip"
              (mouseenter)="tt2.open()"
              (focus)="tt2.open()"
              (blur)="tt2.close()"
              tabindex="0"
              i18n="@@railSearchCarnetTickets"
              (keydown.enter)="
                searchParams.carnetTicket = !searchParams.carnetTicket; (searchParams.carnetTicket)
              ">
              Carnet Tickets
            </mat-checkbox>
          </section>
        </div>
        <label class="header-label" *ngIf="searchParams.ukRail">
          <div class="london-underground-tag">
            <a
              class="locations_link"
              href="http://content.tfl.gov.uk/standard-tube-map.pdf"
              target="_blank"
              rel="noopener noreferrer"
              tabindex="0"
              id="rail-link-underground"
              role="link"
              i18n="@@railSearchLondonUndergroundMap"
              >View Underground Map</a
            >
          </div>
        </label>

        <!-- ! - EU Traveller Info (Trainline Only) -->
        <div class="flex-column-container traveller-container" *ngIf="!searchParams.ukRail">
          <h4 i18n="@@railTravellerInfo">Traveller Information</h4>
          <section *ngIf="!searchParams.trainlineTravellers?.length">
            <span class="error">Add a travelller</span>
          </section>
          <section *ngFor="let traveller of searchParams.trainlineTravellers">
            <app-trainline-traveller
              [traveller]="traveller"
              [railCards]="railCards"></app-trainline-traveller>
          </section>
        </div>
      </div>
    </div>

    <div
      class="flex-row-container hotel-check"
      *ngIf="
        searchParams.chosenSearchType === RailJourneyType.ReturnJourney &&
        displayHotelCheck &&
        toggleHotelSearchRequested.observed
      ">
      <button
        class="large-button"
        color="primary"
        [attr.aria-pressed]="hotelsearchRequested"
        id="rail-checkbox-hotel-{{hotelsearchRequested ? 'remove' : 'add'}}"
        (click)="toggleHotelCheckbox()">
        <span i18n="@@eurostarAddHotelcheck" *ngIf="!hotelsearchRequested"> Add Hotel </span>
        <span i18n="@@eurostarRemoveHotelcheck" *ngIf="hotelsearchRequested"> Remove Hotel </span>
      </button>
    </div>
  </ng-container>
  <ng-template #favoriteSearchContainer> 
    <app-favorite-items
      [serviceType]="ServiceType.Rail">
    </app-favorite-items>
  </ng-template>
  <ng-container
    *ngIf="
      hotelsearchRequested &&
      toggleHotelSearchRequested.observed &&
      searchParams.chosenSearchType === RailJourneyType.ReturnJourney
    ">
    <h2 class="hotel-header">Hotel</h2>
    <app-hotel-search></app-hotel-search>
  </ng-container>
</div>

<app-rail-loading-screen></app-rail-loading-screen>
<app-express-loading-screen *ngIf="searchService.search_loading && searchParams.isAirportExpress">
</app-express-loading-screen>
