<div class="details-wrapper">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="mat-content">
            <mat-panel-title>
              <span i18n="@@basketPanelTotalShowDetails">
                Show Details
              </span>
              <span class="sr-only"> for {{ flightDetails?.marketingCarrier }}{{ flightDetails?.marketingFlightNumber }}</span>
            </mat-panel-title>
          </span>
        </mat-expansion-panel-header>
        <ng-container>
          <div class="summary">
            <div>
              <strong>{{flightDetails.cabinClass | splitCamelCase}} <span *ngIf="flightDetails?.fareInfo?.title">({{flightDetails.fareInfo.title}})</span></strong>
            </div>
            <div>
              <strong i18n="@@ShowDetailsHandBaggage">Hand Baggage</strong>: 
              <span>
                  <span *ngIf="flightDetails?.additional?.isHandBaggage >=1; else noBag">
                    <span i18n="@@ShowDetailsHandBaggageYes">Yes</span>
                  </span>
                  <ng-template #noBag>
                    <span i18n="@@ShowDetailsHandBaggageNo">No</span>
                  </ng-template>
              </span>
            </div>
            <div *ngIf="flightDetails?.baggageAllowanceAmount !== undefined">
              <strong i18n="@@ShowDetailsHoldBaggage">Hold Baggage</strong>: 
              <span>
                {{ flightDetails?.baggageAllowanceAmount }}
                  <span *ngIf="flightDetails?.baggageAllowanceAmount > 1; else oneBag">
                    <span i18n="@@bags">bags</span>
                  </span>
                  <ng-template #oneBag>
                    <span i18n="@@bag">bag</span>
                  </ng-template>
              </span>
            </div>
            <ng-container *ngIf="flightDetails.additional">
              <div *ngIf="flightDetails.additional.seat?.length ||
            flightDetails.additional.reducedMobillityAssistance?.length ||
            flightDetails.additional.flightExtras?.length ||
            flightDetails.additional.advancedAncillaryOptions?.length ||
            flightDetails.additional.baggageAllowance?.length && flightDetails.additional.baggageAllowance[0].text" style="padding-top: 0.5rem;">
              <strong i18n="@@ShowDetailsAddedExtras"><u>Added Extras</u></strong>
              <div *ngIf="flightDetails.additional.seat?.length">
                <strong i18n="@@ShowDetailsSeat">Seat: </strong> <span *ngFor="let seat of flightDetails.additional.seat; let last = last">{{seat.row}}{{seat.column}}<span *ngIf="!last">,</span></span>
              </div>
              <div *ngIf="flightDetails.additional.reducedMobillityAssistance?.length">
                <strong i18n="@@ShowDetailsReducedMobillityAssistance">Reduced Mobillity Assistance: </strong>
                <span *ngFor="let reducedMobillityAssistance of flightDetails.additional.reducedMobillityAssistance; let last = last">
                  {{reducedMobillityAssistance.text}}<span *ngIf="!last">,</span>
                </span>
              </div>
              <div *ngIf="flightDetails.additional.flightExtras?.length">
                <strong i18n="@@ShowDetailsFlightExtras">Flight Extras: </strong>
                <span *ngFor="let flightExtras of flightDetails.additional.flightExtras; let last = last">
                  {{flightExtras.text}}<span *ngIf="!last">,</span>
                </span>
              </div>
              <div *ngIf="flightDetails.additional.advancedAncillaryOptions?.length">
                <strong i18n="@@ShowDetailsAdvancedAncillaryOptions">Advanced Ancillary Options: </strong>
                <span *ngFor="let advancedAncillaryOptions of flightDetails.additional.advancedAncillaryOptions; let last = last">
                  {{advancedAncillaryOptions.text}}<span *ngIf="!last">,</span>
                </span>
              </div>
              <div *ngIf="flightDetails.additional.baggageAllowance?.length">
                <strong i18n="@@ShowDetailsLuggageOptions">Luggage Options: </strong>
                <span *ngFor="let baggageAllowance of flightDetails.additional.baggageAllowance; let last = last">
                  {{baggageAllowance.text}}<span *ngIf="!last">,</span>
                </span>
              </div>
            </div>
            </ng-container>

          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
