<ng-container
  *ngIf="{
    fetching: (hotelAvalibilityService.fetching | async)
  } as data">
  <div class="hotel_filter_options_content" id="filters">
    <div>
      <div>
        <div>
          <br />
          <div class="flex-row-container" *ngIf="amenityTypeList.length">
            <h5>
              <b i18n="@@filterHotelsAmenities">Amenities</b>
            </h5>
            <span
              *ngIf="!amenitiesShowMore && amenityTypeList.length > 5"
              class="hotel-filters-show-more"
              (click)="amenitiesShowMore = true; setFocus('toggle_amenities_button')"
              (keydown.enter)="amenitiesShowMore = true; setFocus('toggle_amenities_button')"
              (keydown.space)="amenitiesShowMore = true; setFocus('toggle_amenities_button')"
              tabindex="0"
              role="button"
              i18n-aria-label
              id="toggle_amenities_button"
              aria-label="Show more amenities filters"
              i18n="@@filterHotelsShowMore">
              Show more
              <i class="ico-chevron-down" aria-hidden="true"></i>
            </span>
            <span
              *ngIf="amenitiesShowMore && amenityTypeList.length > 5"
              class="hotel-filters-show-more"
              (click)="amenitiesShowMore = false; setFocus('toggle_amenities_button')"
              (keydown.enter)="amenitiesShowMore = false; setFocus('toggle_amenities_button')"
              (keydown.space)="amenitiesShowMore = false; setFocus('toggle_amenities_button')"
              tabindex="0"
              role="button"
              i18n-aria-label
              id="toggle_amenities_button"
              i18n="@@filterHotelsShowLess"
              aria-label="Show less amenities filters">
              Show less
              <i class="ico-chevron-up" aria-hidden="true"></i>
            </span>
          </div>

          <ng-container *ngFor="let x of amenityTypeList; let i = index">
            <span
            [ngClass]="{ display_none: i > 4 && !amenitiesShowMore }">
              <mat-checkbox
                color="primary"
                class="light-primary"
                name="{{ x }}"
                value="{{ x }}"
                (change)="applyFilter($event, x, 'amenitytype')"
                (keydown.enter)="applyFilter($event, x, 'amenitytype')"
                [(ngModel)]="CheckboxStatesFacilities[x]"
                (keydown.esc)="closeFiltersOptions()">
                {{ getAmenitiesDisplayText(x) | translate }}
              </mat-checkbox>
              
              <ng-container *ngIf="x === AmenitiesSearchHandler.ELECTRIC_CAR_CHARGING_POINT">
                <section
                  (mouseleave)="tt1.close()"
                  class="container">
                  <i
                    class="ico ico-info-circle info_icon"
                    placement="top"
                    i18n-ngbTooltip
                    triggers="manual"
                    #tt1="ngbTooltip"
                    placement="right"
                    (mouseenter)="tt1.open()"
                    (focus)="tt1.open()"
                    (blur)="tt1.close()"
                    tabindex="0"
                    attr.aria-label="Electric charging points may be available onsite or offsite at the hotels closest available car park, please check the hotel website for more information."
                    ngbTooltip="Electric charging points may be available onsite or offsite at the hotels closest available car park, please check the hotel website for more information.">
                    <span class="visuallyhidden"
                      >Electric charging points may be available onsite or offsite at the hotels closest
                      available car park, please check the hotel website for more information.</span
                    >
                  </i>
                </section>
              </ng-container>
              <br />
            </span>
          </ng-container>
        </div>
        <div>
          <br />
          <div class="flex-row-container">
            <h5>
              <b i18n="@@travelPolicy">Travel Policy</b>
            </h5>
          </div>
          <span *ngFor="let x of travelPolicies; let i = index">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'travelPolicy')"
              (keydown.enter)="applyFilter($event, x, 'travelPolicy')"
              [(ngModel)]="CheckboxStatesTravelPolicy[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </div>
        <!-- ENT-9710 Customer Rating moved to s66 -->
        <!-- <div> 
          <br />
          <h5>
            <b i18n="@@filterHotelsCustomerRating">Customer Rating</b>
          </h5>
          <span *ngFor="let x of customerRating">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'customerRating')"
              (keydown.enter)="applyFilter($event, x, 'customerRating')"
              [(ngModel)]="CheckboxStatesCustomerRating[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </div> -->
        <div>
          <br />
          <h5>
            <b i18n="@@filterHotelsExtra">Extra</b>
          </h5>
          <ng-container *ngFor="let x of extra">
            <div [hidden]="searchParams.hiddenExtraFilters[x]">
              <mat-checkbox
                color="primary"
                class="light-primary"
                name="{{ x }}"
                value="{{ x }}"
                (change)="applyFilter($event, x, 'extra')"
                (keydown.enter)="applyFilter($event, x, 'extra')"
                [(ngModel)]="CheckboxStatesExtra[x]"
                (keydown.esc)="closeFiltersOptions()">
                {{ getExtraDisplayString(x) | translate }}
              </mat-checkbox>
            </div>
          </ng-container>
          <ng-container *ngFor="let x of refundableRoomTypeList">
            <div [hidden]="searchParams.hiddenExtraFilters[x]">
              <mat-checkbox
                color="primary"
                class="light-primary"
                name="{{ x }}"
                value="{{ x }}"
                id="filter_checkbox_{{x}}"
                (change)="applyFilter($event, x, 'refundableRoomType')"
                (keydown.enter)="applyFilter($event, x, 'refundableRoomType')"
                [(ngModel)]="CheckboxStatesRefundable[x]"
                >
                {{ x | splitCamelCase | translate }}
              </mat-checkbox>
            </div>
          </ng-container>
          <span class="extra_amenities">
            <!--Requested under Extras but actually filters by amenity type-->
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ ExtraSearchHandler.CTM_PARTNERS }}"
              value="{{ ExtraSearchHandler.CTM_PARTNERS }}"
              (change)="applyFilter($event, ExtraSearchHandler.CTM_PARTNERS, 'amenitytype')"
              (keydown.enter)="applyFilter($event, ExtraSearchHandler.CTM_PARTNERS, 'amenitytype')"
              [(ngModel)]="CheckboxAmenityTypes[ExtraSearchHandler.CTM_PARTNERS]"
              (keydown.esc)="closeFiltersOptions()"
              i18n="@@filterHotelsCTMBusinessReady"
              [hidden]="searchParams.hiddenExtraFilters[ExtraSearchHandler.CTM_PARTNERS]"
              >
              CTM Partners - Business Ready
            </mat-checkbox>
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ ExtraSearchHandler.GREEN_HOTEL }}"
              value="{{ ExtraSearchHandler.GREEN_HOTEL' }}"
              (change)="applyFilter($event, ExtraSearchHandler.GREEN_HOTEL, 'amenitytype')"
              (keydown.enter)="applyFilter($event, ExtraSearchHandler.GREEN_HOTEL, 'amenitytype')"
              [(ngModel)]="CheckboxAmenityTypes[ExtraSearchHandler.GREEN_HOTEL]"
              (keydown.esc)="closeFiltersOptions()"
              i18n="@@filterHotelsCTMGreenerChoice"
              [hidden]="searchParams.hiddenExtraFilters[ExtraSearchHandler.GREEN_HOTEL]"
              >
              CTM Greener Choice
            </mat-checkbox>
            <mat-checkbox
              color="primary"
              class="light-primary"
              [name]="hotelTagEnum.FCDOApprovedHotel"
              [value]="hotelTagEnum.FCDOApprovedHotel"
              (change)="applyFilter($event, hotelTagEnum.FCDOApprovedHotel, 'amenitytype')"
              (keydown.enter)="applyFilter($event, hotelTagEnum.FCDOApprovedHotel, 'amenitytype')"
              [(ngModel)]="CheckboxStatesExtra[hotelTagEnum.FCDOApprovedHotel]"
              (keydown.esc)="closeFiltersOptions()"
              i18n="@@filterHotelsFCDO"
              [hidden]="!isFCDOApprovedHotel || searchParams.hiddenExtraFilters[ExtraSearchHandler.FCDO_HOTEL]"
            >
              FCDO Approved Hotel
            </mat-checkbox>
            <span [hidden]="searchParams.hiddenExtraFilters[ExtraSearchHandler.ACCESSIBLE_ROOM]">
              <mat-checkbox
                color="primary"
                class="light-primary"
                [name]="hotelTagEnum.AccessibleRoom"
                [value]="hotelTagEnum.AccessibleRoom"
                (change)="applyFilter($event, hotelTagEnum.AccessibleRoom, 'accessibleRoomType')"
                (keydown.enter)="
                  applyFilter($event, hotelTagEnum.AccessibleRoom, 'accessibleRoomType')
                "
                [(ngModel)]="CheckboxAmenityTypes[hotelTagEnum.AccessibleRoom]"
                (keydown.esc)="closeFiltersOptions()"
                i18n="@@filterHotelsAR">
                Accessible Room
              </mat-checkbox>
              <section (mouseleave)="ttAR.close()" class="container">
                <i
                  class="ico ico-info-circle info_icon"
                  placement="top"
                  i18n-ngbTooltip
                  i18n-aria-label
                  triggers="manual"
                  #ttAR="ngbTooltip"
                  placement="right"
                  (mouseenter)="ttAR.open()"
                  (focus)="ttAR.open()"
                  (blur)="ttAR.close()"
                  tabindex="0"
                  attr.aria-label="The hotel have confirmed this room meets ADA standards."
                  ngbTooltip="The hotel have confirmed this room meets ADA standards.">
                  <span class="visuallyhidden"
                    >The hotel have confirmed this room meets ADA standards.</span
                  >
                </i>
              </section>
            </span>
            <br />
          </span>
        </div>
        <div>
          <br />
          <div class="flex-row-container">
            <h5>
              <b i18n="@@filterHotelsBreakfast">Breakfast</b>
            </h5>
          </div>
          <span *ngFor="let x of breakfastFilters; let i = index">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'breakfastType')"
              (keydown.enter)="applyFilter($event, x, 'breakfastType')"
              [(ngModel)]="CheckboxStatesBreakfast[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </div>
        <div>
          <br />
          <h5>
            <b i18n="@@filterHotelsPreference">Preference</b>
          </h5>
          <span *ngFor="let x of preferred">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'preferred')"
              (keydown.enter)="applyFilter($event, x, 'preferred')"
              [(ngModel)]="CheckboxStatesPreference[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </div>
        <div *ngIf="hotelAvalibilityService.hasSabreCSLResults">
          <br />
          <h5>
            <b i18n="@@filterHotelsStarRating">Star Rating</b>
          </h5>
          <ng-container *ngFor="let x of starRating">
            <span [hidden]="searchParams.hiddenStarRatingFilters[x] || hotelStarRatingsToHide[x]">
              <mat-checkbox
                color="primary"
                class="light-primary"
                name="{{ x }}"
                value="{{ x }}"
                (change)="applyFilter($event, x, 'starRating')"
                (keydown.enter)="applyFilter($event, x, 'starRating')"
                [(ngModel)]="CheckboxStatesStarRating[x]"
                (keydown.esc)="closeFiltersOptions()">
                {{ x | translate }}
              </mat-checkbox>
              <br />
            </span>
          </ng-container>
        </div>
        <div>
          <br />
          <h5>
            <b i18n="@@filterRoomType">Room Type</b>
          </h5>
          <span *ngFor="let x of roomTypeList">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'roomType')"
              (keydown.enter)="applyFilter($event, x, 'roomType')"
              [(ngModel)]="CheckboxStatesPreference[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </div>
      </div>
      <div>
        <br />
        <div class="hotel-name">
          <h5>
            <b i18n="@@filterHotelsByName">Hotel Name</b>
          </h5>
          <input
            type="text"
            [(ngModel)]="searchParams.filters.hotelName"
            (ngModelChange)="onFilterChangeHotelName()"
            aria-label="HotelName"
            i18n-placeholder
            placeholder="Enter a Hotel Name" />
        </div>
        <br />
        <div class="flex-row-container">
          <h5>
            <b i18n="@@filterHotelsHotelChain">Hotel Chain</b>
          </h5>
          <span
            *ngIf="!chainsShowMore && amenityTypeList.length > 5"
            class="hotel-filters-show-more"
            (click)="chainsShowMore = true; setFocus('toggle_chains_button')"
            (keydown.enter)="chainsShowMore = true; setFocus('toggle_chains_button')"
            (keydown.space)="chainsShowMore = true; setFocus('toggle_chains_button')"
            tabindex="0"
            role="button"
            i18n-aria-label
            aria-label="Show more chains filters"
            id="toggle_chains_button"
            i18n="@@filterHotelsShowMore">
            Show more
            <i class="ico-chevron-down" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="chainsShowMore && amenityTypeList.length > 5"
            class="hotel-filters-show-more"
            (click)="chainsShowMore = false; setFocus('toggle_chains_button')"
            (keydown.enter)="chainsShowMore = false; setFocus('toggle_chains_button')"
            (keydown.space)="chainsShowMore = false; setFocus('toggle_chains_button')"
            tabindex="0"
            role="button"
            i18n-aria-label
            id="toggle_chains_button"
            i18n="@@filterHotelsShowLess"
            aria-label="Show less chains filters">
            Show less
            <i class="ico-chevron-up" aria-hidden="true"></i>
          </span>
        </div>

        <span
          *ngFor="let x of chainTypeList; let i = index"
          [ngClass]="{ display_none: i > 4 && !chainsShowMore }">
          <mat-checkbox
            color="primary"
            class="light-primary"
            name="{{ x }}"
            value="{{ x }}"
            (change)="applyFilter($event, x, 'chain')"
            (keydown.enter)="applyFilter($event, x, 'chain')"
            (keydown.esc)="closeFiltersOptions()"
            [(ngModel)]="CheckboxStatesChain[x]">
            {{ x | translate }}
          </mat-checkbox>
          <br />
        </span>

        <br/>
        <section *ngIf="crownSet">
          <div class="flex-row-container">
            <h5>
              <b i18n="@@filterHotelsCrownRates">Crown Rates</b>
            </h5>
          </div>
          <!-- preferredCrown -->
          <span *ngFor="let x of preferredCrown">
            <mat-checkbox
              color="primary"
              class="light-primary"
              name="{{ x }}"
              value="{{ x }}"
              (change)="applyFilter($event, x, 'crownPreferred')"
              (keydown.enter)="applyFilter($event, x, 'crownPreferred')"
              [(ngModel)]="CheckboxStatesPreferenceCrown[x]"
              (keydown.esc)="closeFiltersOptions()">
              {{ x | translate }}
            </mat-checkbox>
            <br />
          </span>
        </section>
        

      </div>
    </div>
    <div>
      <br />
      <div class="class_option_list">
        <h5 id="price_range"><b i18n="@@priceRange">Price Range </b></h5>
        <div class="custom-slider flex-row-container">
          <div class="customer-slider-box">
            <label class="custom-slider-label" for="minPrice">
              <span i18n="@@filterHotelsMinRange">Min</span>
              <span class="visuallyhidden">&nbsp;-&nbsp;price range lower bound</span>
            </label>
            <input
              id="minPrice"
              class="price_filter_box"
              [(ngModel)]="minPrice" />
          </div>
          <div class="customer-slider-box">
            <label class="custom-slider-label" for="maxPrice">
              <span i18n="@@filterHotelsMaxRange">Max</span>
              <span class="visuallyhidden">&nbsp;-&nbsp;price range upper bound</span>
            </label>
            <input
              id="maxPrice"
              class="price_filter_box"
              [(ngModel)]="maxPrice"/>
          </div>
          <button class="small-button cutomer-slider-filter-button" (click)="updatePrice()">
            Filter Price
          </button>
        </div>
      </div>
      <section role="region" aria-label="close" class="hotel_filter_options_clear">
        <button
          class="small-button"
          (click)="clearFilters()"
          (keydown.esc)="closeFiltersOptions()"
          i18n="@@filterhotels_clear"
          (keydown.shift.tab)="trapFocusReverse($event)"
          aria-label="Clear filters"
          id="hotel-filters-clear">
          Clear
        </button>
      </section>
    </div>
  </div>
</ng-container>
