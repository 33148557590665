<div class="flex-row-container--tb-margin" *ngIf="display">
  <div class="irl-list_item half-container">
    <div class="half-block flex-row-container">
      <img
        class="irl-list_item-logo"
        alt="{{ operatorCode }} Logo"
        src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/{{
          operatorCode
        }}.svg" />
      <div class="irl-list_schedule flex-row-container">
        <div class="flex-column-container">
          <div class="irl-list_schedule">
            <span i18n="@@euRailDepart">Depart: &nbsp;</span>{{ item.from }}
          </div>
          <div class="irl-list_schedule">
            <span i18n="@@euRailArrive">Arrive: &nbsp;</span>{{ item.to }}
          </div>
        </div>
        <div class="flex-column-container">
          <div class="irl-list_schedule">
            {{ removeTimeZone(item.departDateTime) | sabsTimePipe : 'timeString' }}
          </div>
          <div class="irl-list_schedule">
            {{ removeTimeZone(item.arriveDateTime) | sabsTimePipe : 'timeString' }}
          </div>
        </div>
        <div class="flex-column-container">
          <div class="irl-list_schedule" *ngIf="direct" i18n="@@railDirect">Direct</div>
          <div class="irl-list_schedule" *ngIf="!direct">{{ item.changes + ' Changes' | translate}}</div>
          <div class="irl-list_schedule">
            Duration: {{ calculateDuration(item) | sabsTimePipe : 'min_duration' }}
          </div>
        </div>
        <div class="end-container">
          <i *ngIf="isCancelled" class="ico-norail cancelled-icon" i18n-ngbTooltip ngbTooltip="This Train has been cancelled."  ></i>
        </div>
        <div class="irl-list_legs">
          <span class="irl-list_leg" i18n="@@irlListDirect">{{ 'Direct' | translate }}</span>
        </div>
      </div>
    </div>
    <div class="half-block flex-row-container">
      <button
        (click)="toggleFare(cheapestSecond)"
        *ngIf="cheapestSecondClassTicket || []; let cheapestSecond"
        class="irl-list_item-fare"
        [disabled]="cheapestSecond[0]?.unavailable || isCancelled">
        @if(cheapestSecond[0]){
          <span>
            <span class="sr-only" i18n="@@irl2ndclassCheapest">2nd Class </span>
            <div class="non-selected-container">
              <span
                *ngIf="
                  cheapestSecond[0]?.price &&
                  searchParams.selectedTicket?.fare.fareHash !== cheapestSecond[0]?.fareHash
                "
                class="price-container">
                <span
                  ><span i18n="@@itineraryFromCheapest">From:</span>
                  {{ ( getTotalPrice | memoize: cheapestSecond) | currency : cheapestSecond[0].currency }}</span
                >
              </span>
              <span *ngIf="isExchangeSearch && !( getTotalPrice | memoize: cheapestSecond)" i18n="@@Select">Select</span>
            </div>
            <div *ngIf="searchParams.selectedTicket?.fare.fareHash === cheapestSecond[0]?.fareHash">
              <span i18n="@@Deselect">Deselect</span>
            </div>
          </span>
          <app-preferred-icons
            [tmcPreferred]="cheapestSecond[0]?.tmcPreferred"
            [tmcPreferredLogoUrl]="cheapestSecond[0]?.tmcPreferredLogoUrl"
            [tmcPreferredMessage]="cheapestSecond[0]?.tmcPreferredMessage"
            [clientPreferred]="cheapestSecond[0]?.officePreferred || cheapestSecond[0]?.companyPreferred"
            [companyPreferredMessage]="cheapestSecond[0]?.companyPreferredMessage"
            [officePreferredMessage]="cheapestSecond[0]?.officePreferredMessage">
          </app-preferred-icons>
          <app-warning-tags [iconId]="cheapestSecond[0]?.fareHash" [ticket]="cheapestSecond[0]"></app-warning-tags>
        }
      </button>
      <button
        *ngIf="cheapestFirstClassTicket || []; let cheapestFirst"
        (click)="toggleFare(cheapestFirst)"
        class="irl-list_item-fare"
        [disabled]="cheapestFirst[0]?.unavailable || isCancelled">
        @if(cheapestFirst[0]){
          <span *ngIf="cheapestFirst[0]?.price">
          <div class="non-selected-container">
            <span class="sr-only" i18n="@@irl1stclass">1st Class </span>
            <span class="price-container">
              <span
                *ngIf="
                  cheapestFirst[0]?.price &&
                  searchParams.selectedTicket?.fare.fareHash !== cheapestFirst?.fareHash
                "
                ><span i18n="@@itineraryFromCheapest">From:</span>
                {{ ( getTotalPrice | memoize: cheapestFirst) | currency : cheapestFirst[0].currency }}</span
              >
              <span *ngIf="isExchangeSearch && !( getTotalPrice | memoize: cheapestFirst)" i18n="@@Select">Select</span>
            </span>
            </div>
            <div *ngIf="searchParams.selectedTicket?.fare.fareHash === cheapestFirst[0]?.fareHash">
              <span i18n="@@Deselect">Deselect</span>
            </div>
          </span>
          <app-preferred-icons
            [tmcPreferred]="cheapestFirst[0]?.tmcPreferred"
            [tmcPreferredLogoUrl]="cheapestFirst[0]?.tmcPreferredLogoUrl"
            [tmcPreferredMessage]="cheapestFirst[0]?.tmcPreferredMessage"
            [clientPreferred]="cheapestFirst[0]?.officePreferred || cheapestFirst[0]?.companyPreferred"
            [companyPreferredMessage]="cheapestFirst[0]?.companyPreferredMessage"
            [officePreferredMessage]="cheapestFirst[0]?.officePreferredMessage">
          </app-preferred-icons>

          <app-warning-tags [iconId]="cheapestFirst[0]?.fareHash" [ticket]="cheapestFirst[0]"></app-warning-tags>
        }
      </button>
    </div>
  </div>
  <div class="irl_detail_container">
    <div class="irl_detail_title">
      <b>&nbsp;&nbsp;<span i18n="@@flightLegsOperatedBy">Operated By</span> </b>
      &nbsp;
      {{ transport }}
      • &nbsp;
      {{ timetable }}
      • &nbsp;
      {{ operator }}
    </div>
    <div
      class="irl_detail_info"
      (click)="showDetail = !showDetail"
      role="button"
      tabindex="0"
      (keydown.enter)="showDetail = !showDetail"
      [attr.aria-expanded]="showDetail"
      attr.aria-controls="rail-detail-expended-{{ item.journeyHash }}">
      <b class="detail-accordion">{{
        (showDetail ? 'Collapse' : 'Show trip details & fares') | translate
      }}</b>
    </div>
  </div>
  <div
    class="irl_detail_showdetail"
    *ngIf="showDetail"
    id="irl-detail-expended-{{ item.journeyHash }}">
    <app-trainline-eu-item-detail
      [item]="item"
      [isCancelled]="isCancelled"
      [isReturnTrip]="type === RailJourneyType.ReturnJourney"
      [legs]="item.journeyLegs"
      [isInbound]="isInbound"
      [selectedRail]="selectedRail"
      (selectRail)="toggleFare($event)">
    </app-trainline-eu-item-detail>
  </div>
</div>

